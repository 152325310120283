// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,700;1,400;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{box-sizing:border-box}html{font-family:"Barlow",Helvetica,Arial,sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}html,body{background-color:#111;margin:0;border:0;padding:0;min-width:280px}@media(hover: none){html,body{scrollbar-width:none}html *::-webkit-scrollbar,body *::-webkit-scrollbar{display:none}html *,body *{-ms-overflow-style:none;scrollbar-width:none}html * *::-webkit-scrollbar,body * *::-webkit-scrollbar{display:none}}button{font-family:"Barlow",Helvetica,Arial,sans-serif}img{max-width:100%;image-orientation:from-image}a{color:#000;text-decoration:none;word-wrap:break-word}a:visited{color:#000}ul{list-style:none;margin:0px;padding:0px}`, "",{"version":3,"sources":["webpack://./src/index.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAOA,EACE,qBAAA,CAGF,KACE,+CAPW,CAQX,kCAAA,CACA,iCAAA,CAGF,UAEE,qBCoBc,CDnBd,QAAA,CACA,QAAA,CACA,SAAA,CACA,eAAA,CAEA,oBARF,UAUI,oBAAA,CAEA,oDACE,YAAA,CAGF,cACE,uBAAA,CAEA,oBAAA,CAGA,wDACE,YAAA,CAAA,CAMR,OACE,+CA1CW,CA6Cb,IACE,cAAA,CAEA,4BAAA,CAGF,EACE,UChDM,CDiDN,oBAAA,CAMA,oBAAA,CAJA,UACE,UCpDI,CD0DR,GACE,eAAA,CACA,UAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
