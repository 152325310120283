import React from 'react';

export const X = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.5 1.5C2.84531 1.5 1.5 2.84531 1.5 4.5V19.5C1.5 21.1547 2.84531 22.5 4.5 22.5H19.5C21.1547 22.5 22.5 21.1547 22.5 19.5V4.5C22.5 2.84531 21.1547 1.5 19.5 1.5H4.5ZM18.4266 5.4375L13.5609 10.9969L19.2844 18.5625H14.8031L11.2969 13.9734L7.27969 18.5625H5.05313L10.2563 12.6141L4.76719 5.4375H9.36094L12.5344 9.63281L16.2 5.4375H18.4266ZM16.6547 17.2313L8.69062 6.69844H7.36406L15.4172 17.2313H16.65H16.6547Z"
      fill="#EEEEEE"
    />
  </svg>
);
