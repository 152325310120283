import { getNotificationRedirect } from './getNotificationRedirect';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Keyboard } from '@capacitor/keyboard';
import { StatusBar, Style } from '@capacitor/status-bar';
import { PushNotifications } from '@capacitor/push-notifications';
import { Browser } from '@capacitor/browser';
import { EmailComposer } from 'capacitor-email-composer';
import { log, LogType } from './logger';
import { OrientationType, ScreenOrientation } from '@capawesome/capacitor-screen-orientation';
import api from './api';

const capacitorInit = async () => {
  if (Capacitor.isNativePlatform()) {
    await ScreenOrientation.lock({ type: OrientationType.PORTRAIT });
    // Upgrading from Cordova to Capacitor broke mailto links
    // When a mailto link is sent to be opened
    // function catches "mailto:" links and instead use capacitor-email-composer to open the default mail app
    window.open = (url: string) => {
      if (typeof url === 'string' && url.includes('mailto')) {
        const email = url.split(':')[1];
        void EmailComposer.open({
          to: [email],
        });
      } else if (typeof url === 'string') {
        void Browser.open({ url });
      }
      return window;
    };
  }
  StatusBar.getInfo()
    .then(result => {
      log(String(result));
    })
    .then(() => {
      void StatusBar.setOverlaysWebView({
        overlay: false,
      });
      void StatusBar.setBackgroundColor({ color: '#111' });
      void StatusBar.setStyle({ style: Style.Dark });
    })
    .then(() => StatusBar.getInfo())
    .then(result => log(String(result)))
    .catch(error => {
      log(error.message, LogType.ERROR);
    });

  // On success, we should be able to receive notifications
  void PushNotifications.addListener('registration', token => {
    localStorage.setItem('FCMToken', token.value);
  });

  // Some issue with our setup and push will not work
  void PushNotifications.addListener('registrationError', error => {
    alert(`Error on registration: ${JSON.stringify(error)}`);
  });

  // Show us the notification payload if the app is open on our device
  // PushNotifications.addListener(
  //   'pushNotificationReceived',
  //   (notification) => {
  //     // TODO navigate to relevant page
  //     // alert('Push received: ' + JSON.stringify(notification));
  //   },
  // );

  // Method called when tapping on a notification
  void PushNotifications.addListener('pushNotificationActionPerformed', notification => {
    type NotificationPayload = {
      id: string;
      postId: string;
      commentId?: string;
      boardId?: string;
    };

    const payload: NotificationPayload = notification.notification.data;

    const pushNoticiationRead = new CustomEvent('pushNotificationRead', {
      detail: { notificationId: parseInt(payload.id, 10) },
    });
    document.dispatchEvent(pushNoticiationRead);

    window.location.hash = getNotificationRedirect(payload);
  });

  void Keyboard.setAccessoryBarVisible({ isVisible: true });
  const device = await Device.getInfo();

  (function ios12NotchFix(isIos, keyboardPluginExists) {
    if (!isIos || device.model.indexOf('iPhone') === -1) return false;
    if (!keyboardPluginExists) throw new Error('This fix depends on keyboard plugin!');
    const iphoneNumber = Number(device.model.replace('iPhone', '').replace(',', '.'));
    const viewportEl = document.getElementsByName('viewport')[0];
    const defaultContent =
      'user-scalable=no, width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1';
    const setCover = () => {
      // @ts-ignore disable-next-line
      viewportEl.content = `${defaultContent}, viewport-fit=cover`;
    };
    const setFix = () => {
      // @ts-ignore disable-next-line
      viewportEl.content = defaultContent;
    };
    if (iphoneNumber >= 10.6 || iphoneNumber === 10.3) {
      /* devices with notch: X, XR, ... */
      setCover();
      // Keyboard.addListener('keyboardWillShow', setFix);
      // Keyboard.addListener('keyboardWillHide', setCover);
    }
    return true;
  })(device.platform === 'ios', Capacitor.isPluginAvailable('Keyboard'));
};

export default capacitorInit;
