// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-ChatModal-module-wrapper-by00V{background:#111}.-ChatModal-module-wrapper-by00V .-ChatModal-module-header-RCgHL{display:flex;align-items:center;padding:.75rem;padding-top:calc(.75rem + env(safe-area-inset-top));height:auto;color:#eee}.-ChatModal-module-wrapper-by00V .-ChatModal-module-header-RCgHL .-ChatModal-module-name-fdD7L,.-ChatModal-module-wrapper-by00V .-ChatModal-module-header-RCgHL .-ChatModal-module-description-wTz0J{margin:0 1rem;color:#fff;font-family:"nexa"}.-ChatModal-module-wrapper-by00V .-ChatModal-module-header-RCgHL .-ChatModal-module-name-fdD7L{font-weight:700;font-size:1.5rem}.-ChatModal-module-wrapper-by00V .-ChatModal-module-header-RCgHL .-ChatModal-module-description-wTz0J{font-weight:normal;margin-top:0;font-size:1rem;font-weight:700;margin-bottom:2rem}.-ChatModal-module-backButton-oxlZz{color:#eee;max-width:960px;margin:0 auto}`, "",{"version":3,"sources":["webpack://./src/components/ChatModal/ChatModal.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAEA,iCACE,eCoCc,CDnCd,iEACE,YAAA,CACA,kBAAA,CACA,cAAA,CACA,mDAAA,CACA,WAAA,CACA,UCyCW,CDvCX,qMAEE,aAAA,CACA,UCHW,CDIX,kBAAA,CAGF,+FACE,eAAA,CACA,gBAAA,CAGF,sGACE,kBAAA,CACA,YAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CAKN,oCACE,UCgBa,CDfb,eAAA,CACA,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-ChatModal-module-wrapper-by00V`,
	"header": `-ChatModal-module-header-RCgHL`,
	"name": `-ChatModal-module-name-fdD7L`,
	"description": `-ChatModal-module-description-wTz0J`,
	"backButton": `-ChatModal-module-backButton-oxlZz`
};
export default ___CSS_LOADER_EXPORT___;
