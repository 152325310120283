// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-UIBlockListItem-module-main-jz_3c{display:flex;flex-direction:row;align-items:center}.-UIBlockListItem-module-main-jz_3c .-UIBlockListItem-module-content-sFHDb{flex:1}@supports(display: grid){.-UIBlockListItem-module-main-jz_3c{display:grid;grid-template-columns:1fr auto;grid-template-areas:"content toggle";grid-column-gap:8px}.-UIBlockListItem-module-main-jz_3c.-UIBlockListItem-module-hasAvatar-pzQG_{grid-template-columns:auto 1fr auto;grid-template-areas:"avatar content toggle"}.-UIBlockListItem-module-main-jz_3c .-UIBlockListItem-module-avatar-EdIj0{grid-template-areas:"avatar"}.-UIBlockListItem-module-main-jz_3c .-UIBlockListItem-module-content-sFHDb{grid-template-areas:"content";font-size:16px;font-style:normal;font-weight:bold;line-height:20px}.-UIBlockListItem-module-main-jz_3c .-UIBlockListItem-module-toggle-T4bH7{grid-template-areas:"toggle"}}`, "",{"version":3,"sources":["webpack://./src/components/UIBlockListItem/UIBlockListItem.module.scss","webpack://./src/theme/_dimensions.scss"],"names":[],"mappings":"AAGA,oCACE,YAAA,CACA,kBAAA,CACA,kBAAA,CAEA,2EAAA,MAAA,CAIF,yBACE,oCACE,YAAA,CACA,8BAAA,CACA,oCAAA,CACA,mBCZU,CDcV,4EACE,mCAAA,CACA,2CAAA,CAGF,0EACE,4BAAA,CAGF,2EACE,6BAAA,CACA,cAAA,CACA,iBAAA,CACA,gBAAA,CACA,gBAAA,CAGF,0EACE,4BAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `-UIBlockListItem-module-main-jz_3c`,
	"content": `-UIBlockListItem-module-content-sFHDb`,
	"hasAvatar": `-UIBlockListItem-module-hasAvatar-pzQG_`,
	"avatar": `-UIBlockListItem-module-avatar-EdIj0`,
	"toggle": `-UIBlockListItem-module-toggle-T4bH7`
};
export default ___CSS_LOADER_EXPORT___;
