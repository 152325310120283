// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-LanguageSelector-module-wrapper-JhDLd{color:#eee;font-size:1rem;margin:8px 16px;padding:16px;border-radius:12px;background:#222}.-LanguageSelector-module-wrapper-JhDLd .-LanguageSelector-module-lngSelect-k4Eav{background:#222;border:none;margin:0 8px;color:#eee;font-size:1rem;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/LanguageSelector/LanguageSelector.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAIA,wCACE,UC8Ca,CD7Cb,cAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,eCgCe,CD9Bf,kFACE,eC6Ba,CD5Bb,WAAA,CACA,YAAA,CACA,UCmCW,CDlCX,cAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-LanguageSelector-module-wrapper-JhDLd`,
	"lngSelect": `-LanguageSelector-module-lngSelect-k4Eav`
};
export default ___CSS_LOADER_EXPORT___;
