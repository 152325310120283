import { useEffect, useState } from 'react';

import ActionButtonGroup from '../ActionButtonGroup/ActionButtonGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HideShow from '../HideShow/HideShow';
import UIListItem from '../UIListItem/UIListItem';
import s from '../RemoveCommentListItem/RemoveCommentListItem.module.scss';
import { useMessageRemove } from '../../hooks/messageHooks';
import { useTranslation } from 'react-i18next';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import Button from '../Button/Button';

/**
 * Props Type Interface for {@link RemoveMessageListItem}
 */
export interface RemoveMessageListItemProps {
  messageId: number;
  chatId: number;
  toggleActionSheet: Function;
}

/**
 *
 * @param {RemoveMessageListItemProps} props
 * @returns {JSX.Element}
 */
const RemoveMessageListItem = ({
  messageId,
  chatId,
  toggleActionSheet,
  deleteMessage,
}: RemoveMessageListItemProps): JSX.Element => {
  const { removeMessage, removed, isRemoving } = useMessageRemove(chatId, messageId);
  const { t } = useTranslation();

  const removeStatus = removed;
  const [showDecision, setShowDecision] = useState(false);
  const [label, setLabel] = useState(t('addSavedList.label'));

  useEffect(() => {
    if (isRemoving) {
      setLabel(t('removingMessage.label'));
    } else if (removed) {
      setLabel(t('messageRemoved.label'));
    } else {
      setLabel(t('removeComment.label'));
    }
  }, [isRemoving, removeStatus, removed, t]);

  const iconStyles = [s.icon, isRemoving && s.saving].join(' ');

  const icon = isRemoving ? (
    <FontAwesomeIcon className={iconStyles} icon={['far', 'hourglass']} />
  ) : (
    <FontAwesomeIcon className={iconStyles} icon={['fas', 'trash']} />
  );

  const handleRemove = () => {
    void removeMessage().then(() => {
      deleteMessage(messageId);
    });
    toggleActionSheet(null);
  };

  return (
    <>
      <UIListItem icon={icon} disabled={isRemoving} onClick={() => setShowDecision(true)}>
        {label}
      </UIListItem>
      <HideShow show={showDecision}>
        <div className={s.decisionCopy}>
          <p>{t('sureRemove.label')}</p>
        </div>
        <ButtonGroup borderTop>
          <Button label={t('yes.label')} type="button" onClick={() => handleRemove()}></Button>
          <Button
            secondary
            label={t('no.label')}
            type="button"
            onClick={() => setShowDecision(false)}></Button>
        </ButtonGroup>
      </HideShow>
    </>
  );
};

export default RemoveMessageListItem;
