export const FILE_ADD_DISPATCH = 'WIS/files/FILE_ADD_DISPATCHED';
export const FILE_ADD_PROGRESS = 'WIS/files/FILE_ADD_PROGRESSED';
export const FILE_ADD_FULFILL = 'WIS/files/FILE_ADD_FULFILLED';
export const FILE_ADD_REJECT = 'WIS/files/FILE_ADD_REJECTED';

export type FileActionTypes =
  'WIS/files/FILE_ADD_DISPATCHED'
  | 'WIS/files/FILE_ADD_PROGRESSED'
  | 'WIS/files/FILE_ADD_FULFILLED'
  | 'WIS/files/FILE_ADD_REJECTED';

export const fileAddDispatch = (ref: string, id: string, name: string) => ({ type: FILE_ADD_DISPATCH, ref, id, name });
export const fileAddProgress = (ref: string, id: string, perc: number) => ({ type: FILE_ADD_PROGRESS, ref, id, perc });
export const fileAddFulfill = (ref: string, id: string) => ({ type: FILE_ADD_FULFILL, ref, id });
export const fileAddReject = (ref: string, id: string, errorMessage: string) => ({ type: FILE_ADD_REJECT, ref, id, errorMessage });

export interface FileState {
  groups: {
    [key: string]: {
      [key: string]: {
        name?: string;
        uploadedPerc?: number;
        uploaded?: boolean;
        errorMessage?: string;
      }
    }
  }
}

const initialState: FileState = {
  groups: {},
};

const initialFileState = {
  name: '',
  uploadedPerc: 0,
  uploaded: false,
  errorMessage: null as string,
};

export type FileAction = {
  readonly type: FileActionTypes;
  ref?: string;
  id?: string;
  name?: string;
  perc?: number;
  errorMessage?: string;
}

export default function reducer(state = initialState, action: FileAction) {
  switch (action.type) {
    case FILE_ADD_DISPATCH:
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.ref]: {
            ...state.groups[action.ref],
            [action.id]: {
              ...initialFileState,
              name: action.name,
            },
          },
        },
      };

    case FILE_ADD_PROGRESS:
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.ref]: {
            ...state.groups[action.ref],
            [action.id]: {
              ...state.groups[action.ref][action.id],
              uploadedPerc: action.perc,
            },
          },
        },
      };

    case FILE_ADD_FULFILL:
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.ref]: {
            ...state.groups[action.ref],
            [action.id]: {
              ...state.groups[action.ref][action.id],
              uploaded: true,
              errorMessage: null,
            },
          },
        },
      };

    case FILE_ADD_REJECT:
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.ref]: {
            ...state.groups[action.ref],
            [action.id]: {
              ...state.groups[action.ref][action.id],
              uploaded: false,
              errorMessage: action?.errorMessage || 'something went wrong uploading.',
            },
          },
        },
      };

    default:
      return state;
  }
}
