import React, { useEffect } from 'react';
import { useProfile } from '../util/APIDjango';
import api from '../util/api';

export const useProfileData = () => {
  const [profileData, setProfileData] = useProfile();
  useEffect(() => {
    if (!profileData?.profile?.id) {
      setProfileData({ ...profileData, isFetching: true });
      void api
        .profileGet()
        .then(data => {
          setProfileData({
            ...data,
            profile: data,
            isFetching: false,
            isFetched: true,
            error: null,
          });
        })
        .catch(error => {
          setProfileData({ ...profileData, error: error, isFetching: false });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    profileData,
    setProfileData,
  };
};
