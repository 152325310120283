import {
  updateLanguagesDispatch,
  updateLanguagesFulfill,
  updateLanguagesReject,
} from '../../redux/modules/autoTranslate';
import { useEffect, useState } from 'react';

import { AutoTranslate } from '../../types/AutoTranslate';
import Button from '../Button/Button';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import Card from '../Card/Card';
import Modal from '../Modal/Modal';
import { Preferences } from '@capacitor/preferences';
import Toggle from '../Toggle/Toggle';
import api from '../../util/api';
import s from './AutoTranslateDisclaimer.module.scss';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

/**
 * The component is a disclaimer popup that users see upon their first access to the site from a new device.
 * It highlights the basic terms of use for users.
 * The AutoTranslater Disclaimer label is the latest disclaimer set by the client.
 * @returns {JSX.Element}
 */
const AutoTranslateDisclaimer = (): JSX.Element => {
  const dispatch = useDispatch();
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    //check if autoTranslateDisclaimerShown is set
    void Preferences.get({
      key: 'autoTranslateDisclaimerShown',
    }).then(({ value }) => {
      if (value === null) {
        setShowDisclaimer(true);
      } else if (value !== 'true') {
        setShowDisclaimer(false);
        return;
      }
    });

    void Preferences.get({
      key: 'autoTranslateStatus',
    }).then(({ value }) => {
      if (!value) {
        void Preferences.set({
          key: 'autoTranslateStatus',
          value: 'true',
        });
      }
    });
  }, []);

  const [isChanging, setIsChanging] = useState(false);
  const [autoTranslateStatus, setAutoTranslateStatus] = useState(true);

  const toggleAutoTranslate = () => {
    setAutoTranslateStatus(!autoTranslateStatus);
  };

  return (
    <Modal show={showDisclaimer}>
      <Card title={t('autoTranslate.label')} className={s.wrapper}>
        <div className={s.cardcontent}>
          <div className={s.checkbox}>
            <div className={s.textbody}>{t('autoTranslatePrompt.label')}</div>{' '}
            <Toggle
              id={'1'}
              checked={autoTranslateStatus}
              pending={isChanging}
              onChange={toggleAutoTranslate}
              className={undefined}
              onClick={undefined}
            />
          </div>

          <Button
            label={t('save.label')}
            onClick={() =>
              void Preferences.set({
                key: 'autoTranslateDisclaimerShown',
                value: '1',
              }).then(() => {
                setShowDisclaimer(false);
                const autoTranslatePreference = autoTranslateStatus ? true : false;
                const values = {
                  language: i18n.language,
                  autoTranslatePreference,
                };

                dispatch(updateLanguagesDispatch(values));
                api
                  .autoTranslate(values)
                  .then((response: AutoTranslate) => {
                    dispatch(updateLanguagesFulfill(response));
                  })
                  .catch((error: { message: string }) => {
                    dispatch(updateLanguagesReject(error.message));
                  });

                setIsChanging(true);
                setAutoTranslateStatus(!autoTranslateStatus);
                setIsChanging(false);
              })
            }
            fullWidth
          />
        </div>
      </Card>
    </Modal>
  );
};

export default AutoTranslateDisclaimer;
