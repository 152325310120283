import { CommentType } from '../../types/Comment';
import { PostType } from '../../types/Post';

export const COMMENT_GET_BY_POST_ID_DISPATCH =
  'WIS/comments/COMMENT_GET_BY_POST_ID_DISPATCHED';
export const COMMENT_GET_BY_POST_ID_FULFILL =
  'WIS/comments/COMMENT_GET_BY_POST_ID_FULFILLED';
export const COMMENT_GET_BY_POST_ID_REJECT =
  'WIS/comments/COMMENT_GET_BY_POST_ID_REJECTED';

export const COMMENT_CREATE_DISPATCH = 'WIS/comments/COMMENT_CREATE_DISPATCHED';
export const COMMENT_CREATE_FULFILL = 'WIS/comments/COMMENT_CREATE_FULFILLED';
export const COMMENT_CREATE_REJECT = 'WIS/comments/COMMENT_CREATE_REJECTED';

export const COMMENT_UNPUBLISH_DISPATCH =
  'WIS/posts/COMMENT_UNPUBLISH_DISPATCHED';
export const COMMENT_UNPUBLISH_FULFILL =
  'WIS/posts/COMMENT_UNPUBLISH_FULFILLED';
export const COMMENT_UNPUBLISH_REJECT = 'WIS/posts/COMMENT_UNPUBLISH_REJECTED';

export type CommentActionTypes =
  | 'WIS/comments/COMMENT_GET_BY_POST_ID_DISPATCHED'
  | 'WIS/comments/COMMENT_GET_BY_POST_ID_FULFILLED'
  | 'WIS/comments/COMMENT_GET_BY_POST_ID_REJECTED'
  | 'WIS/comments/COMMENT_CREATE_DISPATCHED'
  | 'WIS/comments/COMMENT_CREATE_FULFILLED'
  | 'WIS/comments/COMMENT_CREATE_REJECTED'
  | 'WIS/posts/COMMENT_UNPUBLISH_DISPATCHED'
  | 'WIS/posts/COMMENT_UNPUBLISH_FULFILLED'
  | 'WIS/posts/COMMENT_UNPUBLISH_REJECTED';

export const commentGetByPostIdDispatch = (postId: string) => ({
  type: COMMENT_GET_BY_POST_ID_DISPATCH,
  postId,
});
export const commentGetByPostIdFulfill = (
  postId: string,
  comments: CommentType[],
) => ({ type: COMMENT_GET_BY_POST_ID_FULFILL, postId, comments });
export const commentGetByPostIdReject = (error: string) => ({
  type: COMMENT_GET_BY_POST_ID_REJECT,
  error,
});

export const commentCreateDispatch = (postId: string) => ({
  type: COMMENT_CREATE_DISPATCH,
  postId,
});
export const commentCreateFulfill = (postId: string, comment: CommentType) => ({
  type: COMMENT_CREATE_FULFILL,
  postId,
  comment,
});
export const commentCreateReject = (error: string) => ({
  type: COMMENT_CREATE_REJECT,
  error,
});

export const commentUnpublishDispatch = (
  postId: string,
  commentId: string,
) => ({ type: COMMENT_UNPUBLISH_DISPATCH, postId, commentId });
export const commentUnpublishFulfill = (comment: CommentType) => ({
  type: COMMENT_UNPUBLISH_FULFILL,
  comment,
});
export const commentUnpublishReject = (postId: string, error: string) => ({
  type: COMMENT_UNPUBLISH_REJECT,
  postId,
  error,
});

export interface CommentState {
  commentsByPostId: {
    [key: PostType['id']]: {
      comments?: CommentType[];
      isFetching?: boolean;
      isFetched?: boolean;
      lastFetched?: number;
      isUnpublishing?: boolean;
      unpublishedSinceLastFetched?: string[];
      unPublishingId?: string;
      unPublishError?: string;
      error?: string;
    };
  };
  isFetching: boolean;
  isFetched: boolean;
  isPosting: boolean;
  isPosted: boolean;
  error?: string;
}

const initialState: CommentState = {
  commentsByPostId: {},
  isFetching: false,
  isFetched: false,
  isPosting: false,
  isPosted: false,
  error: null,
};

const removeCommentFromComments = (
  commentId: CommentType['id'],
  comments: CommentType[],
) => {
  const newCommentsState = [...comments];
  const commentIndex = newCommentsState.findIndex(
    comment => comment.id === commentId,
  );
  newCommentsState.splice(commentIndex, 1);
  return newCommentsState;
};

export type CommentAction = {
  readonly type: CommentActionTypes;
  postId?: PostType['id'];
  comments?: CommentType[];
  comment?: CommentType;
  commentId?: CommentType['id'];
} & Partial<Pick<CommentState, 'error'>>;

export default function reducer(state = initialState, action: CommentAction) {
  switch (action.type) {
    case COMMENT_CREATE_DISPATCH:
      return {
        ...state,
        isPosting: true,
        isPosted: false,
        error: null,
      };

    case COMMENT_CREATE_FULFILL:
      return {
        ...state,
        isPosting: false,
        isPosted: true,
        error: null,
      };

    case COMMENT_CREATE_REJECT:
      return {
        ...state,
        isPosting: false,
        isPosted: false,
        error: action.error,
      };

    case COMMENT_GET_BY_POST_ID_DISPATCH: {
      const currentComments = state.commentsByPostId[action.postId]
        ? state.commentsByPostId[action.postId].comments
        : [];
      const commentsForPost: CommentState['commentsByPostId'] = {};
      commentsForPost[action.postId] = {
        comments: [...(currentComments || [])],
        isFetched: false,
        isFetching: true,
      };

      return {
        ...state,
        commentsByPostId: {
          ...state.commentsByPostId,
          ...commentsForPost,
        },
      };
    }

    case COMMENT_GET_BY_POST_ID_FULFILL: {
      const commentsForPost: CommentState['commentsByPostId'] = {};
      /*
        ensure correct by filtering comments by postId
        then rename key 'post' to 'postId'
      */
      const sanitisedComments = action.comments
        .filter(comment => comment.post === action.postId)
        .map(({ post, ...rest }) => ({ ...rest, postId: post }));

      commentsForPost[action.postId] = {
        comments: sanitisedComments,
        isFetched: true,
        isFetching: false,
        lastFetched: Date.now(),
        unpublishedSinceLastFetched: [],
        error: null,
      };

      return {
        ...state,
        commentsByPostId: {
          ...state.commentsByPostId,
          ...commentsForPost,
        },
      };
    }

    case COMMENT_GET_BY_POST_ID_REJECT: {
      const commentsForPost: CommentState['commentsByPostId'] = {};
      commentsForPost[action.postId] = {
        isFetched: false,
        isFetching: false,
        error: action.error,
      };
      return {
        ...state,
        commentsByPostId: {
          ...state.commentsByPostId,
          ...commentsForPost,
        },
      };
    }

    case COMMENT_UNPUBLISH_DISPATCH: {
      const currentComments = state.commentsByPostId[action.postId]
        ? state.commentsByPostId[action.postId].comments
        : [];
      const commentsForPost: CommentState['commentsByPostId'] = {};
      commentsForPost[action.postId] = {
        comments: [...currentComments],
        isUnpublishing: true,
        unPublishingId: action.commentId,
      };
      return {
        ...state,
        commentsByPostId: {
          ...state.commentsByPostId,
          ...commentsForPost,
        },
        error: null,
      };
    }

    case COMMENT_UNPUBLISH_FULFILL: {
      const commentsForPost: CommentState['commentsByPostId'] = {};
      commentsForPost[action.comment.post] = {
        comments: removeCommentFromComments(
          action.comment.id,
          state.commentsByPostId[action.comment.post].comments,
        ),
        unpublishedSinceLastFetched: [
          ...(state.commentsByPostId[action.comment.post]
            .unpublishedSinceLastFetched || []),
          action.comment.id,
        ],
        isUnpublishing: false,
        unPublishingId: null,
        unPublishError: null,
      };
      return {
        ...state,
        commentsByPostId: {
          ...state.commentsByPostId,
          ...commentsForPost,
        },
      };
    }

    case COMMENT_UNPUBLISH_REJECT: {
      const commentsForPost: CommentState['commentsByPostId'] = {};
      commentsForPost[action.postId] = {
        isUnpublishing: false,
        unPublishingId: null,
        unPublishError: action.error,
      };
      return {
        ...state,
        commentsByPostId: {
          ...state.commentsByPostId,
          ...commentsForPost,
        },
      };
    }

    default:
      return state;
  }
}
