import { FC } from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { renderCheckboxButtonGroup } from '../../util/formik-custom-fields';
import CheckboxButton from '../../components/CheckboxButton/CheckboxButton';
import { CheckboxButtonGroupProps } from '../../components/CheckboxButtonGroup/CheckboxButtonGroup';
import TaxonomyTerm from '../../types/TaxonomyTerm';
import { useTaxonomyOpportunities } from '../../util/APIDjango';

interface OpportunityInterestFieldProps {
  name: string;
  label: string;
}

const OpportunityInterestField: FC<OpportunityInterestFieldProps> = ({ name, label }) => {
  const { t } = useTranslation();

  const [opportunities] = useTaxonomyOpportunities();

  const handleRenderItems: CheckboxButtonGroupProps['renderItems'] = (
    _selectedValue,
    handleSelect,
    getSelectedState,
  ) =>
    opportunities.terms.map((term: TaxonomyTerm) => (
      <CheckboxButton
        key={term.id}
        id={`${term.vocabulary}-${term.id}`}
        value={term.id}
        // label={term.name}
        label={term.i18nKey ? t(`${term.i18nKey}.label`) : term.description}
        checked={getSelectedState(term.id)}
        onChange={() => handleSelect(term.id)}
      />
    ));

  return (
    <Field
      name={name}
      label={label}
      component={renderCheckboxButtonGroup}
      multiSelect
      renderItems={handleRenderItems}
    />
  );
};

export default OpportunityInterestField;
