import React from 'react';
import { OptionProps, components } from 'react-select';
import { Option } from './SelectField';
import styles from './SelectField.module.scss';

export const SingleValue = (props: OptionProps<Option>) => {
  const {
    data: { label, icon },
  } = props;
  return (
    <components.SingleValue {...props}>
      <div className={styles.option}>
        {icon && <img src={icon} />}
        {label}
      </div>
    </components.SingleValue>
  );
};
