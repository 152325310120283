import { Notification } from '../../types/Notification';

export const FETCH_NOTIFICATION_HISTORY_DISPATCH =
  'WIS/notifications/FETCH_NOTIFICATION_HISTORY_DISPATCHED';
export const FETCH_NOTIFICATION_HISTORY_PAGINATION_DISPATCH =
  'WIS/notifications/FETCH_NOTIFICATION_HISTORY_DISPATCHED';
export const FETCH_NOTIFICATION_HISTORY_FULFILL =
  'WIS/notifications/FETCH_NOTIFICATION_HISTORY_FULFILLED';
export const FETCH_NOTIFICATION_HISTORY_REJECT =
  'WIS/notifications/FETCH_NOTIFICATION_HISTORY_REJECTED';

export const fetchNotificationHistory = (pageNumber?: number) => ({
  type: FETCH_NOTIFICATION_HISTORY_DISPATCH,
  pageNumber: pageNumber | 1,
  pageSize: 10,
  nextPage: 2,
  notifications: [] as Notification[],
});

export const fetchNotificationHistoryPagination = (
  pageNumber: number,
  pageSize: number,
  nextPage: number,
  notifications: Notification[],
) => ({
  type: FETCH_NOTIFICATION_HISTORY_PAGINATION_DISPATCH,
  pageNumber,
  pageSize,
  nextPage,
  notifications,
});

export const fetchNotificationHistoryFulfill = (history: NotificationAction['history']) => ({
  type: FETCH_NOTIFICATION_HISTORY_FULFILL,
  history,
});
export const fetchNotificationHistoryReject = (error: NotificationAction['error']) => ({
  type: FETCH_NOTIFICATION_HISTORY_REJECT,
  error,
});

export const FETCH_NOTIFICATION_READ_DISPATCH =
  'WIS/notifications/FETCH_NOTIFICATION_READ_DISPATCHED';
export const FETCH_NOTIFICATION_READ_FULFILL =
  'WIS/notifications/FETCH_NOTIFICATION_READ_FULFILLED';

export const FETCH_NOTIFICATION_UNREAD = 'WIS/notifications/FETCH_NOTIFICATION_UNREAD';

export const FETCH_NOTIFICATION_READ_REJECT = 'WIS/notifications/FETCH_NOTIFICATION_READ_REJECTED';

export const fetchNotificationRead = () => ({
  type: FETCH_NOTIFICATION_READ_DISPATCH,
});
export const fetchNotificationReadFulfill = ({ count, unreadCount }: NotificationAction) => ({
  type: FETCH_NOTIFICATION_READ_FULFILL,
  count,
  unreadCount,
  pageNumber: 1,
  pageSize: 10,
  nextPage: 2,
});
export const fetchNotificationUnRead = (unreadCount: number) => ({
  type: FETCH_NOTIFICATION_UNREAD,
  unreadCount,
});

export const fetchNotificationReadReject = (error: NotificationAction['error']) => ({
  type: FETCH_NOTIFICATION_READ_REJECT,
  error,
});

export const UPDATE_NOTIFICATION_READ_DISPATCH =
  'WIS/notifications/UPDATE_NOTIFICATION_READ_DISPATCHED';
export const UPDATE_NOTIFICATION_READ_FULFILL =
  'WIS/notifications/UPDATE_NOTIFICATION_READ_FULFILLED';
export const UPDATE_NOTIFICATION_READ_REJECT =
  'WIS/notifications/UPDATE_NOTIFICATION_READ_REJECTED';

export const updateNotificationRead = (notificationIds: number[]) => ({
  type: UPDATE_NOTIFICATION_READ_DISPATCH,
  notificationIds,
});
export const updateNotificationReadFulfill = ({ count, unreadCount }: NotificationAction) => ({
  type: UPDATE_NOTIFICATION_READ_FULFILL,
  count,
  unreadCount,
});
export const updateNotificationReadReject = (error: NotificationAction['error']) => ({
  type: UPDATE_NOTIFICATION_READ_REJECT,
  error,
});

export const FETCH_NOTIFICATION_PREFERENCES_DISPATCH =
  'WIS/notifications/FETCH_NOTIFICATION_PREFERENCES_DISPATCHED';
export const FETCH_NOTIFICATION_PREFERENCES_FULFILL =
  'WIS/notifications/FETCH_NOTIFICATION_PREFERENCES_FULFILLED';
export const FETCH_NOTIFICATION_PREFERENCES_REJECT =
  'WIS/notifications/FETCH_NOTIFICATION_PREFERENCES_REJECTED';

export const fetchNotificationPreferences = () => ({
  type: FETCH_NOTIFICATION_PREFERENCES_DISPATCH,
});
export const fetchNotificationPreferencesFulfill = (
  preferences: NotificationAction['preferences'],
) => ({
  type: FETCH_NOTIFICATION_PREFERENCES_FULFILL,
  preferences,
});
export const fetchNotificationPreferencesReject = (error: NotificationAction['error']) => ({
  type: FETCH_NOTIFICATION_PREFERENCES_REJECT,
  error,
});

export const UPDATE_NOTIFICATION_PREFERENCES_DISPATCHED =
  'WIS/notifications/UPDATE_NOTIFICATION_PREFERENCES_DISPATCHED';
export const UPDATE_NOTIFICATION_PREFERENCES_FULFILLED =
  'WIS/notifications/UPDATE_NOTIFICATION_PREFERENCES_FULFILLED';
export const UPDATE_NOTIFICATION_PREFERENCES_REJECTED =
  'WIS/notifications/UPDATE_NOTIFICATION_PREFERENCES_REJECTED';

export const updateNotificationPreferencesDispatch = (
  preferences: NotificationAction['preferences'],
) => ({ type: UPDATE_NOTIFICATION_PREFERENCES_DISPATCHED, preferences });
export const updateNotificationPreferencesFulfill = (
  preferences: NotificationAction['preferences'],
) => ({ type: UPDATE_NOTIFICATION_PREFERENCES_FULFILLED, preferences });
export const updateNotificationPreferencesReject = (error: NotificationAction['error']) => ({
  type: UPDATE_NOTIFICATION_PREFERENCES_REJECTED,
  error,
});

export type NotificationActionTypes =
  | 'WIS/notifications/FETCH_NOTIFICATION_HISTORY_DISPATCHED'
  | 'WIS/notifications/FETCH_NOTIFICATION_HISTORY_FULFILLED'
  | 'WIS/notifications/FETCH_NOTIFICATION_HISTORY_REJECTED'
  | 'WIS/notifications/FETCH_NOTIFICATION_READ_DISPATCHED'
  | 'WIS/notifications/FETCH_NOTIFICATION_READ_FULFILLED'
  | 'WIS/notifications/FETCH_NOTIFICATION_READ_REJECTED'
  | 'WIS/notifications/UPDATE_NOTIFICATION_READ_DISPATCHED'
  | 'WIS/notifications/UPDATE_NOTIFICATION_READ_FULFILLED'
  | 'WIS/notifications/UPDATE_NOTIFICATION_READ_REJECTED'
  | 'WIS/notifications/FETCH_NOTIFICATION_PREFERENCES_DISPATCHED'
  | 'WIS/notifications/FETCH_NOTIFICATION_PREFERENCES_FULFILLED'
  | 'WIS/notifications/FETCH_NOTIFICATION_PREFERENCES_REJECTED'
  | 'WIS/notifications/UPDATE_NOTIFICATION_PREFERENCES_DISPATCHED'
  | 'WIS/notifications/UPDATE_NOTIFICATION_PREFERENCES_FULFILLED'
  | 'WIS/notifications/UPDATE_NOTIFICATION_PREFERENCES_REJECTED'
  | 'WIS/notifications/FETCH_NOTIFICATION_UNREAD';

export interface NotificationState {
  history: {
    count: number;
    unreadCount: number;
    notifications: Notification[];
    isFetching: boolean;
    isFetched: boolean;
    pageNumber: number;
    pageSize: number;
    nextPage: number;
    error?: string;
    readError?: string;
  };
  preferences: {
    notificationMethod?: string;
    isFetching: boolean;
    isFetched: boolean;
    error?: string;
    userId?: number;
    notifications?: boolean;
    pushEnabled?: boolean;
  };
}

const initialState: NotificationState = {
  history: {
    count: 0,
    unreadCount: 0,
    notifications: [],
    pageNumber: 1,
    pageSize: 10,
    nextPage: 2,
    isFetching: false,
    isFetched: false,
    error: null,
    readError: null,
  },
  preferences: {
    notificationMethod: null,
    isFetching: false,
    isFetched: false,
    error: null,
  },
};

export type NotificationAction = {
  readonly type: NotificationActionTypes;
  count?: number;
  unreadCount?: number;
  pageNumber?: number;
  pageSize: number;
  nextPage?: number;
  notifications: Notification[];
  history?: {
    results?: Notification[];
  } & NotificationState['history'];
  preferences?: NotificationState['preferences'];
  error?: string;
};

export default function reducer(state = initialState, action: NotificationAction) {
  switch (action.type) {
    case FETCH_NOTIFICATION_READ_FULFILL:
      return {
        ...state,
        history: {
          ...state.history,
          count: action.count,
          unreadCount: action.unreadCount,
          readError: null,
        },
      };

    case FETCH_NOTIFICATION_UNREAD:
      return {
        ...state,
        history: {
          ...state.history,
          unreadCount: action.unreadCount,
        },
      };
    case FETCH_NOTIFICATION_READ_REJECT:
      return {
        ...state,
        history: {
          ...state.history,
          readError: action.error,
        },
      };

    case UPDATE_NOTIFICATION_READ_FULFILL:
      return {
        ...state,
        history: {
          ...state.history,
          count: action.count,
          unreadCount: action.unreadCount,
          pageNumber: action.history.pageNumber,
          pageSize: action.history.pageSize,
          nextPage: action.history.nextPage,
          readError: null,
        },
      };

    case UPDATE_NOTIFICATION_READ_REJECT:
      return {
        ...state,
        history: {
          ...state.history,
          readError: action.error,
        },
      };

    case FETCH_NOTIFICATION_HISTORY_DISPATCH:
      return {
        ...state,
        history: {
          ...state.history,
          pageNumber: 1,
          pageSize: 10,
          nextPage: 2,
          notifications: [],
          isFetching: true,
          error: null,
        },
      };

    case FETCH_NOTIFICATION_HISTORY_PAGINATION_DISPATCH:
      return {
        ...state,
        history: {
          ...state.history,
          isFetching: true,
          error: null,
        },
      };
    case FETCH_NOTIFICATION_HISTORY_FULFILL:
      return {
        ...state,
        history: {
          ...state.history,
          pageNumber: action.pageNumber,
          notifications: [],
        },
      };
    case FETCH_NOTIFICATION_HISTORY_FULFILL:
      // initial fetch
      return {
        ...state,
        history: {
          ...state.history,
          notifications: action?.history?.results,
          isFetching: false,
          pageNumber: action.history.pageNumber,
          pageSize: action.history.pageSize,
          nextPage: action.history.nextPage,
          isFetched: true,
        },
      };

    case FETCH_NOTIFICATION_HISTORY_REJECT:
      return {
        ...state,
        history: {
          ...state.history,
          isFetching: false,
          isFetched: false,
          error: action.error,
        },
      };

    case FETCH_NOTIFICATION_PREFERENCES_DISPATCH:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          isFetching: true,
          error: null,
        },
      };

    case FETCH_NOTIFICATION_PREFERENCES_FULFILL:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          ...action.preferences,
          isFetching: false,
          isFetched: true,
        },
      };

    case FETCH_NOTIFICATION_PREFERENCES_REJECT:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          isFetching: false,
          isFetched: false,
          error: action.error,
        },
      };

    case UPDATE_NOTIFICATION_PREFERENCES_DISPATCHED:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          isUpdating: true,
          error: null,
        },
      };

    case UPDATE_NOTIFICATION_PREFERENCES_FULFILLED:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          ...action.preferences,
          isUpdating: false,
        },
      };

    case UPDATE_NOTIFICATION_PREFERENCES_REJECTED:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          isUpdating: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
}
