import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBell,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faTimes,
  faPlus,
  faHeart as fasHeart,
  faBookmark as fasBookmark,
  faSlidersH,
  faEllipsisV,
  faExclamationCircle,
  faImage,
  faCamera,
  faUsers,
  faThLarge,
  faTrash,
  faFileUpload,
  faGlobeEurope,
  faEye,
  faEyeSlash,
  faLanguage,
  faMessage,
  faReply,
  faPaperPlane as faPaperPlaneSolid,
} from '@fortawesome/free-solid-svg-icons';
import {
  faBookmark,
  faHeart,
  faComment,
  faComments,
  faPaperPlane,
  faEnvelope,
  faHourglass,
  faFile,
  faXmarkCircle
} from '@fortawesome/free-regular-svg-icons';
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faBell,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faTimes,
  faPlus,
  faEye,
  faEyeSlash,
  fasHeart,
  fasBookmark,
  faSlidersH,
  faEllipsisV,
  faExclamationCircle,
  faImage,
  faCamera,
  faUsers,
  faThLarge,
  faTrash,
  faFileUpload,
  faGlobeEurope,
  faLanguage,
  faMessage,
  faPaperPlaneSolid,
  faReply
);
library.add(
  faBookmark,
  faHeart,
  faComment,
  faComments,
  faPaperPlane,
  faEnvelope,
  faHourglass,
  faFile,
  faXmarkCircle
);
library.add(faFacebook, faTwitter, faInstagram, faYoutube);
