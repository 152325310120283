import React, { MouseEventHandler } from 'react';

import styles from './Button.module.scss';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

export interface ButtonProps {
  label: string;
  link?: string;
  to?: string;
  type?: 'submit' | 'reset' | 'button';
  fullWidth?: boolean;
  disabled?: boolean;
  loading?: boolean;
  center?: boolean;
  secondary?: boolean;
  largerButton?: boolean;
  paddingTop?: boolean;
  nexaFont?: boolean;
  inlineButtons?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  gutterTop?: boolean;
  textButton?: boolean;
  slim?: boolean;
}

const Button = ({
  type = 'button',
  label,
  to = null,
  link = null,
  fullWidth = false,
  disabled = false,
  loading = false,
  center = false,
  secondary = false,
  largerButton = false,
  nexaFont = false,
  inlineButtons = false,
  onClick = null,
  gutterTop = false,
  textButton = false,
  slim = false,
}: ButtonProps) => {
  const history = useHistory();
  const buttonStyles = clsx(
    styles.button,
    fullWidth && styles.fullWidth,
    (disabled || loading) && styles.disabled,
    center && styles.center,
    inlineButtons && styles.inlineButtons,
    secondary && styles.secondary,
    largerButton && styles.largerButton,
    nexaFont && styles.nexaFont,
    gutterTop && styles.gutterTop,
    textButton && styles.textButton,
    slim && styles.slim,
  );

  const handleClick: MouseEventHandler<HTMLButtonElement> = e => {
    if (disabled || loading) {
      return;
    }
    if (to) {
      history.push(to);
    } else if (link) {
      window.open(link, '_blank');
    } else {
      onClick(e);
    }
  };

  return (
    <button
      className={buttonStyles}
      type={type}
      disabled={disabled || loading}
      onClick={type === 'submit' ? undefined : handleClick}>
      {loading && <div className={styles.loader}></div>}
      <span className={styles.label}>{label}</span>
    </button>
  );
};

export default Button;
