import React from 'react';

export const DotMenu = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_875_17877"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24">
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_875_17877)">
      <path
        d="M11.9998 20.0005C11.4498 20.0005 10.9789 19.8047 10.5873 19.413C10.1956 19.0213 9.99976 18.5505 9.99976 18.0005C9.99976 17.4505 10.1956 16.9797 10.5873 16.588C10.9789 16.1963 11.4498 16.0005 11.9998 16.0005C12.5498 16.0005 13.0206 16.1963 13.4123 16.588C13.8039 16.9797 13.9998 17.4505 13.9998 18.0005C13.9998 18.5505 13.8039 19.0213 13.4123 19.413C13.0206 19.8047 12.5498 20.0005 11.9998 20.0005ZM11.9998 14.0005C11.4498 14.0005 10.9789 13.8047 10.5873 13.413C10.1956 13.0213 9.99976 12.5505 9.99976 12.0005C9.99976 11.4505 10.1956 10.9797 10.5873 10.588C10.9789 10.1963 11.4498 10.0005 11.9998 10.0005C12.5498 10.0005 13.0206 10.1963 13.4123 10.588C13.8039 10.9797 13.9998 11.4505 13.9998 12.0005C13.9998 12.5505 13.8039 13.0213 13.4123 13.413C13.0206 13.8047 12.5498 14.0005 11.9998 14.0005ZM11.9998 8.00049C11.4498 8.00049 10.9789 7.80466 10.5873 7.41299C10.1956 7.02132 9.99976 6.55049 9.99976 6.00049C9.99976 5.45049 10.1956 4.97966 10.5873 4.58799C10.9789 4.19632 11.4498 4.00049 11.9998 4.00049C12.5498 4.00049 13.0206 4.19632 13.4123 4.58799C13.8039 4.97966 13.9998 5.45049 13.9998 6.00049C13.9998 6.55049 13.8039 7.02132 13.4123 7.41299C13.0206 7.80466 12.5498 8.00049 11.9998 8.00049Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
