// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-FieldLabel-module-wrapper-bx9mm{margin-bottom:4px;color:#eee;font-size:20px;font-weight:bold;line-height:24px;margin-bottom:8px}.-FieldLabel-module-wrapper-bx9mm span{font-weight:normal;font-size:16px;line-height:20px}.-FieldLabel-module-wrapper-bx9mm.-FieldLabel-module-light-UPqyW{color:#fff}.-FieldLabel-module-disabled-tvkGl{color:#cfd8dc}`, "",{"version":3,"sources":["webpack://./src/components/FieldLabel/FieldLabel.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAEA,kCACE,iBAAA,CACA,UC+Ca,CD9Cb,cAAA,CACA,gBAAA,CACA,gBAAA,CACA,iBAAA,CACA,uCACE,kBAAA,CACA,cAAA,CACA,gBAAA,CAIJ,iEACE,UCNM,CDSR,mCACE,aCNS","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-FieldLabel-module-wrapper-bx9mm`,
	"light": `-FieldLabel-module-light-UPqyW`,
	"disabled": `-FieldLabel-module-disabled-tvkGl`
};
export default ___CSS_LOADER_EXPORT___;
