import { PostCategory } from '../../types/PostCategory';

export const GET_POST_CATEGORIES_DISPATCH =
  'WIS/posts/GET_POST_CATEGORIES_DISPATCH';
export const GET_POST_CATEGORIES_FULFILL =
  'WIS/posts/GET_POST_CATEGORIES_FULFILLED';
export const GET_POST_CATEGORIES_REJECT =
  'WIS/posts/GET_POST_CATEGORIES_REJECTED';

export type PostCategoriesActionTypes =
  | 'WIS/posts/GET_POST_CATEGORIES_DISPATCH'
  | 'WIS/posts/GET_POST_CATEGORIES_FULFILLED'
  | 'WIS/posts/GET_POST_CATEGORIES_REJECTED';

export const getPostCategoriesDispatch = () => ({
  type: GET_POST_CATEGORIES_DISPATCH,
});

export const getPostCategoriesFulfill = (categories: PostCategory[]) => ({
  type: GET_POST_CATEGORIES_FULFILL,
  categories,
});

export const getPostCategoriesReject = (error: Error) => ({
  type: GET_POST_CATEGORIES_REJECT,
  error,
});

export interface PostCategoryState {
  categories: PostCategory[];
  isFetching: boolean;
  isFetched: boolean;
  fetchError: string;
}

const initialState: PostCategoryState = {
  categories: [],
  isFetching: false,
  isFetched: false,
  fetchError: null,
};

export type PostCategoryAction = {
  readonly type: PostCategoriesActionTypes;
  categories: PostCategory[] | [];
  error: string;
};

export default function reducer(
  state = initialState,
  action: PostCategoryAction,
) {
  switch (action.type) {
    case GET_POST_CATEGORIES_DISPATCH:
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        fetchError: '',
      };

    case GET_POST_CATEGORIES_FULFILL:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        categories: action.categories,
        fetchError: '',
      };

    case GET_POST_CATEGORIES_REJECT:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        fetchError: action.error,
      };

    default:
      return state;
  }
}
