import { useCallback, useState, useEffect, ChangeEventHandler } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldStatusBlock from '../FieldStatusBlock/FieldStatusBlock';
import styles from './DropZoneField.module.scss';
import clsx from 'clsx';
import { Close } from '../Icons/Close';
import { Play } from '../Icons/Play';
import { useTranslation } from 'react-i18next';

export interface DropZoneFieldProps {
  acceptedMimeTypes?: string[];
  maxUploadSize?: number;
  icon?: JSX.Element;
  name?: string;
  label?: string;
  onChange?: (file?: File) => void;
  errorText?: string;
  focused?: boolean;
  touched?: boolean;
  value: string;
  thumbnail?: boolean;
  video?: boolean;
}

const DropZoneField = ({
  acceptedMimeTypes,
  maxUploadSize = 10485760, // 10mb
  icon = <FontAwesomeIcon icon={['fas', 'file-upload']} />,
  label = null,
  onChange = null,
  errorText = null,
  focused = false,
  touched = false,
  thumbnail,
  value,
  video,
}: DropZoneFieldProps) => {
  const [imageData, setImageData] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [error, setError] = useState('');
  const { t } = useTranslation();
  useEffect(() => {
    if (!value) {
      setImageData(null);
      setImageName(null);
    }
  }, [value]);

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: File[]) => {
    setError('');
    if (rejectedFiles.length) {
      // TODO Display error when unsupported files added.
      rejectedFiles.forEach(file => {
        if (file.size > maxUploadSize) {
          setError(`${file.name} ${t('fileDocTooLarge.label')}`);
          return;
        }
      });
      return;
    }
    const reader = new FileReader();
    reader.onload = e => setImageData(e.target.result);
    reader.readAsDataURL(acceptedFiles[0]);
    setImageName(acceptedFiles[0].name);
    onChange(acceptedFiles[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: acceptedMimeTypes.toString(),
    maxSize: maxUploadSize,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const removeImage = (e: any) => {
    e.stopPropagation();
    setImageData(null);
    setImageName(null);
    onChange();
  };

  return (
    <div className={styles.fieldWrapper}>
      <FieldStatusBlock
        error={errorText || error}
        focused={focused}
        touched={touched || !!error}
        className={styles.statusBlock}>
        <div
          {...getRootProps({
            className: styles.trigger,
          })}>
          <input {...getInputProps({})} />
          {thumbnail && imageData && (
            <a onClick={removeImage} className={styles.removeThumb}>
              {<Close />}
            </a>
          )}
          <div
            className={clsx(styles.fileThumb, {
              [styles.fileSelected]: !!imageData && !thumbnail,
              [styles.thumbnail]: thumbnail,
            })}
            style={
              imageData && thumbnail && !video ? { backgroundImage: `url(${imageData})` } : null
            }>
            {!imageData ? (
              <>
                <div className={styles.uploadIcon}>
                  {icon}
                  {label}
                </div>
                {maxUploadSize && (
                  <div className={styles.maxUploadSize}>
                    {t('maximumSize.label')}:{' '}
                    <span>{`${Math.round(maxUploadSize / 1024 / 1024)}MB`}</span>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className={styles.selectedDocument}>
                  {imageName}
                  <div className={styles.clearText}>
                    <a onClick={removeImage}>{t('clear.label')}</a>
                  </div>
                </div>
                {video && (
                  <>
                    <video src={imageData} className={styles.videoThumb} />
                    <div className={styles.playIcon}>
                      <Play />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </FieldStatusBlock>
    </div>
  );
};

export default DropZoneField;
