import getUserLocale from 'get-user-locale';
import { isNative } from './isNative';

export const LOCALE = getUserLocale().toLowerCase();
export const SUPPORT_EMAIL = 'info@invictusgames.org';
const DOMAIN_DEVELOP = 'http://localhost:8000';
const DOMAIN_UIC_STAGING = 'https://uic-ig-int-staging.uksouth.cloudapp.azure.com';
const DOMAIN_STAGING = 'https://staging.api-v1.igfwis.com';
const DOMAIN_PRODUCTION = 'https://api-v1.igfwis.com';

const WS_DOMAIN_DEVELOP = 'ws://localhost:8000';
const WS_DOMAIN_UIC_STAGING = 'wss://uic-ig-int-staging.uksouth.cloudapp.azure.com';
const WS_DOMAIN_STAGING = 'wss://staging.api-v1.igfwis.com';
const WS_DOMAIN_PRODUCTION = 'wss://api-v1.igfwis.com';

let apiDomain = DOMAIN_DEVELOP;
let wsDomain = WS_DOMAIN_DEVELOP;

const env = process.env.NODE_ENVIRONMENT;

if (env === 'uic-staging') {
  apiDomain = DOMAIN_UIC_STAGING;
  wsDomain = WS_DOMAIN_UIC_STAGING;
} else if (env === 'staging') {
  apiDomain = DOMAIN_STAGING;
  wsDomain = WS_DOMAIN_STAGING;
} else if (env === 'production') {
  apiDomain = DOMAIN_PRODUCTION;
  wsDomain = WS_DOMAIN_PRODUCTION;
}

export const FEATURE_FLAGS = {
  boards: true,
  savedPosts: false,
  likedPosts: true,
  forgotPassword: true,
  storeLinks: !isNative,
  googleTranslate: true,
  notifications: true,
  chat: true,
  chatLikes: false,
};

export const ENVIRONMENT = env;
export const API_DOMAIN = apiDomain;
export const WS_API_DOMAIN = wsDomain;
