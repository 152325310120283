import {
  COMMENT_CREATE_FULFILL,
  COMMENT_GET_BY_POST_ID_DISPATCH,
  COMMENT_UNPUBLISH_DISPATCH,
  commentGetByPostIdDispatch,
  commentGetByPostIdFulfill,
  commentGetByPostIdReject,
  commentUnpublishFulfill,
  commentUnpublishReject,
} from '../modules/comment';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

import api from '../../util/api';
import { combineEpics, ofType } from 'redux-observable';

export const commentsGetAllEpic = action$ =>
  action$.pipe(
    ofType(COMMENT_GET_BY_POST_ID_DISPATCH),
    mergeMap(({ postId }) =>
      from(api.commentsForPost(postId)).pipe(
        map(comments => {
          // Sort comments by lastModified in descending order (latest first)
          const sortedComments = comments.sort((a, b) => {
            const dateA = new Date(a.lastModified);
            const dateB = new Date(b.lastModified);
            return dateB - dateA;
          });
          return commentGetByPostIdFulfill(postId, sortedComments);
        }),
        catchError(error => of(commentGetByPostIdReject(error))),
      ),
    ),
  );
export const fetchCommentsOnCreate = action$ =>
  action$.pipe(
    ofType(COMMENT_CREATE_FULFILL),
    map(({ postId }) => commentGetByPostIdDispatch(postId)),
  );

export const unpublishCommentEpic = action$ =>
  action$.pipe(
    ofType(COMMENT_UNPUBLISH_DISPATCH),
    mergeMap(({ commentId }) =>
      from(api.unpublishComment(commentId)).pipe(
        map(unpublishedComment => commentUnpublishFulfill(unpublishedComment)),
        catchError(error => of(commentUnpublishReject(error))),
      ),
    ),
  );

export default combineEpics(commentsGetAllEpic, fetchCommentsOnCreate, unpublishCommentEpic);
