import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './HideShow.module.scss';
import clsx from 'clsx';

const HideShow = ({ children, show }) => {
  const [extraClass, setExtraClass] = useState(false);
  useEffect(() => {
    setExtraClass(show ? styles.show : '');
  }, [show]);
  return <div className={clsx(styles.wrapper, extraClass && styles.show)}>{children}</div>;
};

HideShow.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default HideShow;
