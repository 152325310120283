import { PostType } from '../../types/Post';

export const GET_POSTS_DISPATCH = 'WIS/posts/GET_POSTS_DISPATCHED';
export const GET_POSTS_DISPATCH_FILTERED =
  'WIS/posts/GET_POSTS_DISPATCHED_FILTERED';
export const GET_POSTS_FULFILL = 'WIS/posts/GET_POSTS_FULFILLED';
export const GET_POSTS_REJECT = 'WIS/posts/GET_POSTS_REJECTED';

export const GET_POSTS_GLOBAL_DISPATCH =
  'WIS/posts/GET_POSTS_GLOBAL_DISPATCHED';

export const POST_CREATE_DISPATCH = 'WIS/posts/POST_CREATE_DISPATCHED';
export const POST_CREATE_FULFILL = 'WIS/posts/POST_CREATE_FULFILLED';
export const POST_CREATE_REJECT = 'WIS/posts/POST_CREATE_REJECTED';

export const POST_UNPUBLISH_DISPATCH = 'WIS/posts/POST_UNPUBLISH_DISPATCHED';
export const POST_UNPUBLISH_FULFILL = 'WIS/posts/POST_UNPUBLISH_FULFILLED';
export const POST_UNPUBLISH_REJECT = 'WIS/posts/POST_UNPUBLISH_REJECTED';

export type PostActionTypes =
  | 'WIS/posts/GET_POSTS_DISPATCHED'
  | 'WIS/posts/GET_POSTS_DISPATCHED_FILTERED'
  | 'WIS/posts/GET_POSTS_FULFILLED'
  | 'WIS/posts/GET_POSTS_REJECTED'
  | 'WIS/posts/GET_POSTS_GLOBAL_DISPATCHED'
  | 'WIS/posts/POST_CREATE_DISPATCHED'
  | 'WIS/posts/POST_CREATE_FULFILLED'
  | 'WIS/posts/POST_CREATE_REJECTED'
  | 'WIS/posts/POST_UNPUBLISH_DISPATCHED'
  | 'WIS/posts/POST_UNPUBLISH_FULFILLED'
  | 'WIS/posts/POST_UNPUBLISH_REJECTED';

export const getPostsDispatch = (
  boardId?: number,
  uid?: number,
  keywords?: string,
  offset?: number,
  limit?: number,
  isFullRefresh?: boolean,
  categoryId?: number,
) => ({
  type: GET_POSTS_DISPATCH,
  boardId,
  uid,
  keywords,
  offset,
  limit,
  isFullRefresh,
  categoryId,
});

export const getPostsDispatchFiltered = (
  boardId?: number,
  uid?: number,
  keywords?: string,
) => ({
  type: GET_POSTS_DISPATCH_FILTERED,
  boardId,
  uid,
  keywords,
});

export const getPostsFulfill = (
  posts: PostType[],
  uid: number,
  isFullRefresh: boolean,
) => ({
  type: GET_POSTS_FULFILL,
  posts,
  uid,
  isFullRefresh,
});
export const getPostsReject = (error: string) => ({
  type: GET_POSTS_REJECT,
  error,
});

export const getPostsGlobalDispatch = (boardId: number) => ({
  type: GET_POSTS_GLOBAL_DISPATCH,
  boardId,
});

export const postCreateDispatch = (values: Partial<PostType>) => ({
  type: POST_CREATE_DISPATCH,
  values,
});
export const postCreateFulfill = (post: PostType) => ({
  type: POST_CREATE_FULFILL,
  post,
});
export const postCreateReject = (error: string) => ({
  type: POST_CREATE_REJECT,
  error,
});

export const postUnpublishDispatch = (postId: string) => ({
  type: POST_UNPUBLISH_DISPATCH,
  postId,
});
export const postUnpublishFulfill = (post: PostType) => ({
  type: POST_UNPUBLISH_FULFILL,
  post,
});
export const postUnpublishReject = (error: string) => ({
  type: POST_UNPUBLISH_REJECT,
  error,
});

export interface PostState {
  posts: PostType[];
  isFetching: boolean;
  isFetched: boolean;
  lastFetched?: number;
  isPosting: boolean;
  isPosted: boolean;
  unpublishedSinceLastFetched: PostType['id'][];
  isUnpublishing: boolean;
  unPublishingId?: PostType['id'] | null;
  unPublishError?: string;
  fetchError?: string;
  createError?: string;
}

const initialState: PostState = {
  posts: [],
  isFetching: false,
  isFetched: false,
  lastFetched: null,
  isPosting: false,
  isPosted: false,
  unpublishedSinceLastFetched: [],
  isUnpublishing: false,
  unPublishingId: null,
  unPublishError: null,
  fetchError: null,
  createError: null,
};

const removePostFromPosts = (postId: PostType['id'], posts: PostType[]) => {
  const newPostsState = [...posts];
  const postIndex = newPostsState.findIndex(post => post.id === postId);
  newPostsState.splice(postIndex, 1);
  return newPostsState;
};

export type PostAction = {
  readonly type: PostActionTypes;
  boardId?: number;
  error?: string;
  postId?: string;
  post?: PostType;
  keywords?: string;
  uid?: number;
  isFullRefresh?: boolean;
} & Partial<Pick<PostState, 'posts' | 'unPublishingId'>>;

export default function reducer(state = initialState, action: PostAction) {
  switch (action.type) {
    case POST_CREATE_DISPATCH:
      return {
        ...state,
        isPosting: true,
        isPosted: false,
        createError: null,
      };

    case POST_CREATE_FULFILL:
      return {
        ...state,
        isPosting: false,
        isPosted: true,
        createError: null,
      };

    case POST_CREATE_REJECT:
      return {
        ...state,
        isPosting: false,
        isPosted: false,
        createError: action.error,
      };

    case GET_POSTS_DISPATCH:
      if (action.isFullRefresh) {
        return {
          ...state,
          posts: [],
          isFetching: true,
          isFetched: false,
          fetchError: null,
        };
      }

      return {
        ...state,
        isFetching: true,
        isFetched: false,
        fetchError: null,
      };

    case GET_POSTS_DISPATCH_FILTERED:
      const filteredPosts = state.posts.filter(
        post =>
          post.author.username
            .toLowerCase()
            .includes(action.keywords.toLowerCase()) ||
          post.title.toLowerCase().includes(action.keywords.toLowerCase()) ||
          post.content.toLowerCase().includes(action.keywords.toLowerCase()),
      );

      return {
        ...state,
        isFetching: false,
        isFetched: true,
        posts: filteredPosts || state.posts,
        lastFetched: Date.now(),
        unpublishedSinceLastFetched: [],
        fetchError: null,
      };

    case GET_POSTS_GLOBAL_DISPATCH:
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        fetchError: null,
      };

    case GET_POSTS_FULFILL:
      console.log('action.isFullRefresh', action.isFullRefresh);
      let newPosts = action.posts;
      if (action.uid) {
        // Filter posts based on uid
        newPosts = newPosts.filter(post => post.author.uuid === action.uid);
      }

      // Determine if this is a full refresh or infinite scroll update
      const isFullRefresh = action.isFullRefresh || false;

      // For full refresh, replace the posts; for infinite scroll, concatenate new posts
      const updatedPosts = isFullRefresh
        ? newPosts
        : state.posts.concat(
            newPosts.filter(
              newPost =>
                !state.posts.some(
                  existingPost => existingPost.id === newPost.id,
                ),
            ),
          );

      return {
        ...state,
        isFetching: false,
        isFetched: true,
        posts: updatedPosts,
        lastFetched: Date.now(),
        unpublishedSinceLastFetched: [],
        fetchError: null,
      };

    case GET_POSTS_REJECT:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        fetchError: action.error,
      };

    case POST_UNPUBLISH_DISPATCH:
      return {
        ...state,
        isUnpublishing: true,
        unPublishingId: action.postId,
        unPublishError: null,
      };

    case POST_UNPUBLISH_FULFILL:
      return {
        ...state,
        posts: removePostFromPosts(action.post.id, state.posts),
        isUnpublishing: false,
        unpublishedSinceLastFetched: [
          ...state.unpublishedSinceLastFetched,
          action.post.id,
        ],
        unPublishingId: null,
        unPublishError: null,
      };

    case POST_UNPUBLISH_REJECT:
      return {
        ...state,
        isUnpublishing: false,
        unPublishingId: null,
        unPublishError: action.error,
      };

    default:
      return state;
  }
}
