// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-Card-module-wrapper-sycwd{overflow:hidden;background-color:#222;width:100%;border-radius:12px;transition:all .25s ease;padding:20px}@media only screen and (min-width: 768px){.-Card-module-wrapper-sycwd{max-width:550px}}.-Card-module-wrapper-sycwd .-Card-module-meta-ze2Dq{width:100%;display:flex;flex-direction:row;align-items:center;color:#eee}.-Card-module-wrapper-sycwd .-Card-module-meta-ze2Dq .-Card-module-avatar-kOhuz{margin-right:16px}.-Card-module-wrapper-sycwd .-Card-module-meta-ze2Dq .-Card-module-titles-Szh80{display:flex;flex-direction:column;flex:1;font-size:24px;font-style:normal;font-family:"nexa";font-size:22px;font-style:normal;font-weight:700;line-height:24px;align-items:center}.-Card-module-wrapper-sycwd .-Card-module-meta-ze2Dq .-Card-module-titles-Szh80 .-Card-module-subtitle-g9ra4{font-size:.875rem;color:#546e7a}.-Card-module-wrapper-sycwd .-Card-module-content-HED4E{text-align:center;color:#eee}.-Card-module-wrapper-sycwd .-Card-module-content-HED4E .-Card-module-image-AbBCB{width:100%}.-Card-module-wrapper-sycwd .-Card-module-content-HED4E p{font-size:17px;font-style:normal;font-weight:400;line-height:22px;margin:0}`, "",{"version":3,"sources":["webpack://./src/components/Card/Card.module.scss","webpack://./src/theme/_colors.scss","webpack://./src/theme/_dimensions.scss"],"names":[],"mappings":"AAGA,4BACE,eAAA,CACA,qBCqCe,CDpCf,UAAA,CACA,kBAAA,CACA,wBAAA,CACA,YAAA,CAEA,0CARF,4BASI,eAAA,CAAA,CAGF,qDACE,UAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,UC+BW,CD7BX,gFACE,iBEjBG,CFoBL,gFACE,YAAA,CACA,qBAAA,CACA,MAAA,CACA,cAAA,CACA,iBAAA,CACA,kBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,kBAAA,CAEA,6GACE,iBAAA,CACA,aCrBG,CD0BT,wDACE,iBAAA,CAIA,UAAA,CAHA,kFACE,UAAA,CAGF,0DACE,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,QAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-Card-module-wrapper-sycwd`,
	"meta": `-Card-module-meta-ze2Dq`,
	"avatar": `-Card-module-avatar-kOhuz`,
	"titles": `-Card-module-titles-Szh80`,
	"subtitle": `-Card-module-subtitle-g9ra4`,
	"content": `-Card-module-content-HED4E`,
	"image": `-Card-module-image-AbBCB`
};
export default ___CSS_LOADER_EXPORT___;
