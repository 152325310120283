import { useEffect, useRef, useState } from 'react';

import { LexicalComposer } from '@lexical/react/LexicalComposer';
import ActionableChatMessage from '../ChatMessage/ActionableChatMessage';
import ChatForm from '../../forms/ChatForm/ChatForm';
import Loader from '../Loader/Loader';
import s from './ChatWindow.module.scss';
import { useParams } from 'react-router';
import { ChatMessage, ChatWsEvents } from '../../types/ChatMessage';
import api from '../../util/api';
import { useMessages } from '../../util/APIDjango';
import { addMessagesToList } from '../../redux/modules/chat';
import useWebSocket from 'react-use-websocket';
import { WS_API_DOMAIN } from '../../util/config';
import { useProfileData } from '../../hooks/useProfileData';
import { MentionNode } from '../MentionsEditor/MentionNode';

export interface ChatWindowProps {
  chatId: number;
}

export interface MessagesByRoom {
  likedByMe: any[];
  next: any;
  previous: any;
  results: any[];
}

const initialConfig = {
  namespace: 'editor',
  nodes: [MentionNode],
  onError: (error: Error) => {
    throw error;
  },
};

// const mapStateToProps = ({ blocked }: RootState) => ({
//   blockedUsers: blocked.users,
// });

const ChatWindow = ({ chatId }: ChatWindowProps): JSX.Element => {
  sessionStorage.setItem('reloadNotification', 'Y');
  const { messageId } = useParams<{ messageId?: string }>();
  const LIMIT = 20;
  const AROUND = messageId;
  const [messagesData, setMessagesData] = useMessages();

  const [socketError, setSocketError] = useState<string>();
  const [wsAuthenticated, setWsAuthenticated] = useState<boolean>(false);

  const [onlyMessages, setOnlyMessages] = useState(messagesData?.messagesByRoom?.results || []);

  const [fetchingNext, setFetchingNext] = useState<boolean>(false);

  const { sendJsonMessage, lastJsonMessage } = useWebSocket(
    `${WS_API_DOMAIN}/chat-rooms/${chatId}/websocket/`,
    {
      shouldReconnect: closeEvent => false,
      onOpen: () => {},
      reconnectInterval: 50000,
      onClose: event => {
        switch (event.code) {
          // case 4002: // user banned
          // case 4003: // chat room deleted
          // case 4000: // user not authenticated
          // case 1006: // connection closed unexpectedly
          default:
            // history.push('/chat/');
            console.log('WebSocket closed:', event.code, event.reason);
            break;
        }
        // dispatch(getChatRoomsDispatch());
      },
      onError: error => {
        console.log('WebSocket error:', error);
      },
    },
  );

  const deleteMessage = (messageId: number) => {
    setMessagesData(current => ({
      ...current,
      messagesByRoom: {
        ...current.messagesByRoom,
        results: current.messagesByRoom.results.filter(message => message?.id !== messageId),
      },
    }));
  };

  useEffect(() => {
    if (lastJsonMessage) {
      switch (lastJsonMessage.type) {
        case 'message.saved':
          // setMessagesData(prevMessagesData => {
          //   const updatedResults = prevMessagesData.messagesByRoom.results.map(
          //     message =>
          //       message.id === lastJsonMessage.message.id
          //         ? lastJsonMessage.message
          //         : message,
          //   );

          //   // Checking if the message was not found and add it to the results
          //   const messageExists = updatedResults.some(
          //     message => message.id === lastJsonMessage.message.id,
          //   );
          //   if (!messageExists) {
          //     updatedResults.push(lastJsonMessage.message);
          //   }

          //   return {
          //     ...prevMessagesData,
          //     messagesByRoom: {
          //       ...prevMessagesData.messagesByRoom,
          //       results: updatedResults,
          //     },
          //     lastMessageIdFetched: lastJsonMessage.id,
          //   };
          // });

          setMessagesData(prevMessagesData => {
            const newMessage = {
              ...lastJsonMessage.message,
              created: new Date(lastJsonMessage.message.created),
              lastModified: new Date(lastJsonMessage.message.lastModified),
            };

            return {
              ...prevMessagesData,
              messagesByRoom: {
                ...prevMessagesData.messagesByRoom,
                results: addMessagesToList(prevMessagesData.messagesByRoom.results, [newMessage]),
              },
              lastMessageIdFetched: lastJsonMessage.id,
            };
          });

          setTimeout(() => {
            setShouldScrollAfterReceive(true);
          }, 500);

          break;

        case 'message.likes_count.saved':
          setMessagesData(prevMessagesData => ({
            ...prevMessagesData,
            messagesByRoom: {
              ...prevMessagesData.messagesByRoom,
              results: prevMessagesData.messagesByRoom.results.map(message =>
                message.id === lastJsonMessage.messageId
                  ? { ...message, likesCount: lastJsonMessage.likesCount }
                  : message,
              ),
            },
          }));
          break;

        default:
          break;
      }
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    !wsAuthenticated &&
      api
        .getToken()
        .then((token: string) => sendJsonMessage({ type: ChatWsEvents.AUTHENTICATION, token }))
        .catch((e: Error) => setSocketError(e.message));
  }, [sendJsonMessage, wsAuthenticated]);

  const getChatMessages = () =>
    setMessagesData({
      ...messagesData,
      messagesFetched: false,
      messagesFetching: true,
      isFetching: true,
    });

  const getChatMessagesFulfil = (data: MessagesByRoom, isPrevious: boolean) => {
    let updatedMessagesByRoom;

    if (isPrevious) {
      let likedByMeCombined = [
        ...(messagesData.messagesByRoom.likedByMe || []),
        ...(data.likedByMe || []),
      ];
      likedByMeCombined = Array.from(new Set(likedByMeCombined));

      updatedMessagesByRoom = {
        ...messagesData.messagesByRoom,
        results: addMessagesToList(messagesData.messagesByRoom.results || [], data?.results || []),
        likedByMe: likedByMeCombined,
        next: data?.next,
        previous: data?.previous,
      };
    } else {
      updatedMessagesByRoom = {
        ...messagesData.messagesByRoom,
        results: addMessagesToList(messagesData.messagesByRoom?.results || [], data?.results || []),
        likedByMe: data.likedByMe,
        next: data.next,
        previous: data.previous,
      };
    }

    setMessagesData({
      ...messagesData,
      lastMessageIdFetched: data.results[data.results.length - 1].id,
      isFetched: true,
      isFetching: false,
      messagesByRoom: updatedMessagesByRoom,
    });
  };

  const getChatMessagesReject = (error: string) =>
    setMessagesData({
      ...messagesData,
      fetchError: error,
      messagesFetched: true,
      messagesFetching: false,
    });

  const chatMessages = () => {
    getChatMessages();
    api
      .chatMessages(chatId, { limit: LIMIT, around: AROUND })
      .then((messageData: MessagesByRoom) => {
        getChatMessagesFulfil(messageData, false);
        setTimeout(() => {
          handleScrollToMessage();
        }, 100);
      })
      .catch(err => {
        getChatMessagesReject(err.message);
      });
  };

  useEffect(() => {
    chatMessages();
  }, []);

  const fetchPrevious = () => {
    if (messagesData.messagesByRoom.previous) {
      getChatMessages();
      api
        .chatMessages(chatId, {
          // around: messagesData.messagesByRoom.results[0].id,
          url: messagesData.messagesByRoom.previous,
        })
        .then((messageData: MessagesByRoom) => {
          getChatMessagesFulfil(messageData, true);
        })
        .catch(err => {
          getChatMessagesReject(err.message);
        });
    }
  };

  const fetchNext = () => {
    setFetchingNext(true);
    if (messagesData.messagesByRoom.next) {
      getChatMessages();
      api
        .chatMessages(chatId, {
          // around: messagesData.messagesByRoom.results[0].id,
          url: messagesData.messagesByRoom.next,
        })
        .then((messageData: MessagesByRoom) => {
          getChatMessagesFulfil(messageData, true);
          setFetchingNext(false);
        })
        .catch(err => {
          getChatMessagesReject(err.message);
          setFetchingNext(false);
        });
    }
  };

  const [replyMessage, setReplyMessage] = useState<ChatMessage | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  // const { blockedUsers } = useSelector(mapStateToProps);
  const { profileData } = useProfileData();

  const [quickViewMessageId, setQuickViewMessageId] = useState<number | null>(null);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
  const [shouldScrollAfterSend, setShouldScrollAfterSend] = useState(false);
  const [shouldScrollAfterReceive, setShouldScrollAfterReceive] = useState(false);

  // useEffect(() => {
  //   if (messagesData.isFetching) return;
  //   if (messageId) {
  //     handleScrollToMessage();
  //   } else if (shouldScrollToBottom) {
  //     scrollContainerRef.current?.scrollTo(0, scrollContainerRef.current.scrollHeight);
  //   }
  // }, [messagesData.messagesByRoom.results, messageId, shouldScrollToBottom]);

  useEffect(() => {
    const scrollWindow = document.getElementById('scrollWindow');

    const handleScroll = () => {
      if (scrollWindow.scrollHeight - scrollWindow.scrollTop <= scrollWindow.clientHeight + 1) {
        fetchNext();
        setShouldScrollToBottom(true);
      } else if (scrollWindow.scrollTop === 0) {
        fetchPrevious();
        setShouldScrollToBottom(false);
      }
    };

    scrollWindow.addEventListener('scroll', handleScroll);

    return () => {
      scrollWindow.removeEventListener('scroll', handleScroll);
    };
  }, [messagesData]);

  const handleScrollToMessage = () => {
    console.log('coming here');
    const element = document.getElementById(`message_${messageId}`);
    if (messageId && element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        setShouldScrollToBottom(false);
      }, 500);
    } else {
      scrollContainerRef.current?.scrollTo(0, scrollContainerRef.current.scrollHeight);
      setShouldScrollToBottom(true);
    }
  };

  const updateMessageView = (data: any) => {
    const index = messagesData.messagesByRoom.results.findIndex(msg => msg.id === data.id);
    const updatedResults = [...messagesData.messagesByRoom.results];
    updatedResults[index] = {
      ...updatedResults[index],
      isHidden: !data.isHidden,
    };
    setMessagesData({
      ...messagesData,
      messagesByRoom: {
        ...messagesData.messagesByRoom,
        results: updatedResults,
      },
    });
    // setOnlyMessages(updatedResults); // Update onlyMessages state here
    // setShouldScrollToBottom(false);
  };

  const sendMessage = (content: string, replyMessageId: number, callback?: () => void) =>
    api
      .createChatMessage(chatId, content, replyMessageId)
      .then((res: ChatMessage) => {
        if (callback) {
          callback();
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });

  useEffect(() => {
    if (messageId) {
      handleScrollToMessage();
    }
  }, [messageId]);

  useEffect(() => {
    if (shouldScrollAfterSend) {
      scrollContainerRef.current?.scrollTo(0, scrollContainerRef.current.scrollHeight);
      setShouldScrollAfterSend(false);
    }
    if (shouldScrollAfterReceive) {
      scrollContainerRef.current?.scrollTo(0, scrollContainerRef.current.scrollHeight);
      setShouldScrollAfterReceive(false);
    }
  }, [shouldScrollAfterReceive]);

  useEffect(() => {
    setOnlyMessages(messagesData.messagesByRoom.results);
  }, [messagesData]);

  return (
    messagesData &&
    onlyMessages && (
      <>
        {messagesData.fetchError && <p>{messagesData.fetchError}</p>}
        <div
          id={'scrollWindow'}
          ref={scrollContainerRef}
          className={`${s.scrollWindow} ${replyMessage ? s.withReply : ''}`}>
          {messagesData.isFetching && <Loader />}
          {messagesData &&
            onlyMessages &&
            onlyMessages?.map(msg => (
              <div key={msg.id} id={`message_${msg.id}`}>
                <ActionableChatMessage
                  message={msg}
                  key={msg.id}
                  chatId={chatId}
                  isBlocked={profileData.blockedUsers?.some(user => user.uuid === msg.author.id)}
                  quickViewMessageId={quickViewMessageId}
                  setQuickViewMessageId={setQuickViewMessageId}
                  setReplyMessage={setReplyMessage}
                  likedByMe={messagesData.messagesByRoom.likedByMe}
                  setShouldScrollToBottom={setShouldScrollToBottom}
                  callParentFunction={updateMessageView}
                  deleteMessage={deleteMessage}
                />
              </div>
            ))}
          {fetchingNext && messagesData.isFetching && <Loader />}
        </div>
        <LexicalComposer initialConfig={initialConfig}>
          <ChatForm
            replyMessage={replyMessage}
            setReplyMessage={setReplyMessage}
            callback={content =>
              sendMessage(content, replyMessage?.id).then(() => {
                setShouldScrollAfterSend(true);
              })
            }
          />
        </LexicalComposer>
      </>
    )
  );
};

export default ChatWindow;
