export const SAVE_DISPATCH = 'WIS/save/SAVE_DISPATCHED';
export const SAVE_FULFILL = 'WIS/save/SAVE_FULFILLED';
export const SAVE_REJECT = 'WIS/save/SAVE_REJECTED';

export const UNSAVE_DISPATCH = 'WIS/save/UNSAVE_DISPATCHED';
export const UNSAVE_FULFILL = 'WIS/save/UNSAVE_FULFILLED';
export const UNSAVE_REJECT = 'WIS/save/UNSAVE_REJECTED';

export const save = (postId: string) => ({ type: SAVE_DISPATCH, postId });
export const saveFulfill = (post: string) => ({ type: SAVE_FULFILL, post });
export const saveReject = (error: string) => ({ type: SAVE_REJECT, error });

export const unSave = (postId: string) => ({ type: UNSAVE_DISPATCH, postId });
export const unSaveFulfill = (post: string) => ({ type: UNSAVE_FULFILL, post });
export const unSaveReject = (error: string) => ({ type: UNSAVE_REJECT, error });

export type SavedActionTypes = 
  'WIS/save/SAVE_DISPATCHED'
  | 'WIS/save/SAVE_FULFILLED'
  | 'WIS/save/SAVE_REJECTED'
  | 'WIS/save/UNSAVE_DISPATCHED'
  | 'WIS/save/UNSAVE_FULFILLED'
  | 'WIS/save/UNSAVE_REJECTED';

export interface SavedState {
  posts: string[];
  updating: boolean;
  error?: string;
}

const initialState: SavedState = {
  posts: ['P000002', 'P000004', 'P000006'],
  updating: false,
  error: null,
};

export type SavedAction = {
  type: SavedActionTypes; 
  post: string;
  postId: string;
} & Pick<SavedState, 'error'>;

export default function reducer(state = initialState, action: SavedAction) {
  switch (action.type) {
    case SAVE_DISPATCH:
      return {
        ...state,
        updating: true,
        error: null,
      };

    case SAVE_FULFILL:
      return {
        ...state,
        updating: false,
        posts: [...state.posts, action.post],
        error: null,
      };

    case SAVE_REJECT:
      return {
        ...state,
        updating: false,
        error: action.error,
      };
    case UNSAVE_DISPATCH:
      return {
        ...state,
        updating: true,
        error: null,
      };

    case UNSAVE_FULFILL:
      return {
        ...state,
        updating: false,
        posts: [...state.posts, action.post],
        error: null,
      };

    case UNSAVE_REJECT:
      return {
        ...state,
        updating: false,
        error: action.error,
      };

    default:
      return state;
  }
}
