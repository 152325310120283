import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import useModal from '../../hooks/useModal';
import styles from './Modal.module.scss';
import clsx from 'clsx';

const Modal = ({ show, backDropClick, children }) => {
  const setShow = useModal();
  const [extraClass, setExtraClass] = useState(false);
  useEffect(() => {
    setShow(show);
  }, [show]);
  const modalRoot = document.getElementById('modal-root');
  // const willLeave = () => ({ opacity: spring(0), y: spring(-200) });
  // const willEnter = () => ({ opacity: 0, y: -400 });
  useEffect(() => {
    setExtraClass(show);
  }, [show]);
  if (!show) {
    return null;
  }
  return ReactDOM.createPortal(
    <div className={clsx(styles.backdrop, extraClass && styles.open)} onClick={backDropClick}>
      <div className={styles.modal} onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>,
    modalRoot,
  );
};

Modal.propTypes = {
  show: PropTypes.bool,
  backDropClick: PropTypes.func,
};

Modal.defaultProps = {
  show: false,
  backDropClick: null,
};

export default Modal;
