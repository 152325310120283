import { LOGIN_FULFILL, SESSION_RESUME_FULFILL } from '../modules/auth';
import { delay, mergeMap } from 'rxjs/operators';
// import {
//   fetchNotificationHistory,
//   fetchNotificationPreferences,
//   fetchNotificationRead,
// } from '../modules/notifications';

import { APP_INIT } from '../modules/app';
import { FEATURE_FLAGS } from '../../util/config';
import { boardGetAllDispatch } from '../modules/board';
import { combineEpics, ofType } from 'redux-observable';
// import { dispatch as fetchProfile } from '../modules/profile';
import { dispatch as fetchTaxonomyCountry } from '../modules/taxonomyCountry';
import { dispatch as fetchTaxonomyFlagReasons } from '../modules/taxonomyFlagReasons';
import { dispatch as fetchTaxonomyGameInvolvement } from '../modules/taxonomyGameInvolvement';
import { dispatch as fetchTaxonomyGender } from '../modules/taxonomyGender';
import { dispatch as fetchTaxonomyMedicalStatus } from '../modules/taxonomyMedicalStatus';
import { dispatch as fetchTaxonomyMilitaryService } from '../modules/taxonomyMilitaryService';
import { dispatch as fetchTaxonomyMilitaryStatus } from '../modules/taxonomyMilitaryStatus';
import { dispatch as fetchTaxonomyOpportunities } from '../modules/taxonomyOpportunities';
import { dispatch as fetchTaxonomyPastGames } from '../modules/taxonomyPastGames';
import { dispatch as fetchTaxonomyPastTrials } from '../modules/taxonomyPastTrials';
// import { getChatRoomsDispatch } from '../modules/chat';

const appInitDataLoad = (action$, store) =>
  action$.pipe(
    ofType(APP_INIT),
    mergeMap(() => {
      const actions = [];
      const state = store.value;

      // if (!state.auth.token) {
      //   actions.push(fetchLocation());
      // }

      if (!state.taxonomyGender.isFetched) {
        actions.push(fetchTaxonomyGender());
      }

      if (!state.taxonomyCountry.isFetched) {
        actions.push(fetchTaxonomyCountry());
      }

      if (!state.taxonomyMilitaryStatus.isFetched) {
        actions.push(fetchTaxonomyMilitaryStatus());
      }

      if (!state.taxonomyMilitaryService.isFetched) {
        actions.push(fetchTaxonomyMilitaryService());
      }

      if (!state.taxonomyPastGames.isFetched) {
        actions.push(fetchTaxonomyPastGames());
      }

      if (!state.taxonomyPastTrials.isFetched) {
        actions.push(fetchTaxonomyPastTrials());
      }

      if (!state.taxonomyMedicalStatus.isFetched) {
        actions.push(fetchTaxonomyMedicalStatus());
      }

      if (!state.taxonomyOpportunities.isFetched) {
        actions.push(fetchTaxonomyOpportunities());
      }

      if (!state.taxonomyGameInvolvement.isFetched) {
        actions.push(fetchTaxonomyGameInvolvement());
      }

      if (!state.taxonomyFlagReasons.isFetched) {
        actions.push(fetchTaxonomyFlagReasons());
      }

      return actions;
    }),
  );

const postLoginDataLoad = (action$, store) =>
  action$.pipe(
    ofType(LOGIN_FULFILL, SESSION_RESUME_FULFILL),
    delay(50), // delay enough for token to be saved
    mergeMap(() => {
      const actions = [];
      const state = store.value;

      // if (!state.profile.isFetched) {
      //   actions.push(fetchProfile());
      // }

      // if (!state.notifications.preferences.isFetched) {
      //   actions.push(fetchNotificationPreferences());
      // }

      // if (
      //   FEATURE_FLAGS.notifications &&
      //   !state.notifications.history.isFetched
      // ) {
      //   actions.push(fetchNotificationRead());
      //   actions.push(fetchNotificationHistory());
      // }

      if (FEATURE_FLAGS.boards && !state.boards.isFetched) {
        actions.push(boardGetAllDispatch());
      }

      // if (FEATURE_FLAGS.chat && !state.chat.isFetched) {
      //   actions.push(getChatRoomsDispatch());
      // }

      return actions;
    }),
  );

export default combineEpics(appInitDataLoad, postLoginDataLoad);
