import { Profile } from './../../types/Profile';
export const FLAG_DISPATCH = 'WIS/flag/FLAG_DISPATCHED';
export const FLAG_FULFILL = 'WIS/flag/FLAG_FULFILLED';
export const FLAG_REJECT = 'WIS/flag/FLAG_REJECTED';

export type FlagActionTypes = 
  'WIS/flag/FLAG_DISPATCHED'
  | 'WIS/flag/FLAG_FULFILLED'
  | 'WIS/flag/FLAG_REJECTED';

export const flagDispatch = (id: string) => ({ type: FLAG_DISPATCH, id });
export const flagFulfill = () => ({ type: FLAG_FULFILL });
export const flagReject = (error: string) => ({ type: FLAG_REJECT, error });

export interface FlagState {
  flagging: boolean,
  users?: Partial<Profile>[],
  flagged: boolean,
  error?: string,
}

const initialState: FlagState = {
  flagging: false,
  flagged: false,
  error: null,
};

export type FlagAction = { readonly type: FlagActionTypes; } & Partial<Pick<FlagState, 'users' | 'error'>>;

export default function reducer(state = initialState, action: FlagAction) {
  switch (action.type) {
    case FLAG_DISPATCH:
      return {
        ...state,
        flagging: true,
        flagged: false,
        error: null,
      };

    case FLAG_FULFILL:
      return {
        ...state,
        flagging: false,
        flagged: true,
        users: action.users,
        error: null,
      };

    case FLAG_REJECT:
      return {
        ...state,
        flagging: false,
        flagged: false,
        error: action.error,
      };

    default:
      return state;
  }
}
