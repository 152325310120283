import { FC, KeyboardEvent, HTMLAttributes } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './UIListItem.module.scss';
import { connect } from 'react-redux';
import clsx from 'clsx';

export interface UIListItemProps extends HTMLAttributes<HTMLDivElement>, RouteComponentProps {
  icon?: JSX.Element;
  title?: string;
  to?: string;
  link?: string;
  disabled?: boolean;
  className?: string;
  onKeyUp?: (e: KeyboardEvent) => void;
  activeButton?: boolean;
  contentClass?: string;
}

const UIListItem: FC<UIListItemProps> = ({
  icon = null,
  title = null,
  to = null,
  link = null,
  disabled = false,
  onClick,
  onKeyUp,
  history,
  children,
  className,
  activeButton,
  contentClass,
}) => {
  const handleNavigate = () => {
    if (to) {
      history.push(to);
      // window.open(to, "_self");
    } else if (link) {
      window.open(link, '_blank');
    }
  };

  const handleClick = (e: any) => {
    if (to || link) {
      handleNavigate();
    } else if (onClick) {
      onClick(e);
    }
  };

  const handleKeyUp = (e: KeyboardEvent<Element>) => {
    onkeyup ? onKeyUp(e) : null;
    e.keyCode === 13 ? handleNavigate() : null;
  };

  const listStyles = [
    styles.listItem,
    icon && styles.withIcon,
    to && styles.withLink,

    className,
  ].join(' ');

  const mainStyles = [styles.main, title && styles.withTitle].join(' ');

  return (
    <div className={clsx(styles.wrapper, className, activeButton && styles.activeButton)}>
      <div
        className={listStyles}
        onClick={e => (!disabled ? handleClick(e) : null)}
        onKeyUp={e => (!disabled ? handleKeyUp(e) : null)}
        role="button"
        tabIndex={0}>
        {icon && icon}
        <div className={mainStyles}>
          {title && <div className={styles.title}>{title}</div>}
          <div className={clsx(!contentClass && styles.content, contentClass)}>{children}</div>
        </div>
        {(link || to) && (
          <div className={styles.ctaIcon}>
            <FontAwesomeIcon icon={['fas', 'chevron-right']} />
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(connect(null, {})(UIListItem));
