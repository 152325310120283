import TaxonomyTerm from "../../types/TaxonomyTerm";
import { TaxonomyAction, TaxonomyState } from "./TaxonomyState";

export const DISPATCH = 'WIS/taxonomy/flag_reasons/DISPATCHED';
export const FULFILL = 'WIS/taxonomy/flag_reasons/FULFILLED';
export const REJECT = 'WIS/taxonomy/flag_reasons/REJECTED';

export type FlagReasonsActionTypes = 
  'WIS/taxonomy/flag_reasons/DISPATCHED'
  | 'WIS/taxonomy/flag_reasons/FULFILLED'
  | 'WIS/taxonomy/flag_reasons/REJECTED';

export const dispatch = () => ({ type: DISPATCH });
export const fulfill = (payload: TaxonomyTerm[]) => ({ type: FULFILL, payload });
export const reject = (error: string) => ({ type: REJECT, error });


const initialState: TaxonomyState = {
  terms: [],
  isFetching: false,
  isFetched: false,
  error: null,
};

export type TaxonomyFlagReasonsAction = TaxonomyAction<FlagReasonsActionTypes>;

export default function reducer(state = initialState, action: TaxonomyFlagReasonsAction) {
  switch (action.type) {
    case DISPATCH:
      return {
        ...state,
        isFetching: true,
      };

    case FULFILL:
      return {
        ...state,
        terms: action.payload,
        isFetching: false,
        isFetched: true,
        error: null,
      };

    case REJECT:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };

    default:
      return state;
  }
}
