import { ChangeEvent, FC, FocusEvent, Ref, useEffect, useRef, useState } from 'react';

import FieldLabel from '../FieldLabel/FieldLabel';
import FieldStatusBlock from '../FieldStatusBlock/FieldStatusBlock';
import styles from './TextField.module.scss';
import { useTranslation } from 'react-i18next';

export interface TextFieldProps {
  value: string;
  name: string;
  id?: string;
  inputClass?: string;
  inputRef?: Ref<HTMLInputElement>;
  required?: boolean;
  label: string;
  multiLine?: boolean;
  errorText?: string;
  touched?: boolean;
  disabled?: boolean;
  maxLength?: number;
  min?: number;
  max?: number;
  type?: string;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e: FocusEvent) => void;
  onFocus?: (e: FocusEvent) => void;
  placeholder?: string;
}

const TextField: FC<TextFieldProps> = ({
  value,
  id,
  name,
  type = 'text',
  multiLine = false,
  inputRef,
  maxLength = 524288,
  onChange,
  onFocus,
  onBlur,
  required = false,
  label,
  min,
  max,
  errorText,
  inputClass,
  disabled = false,
  touched = false,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);
  const [textAreaHeight, setTextAreaHeight] = useState('auto');
  const textAreaEl = useRef(null);
  const { t } = useTranslation();
  const updateHeight = () => {
    if (multiLine) {
      window.setTimeout(() => {
        setTextAreaHeight('auto');
        setTextAreaHeight(`${textAreaEl.current?.scrollHeight || 0}px`);
      }, 0);
    }
  };

  useEffect(() => {
    updateHeight();
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(e);
    updateHeight();
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onBlur) {
      onBlur(e);
    }
    setFocused(false);
  };

  const fieldType = () => {
    if (multiLine) {
      return (
        <>
          <textarea
            maxLength={35000}
            rows={1}
            id={id}
            value={value}
            name={name}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={textAreaEl}
            style={{ height: textAreaHeight }}
            className={inputClass}
            disabled={disabled}
            {...rest}
          />
          <div className={styles.charCount}>
            {value.length} / 35000 {t('characters.label')}
          </div>
        </>
      );
    }
    return (
      <input
        id={id}
        value={value}
        name={name}
        type={type && type}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={inputRef}
        maxLength={maxLength}
        className={inputClass}
        disabled={disabled}
        min={min}
        max={max}
        {...rest}
      />
    );
  };

  return (
    <div className={styles.wrapper}>
      {label && <FieldLabel label={label} required={required} />}
      <FieldStatusBlock error={errorText} focused={focused} touched={touched}>
        {fieldType()}
      </FieldStatusBlock>
    </div>
  );
};

export default TextField;
