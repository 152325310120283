import { ImageType } from './Image';

export interface ChatMessage {
  id: number;
  translatedContent?: string;
  author: {
    id: number;
    username: string;
    avatar: {
      id: number;
      src: string;
    };
  };
  content: string;
  created: Date;
  lastModified: Date;
  isHidden: boolean;
  likesCount?: number;
  repliedToMessage?: ChatMessage
  mentions?: {
    username: string;
    id: string;
    avatar: ImageType;
  }[];
  mentionStartingIdentifier?: string;
  mentionEndingIdentifier?: string;
  language?: string;
}

export interface ChatMessagePayload {
  id: number;
  translatedContent?: string;
  author: {
    id: number;
    username: string;
    avatar: {
      id: number;
      src: string;
    };
  };
  content: string;
  created: Date;
  lastModified: Date;
  isHidden: boolean;
  likes_count?: number;
  mentions?: {
    username: string;
    id: string;
    avatar: ImageType;
  }[];
  mentionStartingIdentifier?: string;
  mentionEndingIdentifier?: string;
  language?: string;
  repliedToMessage?: number
}

interface ListResponse<T> {
  next: string | null;
  previous: string | null;
  results: T[];
  likedByMe?: number[];
}

export type ChatMessagesListResp = ListResponse<ChatMessage>;

export enum ChatWsEvents {
  AUTHENTICATION = 'authentication',
  AUTHENTICATED = 'authenticated',
  ERROR = 'error',
  MESSAGE_RECEIVED = 'message.saved',
  MESSAGE_DELETED = 'message.deleted',
  MESSAGE_LIKES_COUNT = 'message.likes_count.saved',
}

export interface ChatSocketEvent {
  type: ChatWsEvents;
  error?: {
    detail: {
      message: string;
      code: string;
    };
  };
  message?: Pick<ChatMessagePayload, 'id' | 'author' | 'content' | 'created' | 'language' | 'likes_count' | 'mentions' | 'repliedToMessage' | 'translatedContent'> & {
    is_hidden: ChatMessage['isHidden'];
    last_modified: ChatMessage['lastModified'];
    replied_to_message?: ChatMessage['repliedToMessage'];
    translated_content?: ChatMessage['translatedContent'];
  };
  messageId?: ChatMessage['id'];
  message_id?: ChatMessage['id'];
  likes_count?: ChatMessagePayload['likes_count']
}
