import React from 'react';

export const Posts = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.875 9C14.5917 9 14.3542 8.90417 14.1625 8.7125C13.9708 8.52083 13.875 8.28333 13.875 8V4C13.875 3.71667 13.9708 3.47917 14.1625 3.2875C14.3542 3.09583 14.5917 3 14.875 3H20.875C21.1583 3 21.3958 3.09583 21.5875 3.2875C21.7792 3.47917 21.875 3.71667 21.875 4V8C21.875 8.28333 21.7792 8.52083 21.5875 8.7125C21.3958 8.90417 21.1583 9 20.875 9H14.875ZM4.875 13C4.59167 13 4.35417 12.9042 4.1625 12.7125C3.97083 12.5208 3.875 12.2833 3.875 12V4C3.875 3.71667 3.97083 3.47917 4.1625 3.2875C4.35417 3.09583 4.59167 3 4.875 3H10.875C11.1583 3 11.3958 3.09583 11.5875 3.2875C11.7792 3.47917 11.875 3.71667 11.875 4V12C11.875 12.2833 11.7792 12.5208 11.5875 12.7125C11.3958 12.9042 11.1583 13 10.875 13H4.875ZM14.875 21C14.5917 21 14.3542 20.9042 14.1625 20.7125C13.9708 20.5208 13.875 20.2833 13.875 20V12C13.875 11.7167 13.9708 11.4792 14.1625 11.2875C14.3542 11.0958 14.5917 11 14.875 11H20.875C21.1583 11 21.3958 11.0958 21.5875 11.2875C21.7792 11.4792 21.875 11.7167 21.875 12V20C21.875 20.2833 21.7792 20.5208 21.5875 20.7125C21.3958 20.9042 21.1583 21 20.875 21H14.875ZM4.875 21C4.59167 21 4.35417 20.9042 4.1625 20.7125C3.97083 20.5208 3.875 20.2833 3.875 20V16C3.875 15.7167 3.97083 15.4792 4.1625 15.2875C4.35417 15.0958 4.59167 15 4.875 15H10.875C11.1583 15 11.3958 15.0958 11.5875 15.2875C11.7792 15.4792 11.875 15.7167 11.875 16V20C11.875 20.2833 11.7792 20.5208 11.5875 20.7125C11.3958 20.9042 11.1583 21 10.875 21H4.875Z"
      fill="currentColor"
    />
  </svg>
);
