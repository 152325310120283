import React, { PropsWithChildren } from 'react';
import styles from './ButtonGroup.module.scss';
import clsx from 'clsx';

interface ButtonGroupProps {
  className?: string;
  borderTop?: boolean;
}

const ButtonGroup = ({ children, className, borderTop }: PropsWithChildren<ButtonGroupProps>) => (
  <div className={clsx(styles.wrapper, className, borderTop && styles.borderTop)}>
    <div className={styles.buttonGroup}>{children}</div>
  </div>
);

export default ButtonGroup;
