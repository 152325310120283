import { FC, HTMLProps } from 'react'
import { useTranslation } from 'react-i18next';
import useUserBlock from '../../hooks/useUserBlock';
import { MentionedUser } from '../../types/Comment';

export interface CommentMentionProps extends HTMLProps<HTMLSpanElement> {
  user?: MentionedUser;
}

const CommentMention: FC<CommentMentionProps> = ({ user, ...props }) => {
  const { isBlocked } = useUserBlock(user?.id);
  const { t } = useTranslation();
  return (
    <>
      <span {...props} >{isBlocked || !user?.username ? t('blockedUser.label') : `@${user.username}`}</span>
    </>
  )
}

export default CommentMention;