import { useEffect, useState } from 'react';

import ButtonIcon from '../ButtonIcon/ButtonIcon';
import ChatWindow from '../ChatWindow/ChatWindow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullModal from '../FullModal/FullModal';
import Loader from '../Loader/Loader';
import { Preferences } from '@capacitor/preferences';
import { RootState } from '../../redux/modules/root';
import s from './ChatModal.module.scss';
import { useHistory } from 'react-router';
import { useChatRooms, useMessages } from '../../util/APIDjango';
import { ChatRoom } from '../../types/ChatRoom';
import BackIcon from '../../assets/images/back.svg';

/**
 * Props Type Interface for {@link ChatModal}
 */
export interface ChatModalProps {
  chatId: number;
}

// const mapStateToProps = ({ chat }: RootState, chatId: number) => ({
//   room: chat.rooms.find(({ id }) => id === chatId),
//   loading: chat.isFetching,
// });

/**
 * Displays a Chat room in a modal (like Posts)
 * @param {ChatModalProps} props
 * @returns {JSX.Element}
 */
const ChatModal = ({ chatId }: ChatModalProps): JSX.Element => {
  const [autoTranslateStatus, setAutoTranslateStatus] = useState(true);
  const [room, setRoom] = useState<ChatRoom>();

  const [messagesData, setMessagesData] = useMessages();

  useEffect(() => {
    void Preferences.get({ key: 'autoTranslateStatus' }).then(result => {
      if (!result.value) {
        setAutoTranslateStatus(false);
      }
    });
    const tempRoom = rooms.find(({ id }) => id === chatId);
    setRoom(tempRoom);
  }, []);

  // const { room, loading } = useSelector(state =>
  //   mapStateToProps(state as RootState, chatId),
  // );
  const [chatRoomsData, setChatRoomsData] = useChatRooms();
  const { isFetching, rooms } = chatRoomsData;
  const history = useHistory();

  const resetChatMessages = () => {
    setMessagesData({
      fetchError: null,
      isFetched: false,
      isFetching: false,
      lastMessageIdFetched: null,
      messagesByRoom: {
        likedByMe: [],
        next: null,
        previous: null,
        results: [],
      },
      messagesFetched: false,
      messagesFetching: false,
    });
  };

  const handleClose = () => {
    resetChatMessages();
    history.push('/chat');
  };

  if (isFetching) {
    return <Loader />;
  }

  if (!room) {
    return <>Error loading chat room data</>;
  }

  if (chatId !== room.id) {
    return null;
  }

  return (
    <FullModal
      className={s.wrapper}
      show={chatId === room.id}
      backDropClick={handleClose}
      isChatPage>
      <div className={s.header}>
        <ButtonIcon
          to={'/chat'}
          icon={<img src={BackIcon} />}
          fullWidth
          label={autoTranslateStatus && room?.translatedName ? room?.translatedName : room.name}
          className={s.backButton}
        />
      </div>
      <ChatWindow chatId={chatId} />
    </FullModal>
  );
};

export default ChatModal;
