import React, { useEffect, useState } from 'react';
import {
  updateLanguagesDispatch,
  updateLanguagesFulfill,
  updateLanguagesReject,
} from '../redux/modules/autoTranslate';

import DetailTitle from '../components/DetailTitle/DetailTitle';
import Layout from '../components/Layout/Layout';
import { Preferences } from '@capacitor/preferences';
import Toggle from './../components/Toggle/Toggle';
import UIListItem from '../components/UIListItem/UIListItem';
import api from './../util/api';
import s from './AutoTranslate.module.scss';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useProfileData } from '../hooks/useProfileData';

/**
 * The component is a disclaimer popup that users see upon their first access to the site from a new device.
 * It highlights the basic terms of use for users.
 * The AutoTranslater Disclaimer label is the latest disclaimer set by the client.
 * @returns {JSX.Element}
 */
const AutoTranslatePage = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [isChanging, setIsChanging] = useState(false);
  const { profileData, setProfileData } = useProfileData();
  const [autoTranslateStatus, setAutoTranslateStatus] = useState(
    profileData.autoTranslatePreference,
  );
  const [language] = useState(i18n.language);

  useEffect(() => {
    void Preferences.get({ key: 'autoTranslateStatus' }).then(result => {
      if (!result.value) {
        setAutoTranslateStatus(false);
      }
    });
  }, []);

  const toggleAutoTranslate = () => {
    const autoTranslatePreference = autoTranslateStatus ? 'false' : 'true';
    setAutoTranslateStatus(!autoTranslateStatus);
    void Preferences.set({
      key: 'autoTranslateStatus',
      value: autoTranslatePreference,
    })
      .then(() => {
        const values = {
          language: language,
          autoTranslatePreference: !autoTranslateStatus,
        };
        dispatch(updateLanguagesDispatch(values));
        setIsChanging(true);
        api
          .autoTranslate(values)
          .then(() => {
            setProfileData({
              ...profileData,
              autoTranslatePreference: values.autoTranslatePreference,
            });
            dispatch(updateLanguagesFulfill(values));
          })
          .catch(error => {
            setAutoTranslateStatus(autoTranslateStatus);
            dispatch(updateLanguagesReject(error.message));
          });

        setIsChanging(false);
      })
      .catch(() => {
        setAutoTranslateStatus(autoTranslateStatus);
      });
  };

  return (
    <>
      <Layout>
        <DetailTitle
          title={t('autoTranslate.label')}
          backLabel={t('settings.label')}
          backTo="/settings"
        />
        <UIListItem contentClass={s.translateContent} className={s.translateWrapper}>
          <div className={[s.main].join(' ')}>
            <div className={s.content}>
              <p style={{ paddingRight: '20px' }}>{t('autoTranslationAbout.label')}</p>
            </div>
            <Toggle
              id="auto-translate-toggle"
              checked={autoTranslateStatus}
              pending={isChanging}
              onChange={toggleAutoTranslate}
              className={undefined}
              onClick={undefined}
            />
          </div>
        </UIListItem>
      </Layout>
    </>
  );
};

export default AutoTranslatePage;
