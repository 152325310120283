export const DISPATCH = 'WIS/sign_up/DISPATCHED';
export const FULFILL = 'WIS/sign_up/FULFILLED';
export const REJECT = 'WIS/sign_up/REJECTED';

export type SignUpActionTypes =
  'WIS/sign_up/DISPATCHED'
  | 'WIS/sign_up/FULFILLED'
  | 'WIS/sign_up/REJECTED';

export const dispatch = () => ({ type: DISPATCH });
export const fulfill = () => ({ type: FULFILL });
export const reject = (error: string) => ({ type: REJECT, error });

export interface SignUpState {
  submitting: boolean,
  submitted: boolean,
  error?: string,
}

const initialState: SignUpState = {
  submitting: false,
  submitted: false,
  error: null,
};

export type SignUpAction = { readonly type: SignUpActionTypes } & Pick<SignUpState, 'error'>;

export default function reducer(state = initialState, action: SignUpAction) {
  switch (action.type) {
    case DISPATCH:
      return {
        ...state,
        submitting: true,
      };

    case FULFILL:
      return {
        ...state,
        submitting: false,
        submitted: true,
        error: null,
      };

    case REJECT:
      return {
        ...state,
        error: action.error,
        submitting: false,
      };

    default:
      return state;
  }
}
