import React from 'react';
import { ChatMessage as ChatMessageType } from '../../types/ChatMessage';
import Avatar from '../Avatar/Avatar';
import { LOCALE } from '../../util/config';
import s from './ChatMessage.module.scss';
import defaultAvatar from '../../assets/images/default-avatar.svg';
// import { RootState } from '../../redux/modules/root';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { AuthContext } from '../../context/AuthProvider';
import { LikedIcon } from '../PostDetail/PostDetail';
import { Profile } from '../../types/Profile';
import { Preferences } from '@capacitor/preferences';
// import { useMessageLiked } from '../../hooks/messageHooks';
import { decodeEntity } from 'html-entities';
import CommentMention from '../CommentMention/CommentMention';
import { useAuth, useMessages, useProfile } from '../../util/APIDjango';
import api from '../../util/api';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import { useProfileData } from '../../hooks/useProfileData';
import dayjs from 'dayjs';

/**
 * Props Type Interface for {@link ChatMessage}
 */
export interface ChatMessageProps {
  message: ChatMessageType;
  isBlocked?: boolean;
  isHidden?: boolean;
  displayQuickView: boolean;
  translatedMessage?: string;
  translationError?: string;
  onClick?: () => void;
  originalContent: string;
  chatId?: number;
  likedByMe?: number[];
  setShouldScrollToBottom?: Dispatch<SetStateAction<boolean | null>>;
}

const decodeCommentText = (chat: string) => {
  const matches = [];
  let match: RegExpMatchArray = [''];

  let restOfText = chat;

  if (restOfText !== null) {
    while (match !== null) {
      restOfText = restOfText.substring(match.index + match[0].length);
      match = restOfText.match('&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-fA-F]{1,6});');
      if (match !== null) {
        matches.push({
          entity: match[0],
          index: chat.length - restOfText.length + match.index,
        });
      }
    }
  }

  let returnString = chat;
  matches.forEach(elem => {
    returnString = returnString.replace(elem.entity, decodeEntity(elem.entity));
  });

  return returnString;
};

// const mapStateToProps = ({ blocked }: RootState) => ({
//   blockedUsers: blocked.users,
// });

/**
 * Chat Message component, renders a message in the chat
 * Displays an author's image, username, the message content and when it was sent
 * Displays a like button with a message like counter
 * @param {ChatMessageProps} props
 * @returns {JSX.Element}
 */
const ChatMessage = ({
  message,
  isBlocked = false,
  isHidden = false,
  displayQuickView,
  translatedMessage,
  translationError,
  onClick,
  originalContent,
  chatId,
  setShouldScrollToBottom,
}: ChatMessageProps): JSX.Element => {
  const [autoTranslateStatus, setAutoTranslateStatus] = useState(true);

  const [messagesData, setMessagesData] = useMessages();
  // let { messagesByRoom } = messagesData;
  // let { results } = messagesByRoom;

  useEffect(() => {
    void Preferences.get({ key: 'autoTranslateStatus' }).then(result => {
      if (!result.value) {
        setAutoTranslateStatus(false);
      }
    });
  }, []);

  // const { likeMessage, removeLikeMessage, liked, setLiked } = useMessageLiked(chatId, message.id);
  // const [liked, setLiked] = useState(false);
  const [isLiking, setIsLiking] = useState(false);
  const [error, setError] = useState<string>();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const removeLikeCount = () => {
    const index = messagesData.messagesByRoom.likedByMe.indexOf(message.id);

    if (index !== -1) {
      messagesData.messagesByRoom.likedByMe.splice(index, 1);
    }
    messagesData.messagesByRoom = {
      ...messagesData.messagesByRoom,
      results: messagesData.messagesByRoom.results.map(msg => {
        if (msg.id === message.id) {
          return {
            ...msg,
            likesCount: msg.likesCount - 1,
          };
        }
        return msg;
      }),
    };
    setMessagesData({
      ...messagesData,
      isFetched: true,
      isFetching: false,
      messagesByRoom: messagesData.messagesByRoom,
    });
    setShouldScrollToBottom(false);
  };

  const removeLikeMessage = (): Promise<void> => {
    setIsLiking(true);

    return api
      .removeLikeChatMessage(chatId, message.id)
      .then(() => {
        removeLikeCount();
        // if (isMounted.current)
        // setLiked(false);
      })
      .catch((e: Error) => {
        console.error(e.message);
        if (isMounted.current) setError(e.message);
      })
      .finally(() => {
        if (isMounted.current) setIsLiking(false);
      });
  };

  const addLikeCount = () => {
    messagesData.messagesByRoom.likedByMe.push(message.id);
    messagesData.messagesByRoom = {
      ...messagesData.messagesByRoom,
      results: messagesData.messagesByRoom.results.map(msg => {
        if (msg.id === message.id) {
          return {
            ...msg,
            likesCount: msg.likesCount + 1,
          };
        }
        return msg;
      }),
    };
    setMessagesData({
      ...messagesData,
      isFetched: true,
      isFetching: false,
      messagesByRoom: messagesData.messagesByRoom,
    });
    setShouldScrollToBottom(false);
  };

  const likeMessage = (): Promise<void> => {
    setIsLiking(true);

    return api
      .likeChatMessage(chatId, message.id)
      .then(() => {
        addLikeCount();
        // if (isMounted.current) setLiked(true);
      })
      .catch((e: Error) => {
        console.error(e.message);
        if (isMounted.current) setError(e.message);
      })
      .finally(() => {
        if (isMounted.current) setIsLiking(false);
      });
  };
  const { profileData } = useProfileData();
  const { t } = useTranslation();
  // const { uid, user }: { uid: number; user: Profile } = useContext(AuthContext);
  const [authState, setAuthState] = useAuth();
  const { uid }: { uid: number; user: Profile } = authState;
  const { profile: user }: { profile: Profile } = profileData;

  const history = useHistory();

  const username = isBlocked ? 'Blocked' : message?.author?.username;
  const avatarSrc = isBlocked ? defaultAvatar : defaultAvatar; // message?.author?.avatar?.src;
  const created = isBlocked ? undefined : new Date(message.created);
  // const [likeButtonLabel, setLabel] = useState<string>();
  // const [likeButtonLabel, setLabel] = useState<string>(String(message?.likesCount));
  // const [isLiked, setIsLiked] = useState<boolean>(false);
  // const [likedByMeState, setLikedByMeState] = useState(false);
  const isModerator = user?.permissions?.includes('chat.hide_message');

  const mentions: ChatMessage['mentions'] =
    isBlocked || (message.mentions && message.mentions?.length === 0)
      ? undefined
      : message.mentions;
  const replyMentions: ChatMessage['mentions'] =
    isBlocked ||
    (message.repliedToMessage?.mentions && message.repliedToMessage.mentions.length === 0)
      ? undefined
      : message?.repliedToMessage?.mentions;

  const toggleLiked = () => {
    history.push(`/chat/${chatId}`);
    setShouldScrollToBottom(false);

    // if (messagesData.messagesByRoom.likedByMe.includes(message.id)) {
    //   setLabel(String(Number(likeButtonLabel) - 1))
    // } else {
    //   setLabel(String(Number(likeButtonLabel) + 1))
    // }

    messagesData.messagesByRoom.likedByMe.includes(message.id)
      ? removeLikeMessage()
      : likeMessage();
    // setLiked(prev => !prev);
  };

  const contentMessage = isBlocked
    ? isModerator
      ? t('blockedUser.label')
      : t('blockedMessage.label')
    : isHidden
      ? displayQuickView
        ? originalContent
        : t('moderatorHiddenMessage.label')
      : uid !== message?.author.id && autoTranslateStatus && message?.translatedContent
        ? message?.translatedContent
        : message?.content;

  const contentReplyMessage = isBlocked
    ? isModerator
      ? t('blockedUser.label')
      : t('blockedMessage.label')
    : message?.repliedToMessage?.isHidden
      ? displayQuickView
        ? originalContent
        : t('moderatorHiddenMessage.label')
      : uid !== message?.author.id &&
          autoTranslateStatus &&
          message?.repliedToMessage?.translatedContent
        ? message?.repliedToMessage?.translatedContent
        : message?.repliedToMessage?.content;

  const buildComment = (chatText: string) => {
    if (!mentions) return [<>{chatText}</>];
    // const { mentionEndingIdentifier, mentionStartingIdentifier } = message;
    const mentionEndingIdentifier = '}}';
    const mentionStartingIdentifier = '{{';

    const tmp = chatText.split(mentionStartingIdentifier);
    const messageParts = tmp.map(part => {
      if (part.includes(mentionEndingIdentifier)) {
        const splitLine = part.split(mentionEndingIdentifier);
        const mentionedUser = mentions.find(user => user.id == splitLine[0]);

        return (
          <>
            <CommentMention className={s.mention} user={mentionedUser} />
            {splitLine[1]}
          </>
        );
      }
      return <>{part}</>;
    });
    return messageParts;
  };

  const buildReplyComment = (chatText: string) => {
    if (!replyMentions) return [<>{chatText}</>];
    // const { mentionEndingIdentifier, mentionStartingIdentifier } = message;
    const mentionEndingIdentifier = '}}';
    const mentionStartingIdentifier = '{{';

    const tmp = chatText.split(mentionStartingIdentifier);
    const messageParts = tmp.map(part => {
      if (part.includes(mentionEndingIdentifier)) {
        const splitLine = part.split(mentionEndingIdentifier);
        const mentionedUser = replyMentions.find(user => user.id == splitLine[0]);

        return (
          <React.Fragment key={mentionedUser.id}>
            <CommentMention className={s.mention} user={mentionedUser} />
            {splitLine[1]}
          </React.Fragment>
        );
      }
      return <>{part}</>;
    });
    return messageParts;
  };

  return (
    <div
      id={message.id.toFixed(0)}
      className={[
        s.wrapper,
        uid == message.author.id ? s.fromUser : undefined,
        isBlocked ? s.blocked : undefined,
        isHidden ? s.hidden : undefined,
      ].join(' ')}>
      <Avatar
        className={s.avatar}
        src={message?.author?.avatar?.src || avatarSrc}
        alt={`${username} Avatar`}
      />
      <div className={`${s.body} ${displayQuickView ? s.quickView : ''}`} onClick={onClick}>
        {/* REPLY SECTION */}
        {message.repliedToMessage && (
          <div
            className={[s.replyContainer, uid == message.author.id ? s.fromReply : s.toReply].join(
              ' ',
            )}>
            {(!message.repliedToMessage.isHidden || displayQuickView) && (
              <div className={s.author}>
                {t('replyingTo.label')} {message.repliedToMessage.author.username}
              </div>
            )}

            <p className={s.content}>{buildReplyComment(decodeCommentText(contentReplyMessage))}</p>
          </div>
        )}

        {(!isHidden || displayQuickView) && <div className={s.author}>{username}</div>}

        <p className={s.content}>{buildComment(decodeCommentText(contentMessage))}</p>

        {(!isHidden || displayQuickView) && !isBlocked && (
          <div className={s.messageFooter}>
            <div>
              <ButtonIcon
                disabled={isLiking}
                title={
                  messagesData.messagesByRoom.likedByMe.includes(message.id) ? 'unlike' : 'like'
                }
                onClick={() => toggleLiked()}
                label={
                  isBlocked
                    ? ''
                    : String(
                        messagesData.messagesByRoom.results?.find(value => value.id === message.id)
                          ?.likesCount ?? '',
                      )
                }
                icon={
                  isBlocked ? (
                    <></>
                  ) : messagesData.messagesByRoom.likedByMe.includes(message.id) ? (
                    <LikedIcon />
                  ) : (
                    <FontAwesomeIcon icon={['far', 'heart']} />
                  )
                }
                className={clsx(
                  s.likeButton,
                  messagesData.messagesByRoom.likedByMe.includes(message.id) && s.liked,
                )}
              />
            </div>
            <div className={s.metaWrapper}>
              <p className={s.time}>{dayjs(created).format('L, LT')}</p>
              {translationError && <div className={s.translationError}>{translationError}</div>}
              {!translationError && translatedMessage && (
                <div className={s.translatedMessage}>{translatedMessage}</div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatMessage;
