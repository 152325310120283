import * as yup from 'yup';

import { Field, Formik } from 'formik';
import React, { Fragment } from 'react';
import { renderTextField } from '../../util/formik-custom-fields';

import FieldLabel from '../../components/FieldLabel/FieldLabel';
import Loader from '../../components/Loader/Loader';
import PropTypes from 'prop-types';
import UIBlockListItem from '../../components/UIBlockListItem/UIBlockListItem';
import api from '../../util/api';
import { authorType } from '../../proptypes/author';
import styles from './FlagForm.module.scss';
import { useTranslation } from 'react-i18next';
import { useFlags, useTaxonomyFlagReasons } from '../../util/APIDjango';
import { FormikSelect } from '../../components/SelectField/SelectField';
import Button from '../../components/Button/Button';

// const mapStateToProps = ({ taxonomyFlagReasons }) => ({
//   flagReasonTerms: taxonomyFlagReasons.terms,
// });

const FlagForm = ({ id, type, author, handleCancel }) => {
  const { t } = useTranslation();
  // const { flagReasonTerms } = useSelector(mapStateToProps);

  const [flagReasons] = useTaxonomyFlagReasons();
  const [flags, setFlags] = useFlags();

  const flagReasonItems = flagReasons.terms.map(term => ({
    value: term.id,
    label: term.i18nKey ? t(`${term.i18nKey}.label`, { defaultValue: term.name }) : term.name,
  }));

  const flagSubmitDispatch = () => {
    setFlags({
      ...flags,
      flagging: true,
      flagged: false,
      error: null,
    });
  };

  return (
    <Formik
      initialValues={{
        id,
        type,
        author: author?.id,
        reason: undefined,
        reasonDescription: '',
      }}
      validationSchema={yup.object().shape({
        reason: yup
          .object()
          .shape({
            label: yup.string().required(t('required.label')),
            value: yup.string().required(t('required.label')),
          })
          .required(t('required.label')),
        reasonDescription: yup.string(),
      })}
      initialStatus={{
        formError: null,
        flagSubmited: false,
      }}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        setSubmitting(true);
        // dispatch(flagDispatch(values?.id));
        flagSubmitDispatch();
        setStatus({ formError: null, flagSubmited: false });

        api
          .flagContent({ ...values, reason: values.reason?.value })
          .then(() => {
            setSubmitting(false);
            setStatus({ formError: null, flagSubmited: true });
          })
          .catch(err =>
            setStatus({
              formError: t(`${err?.message}.label`, { defaultValue: err?.message || err }),
              flagSubmited: false,
            }),
          );
      }}>
      {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, status, errors }) => (
        <form className={styles.form} onSubmit={handleSubmit}>
          {isSubmitting ? (
            <Loader label={t('submittingFlagRequest.label')} />
          ) : (
            <div className={styles.container}>
              {status && status.formError ? (
                <Fragment>
                  {status.formError}
                  <br />
                </Fragment>
              ) : null}
              {status && status.flagSubmited ? (
                <div className={styles.flagFulfill}>{t('thankYouYourFlagWasSubmitted.label')}</div>
              ) : (
                <Fragment>
                  <FieldLabel label={t('pleaseChooseAReasonForFlagging.label')} />
                  <Field
                    component={FormikSelect}
                    name="reason"
                    disabled={isSubmitting}
                    options={flagReasonItems}
                    error={errors.reason?.label}
                  />
                  <div className={styles.separator} />
                  <Field
                    component={renderTextField}
                    type="text"
                    multiLine
                    name="reasonDescription"
                    label={t('additionalDetail.label')}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.reasonDescription}
                  />
                </Fragment>
              )}
              <div className={styles.separator} />
              <FieldLabel label={t('blockAllContentFromAuthor.label')} />
            </div>
          )}
          {!isSubmitting && (
            <UIBlockListItem className={styles.blockListItem} id={author?.username} author={author}>
              {author?.username}
            </UIBlockListItem>
          )}
          {status && status.flagSubmited ? (
            <></>
          ) : (
            <div className={styles.buttons}>
              <Button type="submit" disabled={isSubmitting} label={t('flagContent.label')} />
              <Button secondary type="button" onClick={handleCancel} label={t('cancel.label')} />
            </div>
          )}
        </form>
      )}
    </Formik>
  );
};

FlagForm.propTypes = {
  author: PropTypes.shape(authorType).isRequired,
  handleCancel: PropTypes.func,
};

FlagForm.defaultProps = {
  handleCancel: null,
};

export default FlagForm;
