// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-PageTitle-module-title-WsQjH{font-family:"nexa";font-size:32px;font-style:normal;font-weight:700;line-height:48px;color:#eee;padding:0 16px;margin:0}`, "",{"version":3,"sources":["webpack://./src/components/PageTitle/PageTitle.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAEA,+BACE,kBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,UC2Ca,CD1Cb,cAAA,CACA,QAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `-PageTitle-module-title-WsQjH`
};
export default ___CSS_LOADER_EXPORT___;
