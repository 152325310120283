import { useState, useEffect, useRef } from 'react';
import api from '../util/api';

export const useMessageRemove = (chatId: number, messageId: number) => {
  const [removed, setRemoved] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [error, setError] = useState<string>();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const removeMessage = (): Promise<void> => {
    setIsRemoving(true);

    return api
      .deleteChatMessage(chatId, messageId)
      .then(() => {
        if (isMounted.current) {
          setRemoved(true);
          document.getElementById(`message_${messageId}`).remove();
        }
      })
      .catch(e => {
        console.error(e.message);
        if (isMounted.current) setError(e.message);
      })
      .finally(() => {
        if (isMounted.current) setIsRemoving(false);
      });
  };

  return { removed, isRemoving, removeMessage, error };
};

export const useMessageLiked = (chatId: number, message_id: number) => {
  const [liked, setLiked] = useState(false);
  const [isLiking, setIsLiking] = useState(false);
  const [error, setError] = useState<string>();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const removeLikeMessage = (): Promise<void> => {
    setIsLiking(true);

    return api
      .removeLikeChatMessage(chatId, message_id)
      .then(() => {
        if (isMounted.current) setLiked(false);
      })
      .catch((e: Error) => {
        console.error(e.message);
        if (isMounted.current) setError(e.message);
      })
      .finally(() => {
        if (isMounted.current) setIsLiking(false);
      });
  };

  const likeMessage = (): Promise<void> => {
    setIsLiking(true);

    return api
      .likeChatMessage(chatId, message_id)
      .then(() => {
        if (isMounted.current) setLiked(true);
      })
      .catch((e: Error) => {
        console.error(e.message);
        if (isMounted.current) setError(e.message);
      })
      .finally(() => {
        if (isMounted.current) setIsLiking(false);
      });
  };

  return { liked, likeMessage, removeLikeMessage, setLiked, error };
};

export const useMessageHide = (chatId: number, messageId: number) => {
  const [hidden, setHidden] = useState(false);
  const [isHiding, setIsHiding] = useState(false);
  const [error, setError] = useState<string>();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const hideMessage = (hide: boolean): Promise<void> => {
    setIsHiding(true);

    return api
      .hideChatMessage(chatId, messageId, hide)
      .then(() => {
        if (isMounted.current) setHidden(true);
      })
      .catch(e => {
        console.error(e.message);
        if (isMounted.current) setError(e.message);
      })
      .finally(() => {
        if (isMounted.current) setIsHiding(false);
      });
  };

  const getOriginalContent = () => api.getHiddenChatMessage(chatId, messageId);

  return { hidden, isHiding, hideMessage, error, getOriginalContent };
};
