import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';

import MentionsPlugin from './MentionsPlugin';
import './Editor.module.scss';
import { Placeholder } from './Placeholder';

interface EditorProps {
  placeholder: string;
}

export default function Editor({ placeholder }: EditorProps): JSX.Element {
  return (
    <div className="editor-container">
      <MentionsPlugin />
      <PlainTextPlugin
        contentEditable={
          <ContentEditable
            aria-placeholder={placeholder}
            placeholder={() => <Placeholder placeholder={placeholder} />}
            max={5}
          />
        }
        ErrorBoundary={LexicalErrorBoundary}
      />
    </div>
  );
}
