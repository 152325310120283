import React from 'react';
import styles from './Avatar.module.scss';
import defaultAvatar from '../../assets/images/default-avatar.svg';

export interface AvatarProps {
  src: string;
  alt?: string;
  className?: string;
}

const Avatar = ({ src, alt, className }: AvatarProps) => (
  <img className={[styles.wrapper, className].join(' ')} src={src || defaultAvatar} alt={alt} />
);

export default Avatar;
