import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React from 'react';

interface PlaceholderProps {
  placeholder: string;
}

export const Placeholder = ({ placeholder }: PlaceholderProps) => {
  const [editor] = useLexicalComposerContext();
  return (
    <div
      className="editor-placerholder"
      onClick={() => {
        editor.focus();
      }}>
      {placeholder}
    </div>
  );
};
