// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-CommentList-module-title-oB3TS{font-size:17px;font-style:normal;font-weight:bold;line-height:20px;color:#fff}`, "",{"version":3,"sources":["webpack://./src/components/CommentList/CommentList.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAEA,iCACE,cAAA,CACA,iBAAA,CACA,gBAAA,CACA,gBAAA,CACA,UCIM","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `-CommentList-module-title-oB3TS`
};
export default ___CSS_LOADER_EXPORT___;
