import { AutoTranslate } from '../../types/AutoTranslate';

export const DISPATCH = 'WIS/autoTranslate/DISPATCHED';
export const FULFILL = 'WIS/autoTranslate/FULFILLED';
export const REJECT = 'WIS/autoTranslate/REJECTED';

export const UPDATE_LANGUAGES_DISPATCHED =
  'WIS/autoTranslate/UPDATE_LANGUAGES_DISPATCHED';
export const UPDATE_LANGUAGES_FULFILLED =
  'WIS/autoTranslate/UPDATE_LANGUAGES_FULFILLED';
export const UPDATE_LANGUAGES_REJECTED =
  'WIS/autoTranslate/UPDATE_LANGUAGES_REJECTED';

export type AutoTranslateActionTypes =
  | 'WIS/autoTranslate/DISPATCHED'
  | 'WIS/autoTranslate/FULFILLED'
  | 'WIS/autoTranslate/REJECTED'
  | 'WIS/autoTranslate/UPDATE_LANGUAGES_DISPATCHED'
  | 'WIS/autoTranslate/UPDATE_LANGUAGES_FULFILLED'
  | 'WIS/autoTranslate/UPDATE_LANGUAGES_REJECTED';

export const dispatch = () => ({ type: DISPATCH });
export const fulfill = (
  autoTranslate: AutoTranslateState['autoTranslate'],
) => ({
  type: FULFILL,
  autoTranslate,
});
export const reject = (error: AutoTranslateState['error']) => ({
  type: REJECT,
  error,
});

export const updateLanguagesDispatch = (languages: AutoTranslate) => ({
  type: UPDATE_LANGUAGES_DISPATCHED,
  languages,
});
export const updateLanguagesFulfill = (languages: AutoTranslate) => ({
  type: UPDATE_LANGUAGES_FULFILLED,
  languages,
});
export const updateLanguagesReject = (error: AutoTranslateState['error']) => ({
  type: UPDATE_LANGUAGES_REJECTED,
  error,
});

export interface AutoTranslateState {
  autoTranslate: AutoTranslate;
  isFetching: boolean;
  isFetched: boolean;
  error?: string;
  isUpdating: boolean;
}

const initialState: AutoTranslateState = {
  autoTranslate: {},
  isFetching: false,
  isFetched: false,
  isUpdating: false,
  error: null,
};

export type AutoTranslateAction = {
  readonly type: AutoTranslateActionTypes;
  languages?: AutoTranslate;
} & Pick<AutoTranslateState, 'error' | 'autoTranslate'>;

export default function reducer(
  state = initialState,
  action: AutoTranslateAction,
) {
  switch (action.type) {
    case DISPATCH:
      return {
        ...state,
        isFetching: true,
      };
    case FULFILL:
      return {
        ...state,
        autoTranslate: action.autoTranslate,
        isFetching: false,
        isFetched: true,
        error: null,
      };
    case REJECT:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    case UPDATE_LANGUAGES_DISPATCHED:
      return {
        ...state,
        isUpdating: true,
      };
    case UPDATE_LANGUAGES_FULFILLED:
      return {
        ...state,
        isUpdating: false,
        autoTranslate: {
          ...state.autoTranslate,
          targetLanguages: action.languages,
        },
      };
    case UPDATE_LANGUAGES_REJECTED:
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };
    default:
      return state;
  }
}
