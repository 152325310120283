// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-UpdateApp-module-wrapper-e3PPg{color:#eee;width:100%;display:flex;flex-direction:column;flex:1;height:100%}.-UpdateApp-module-wrapper-e3PPg .-UpdateApp-module-content-Sr7yv{display:flex;flex:1;flex-direction:column;align-items:center;justify-content:center}.-UpdateApp-module-wrapper-e3PPg .-UpdateApp-module-title-UQn8G{width:100%;font-family:"Nexa";font-size:36px;font-style:normal;font-weight:700;line-height:38px;text-align:center;margin-top:16px}.-UpdateApp-module-wrapper-e3PPg .-UpdateApp-module-desc-_0mrs{width:100%;text-align:center;font-family:Barlow;font-size:17px;font-style:normal;font-weight:400;line-height:22px}`, "",{"version":3,"sources":["webpack://./src/components/UpdateApp/UpdateApp.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAEA,iCACE,UCgDa,CD/Cb,UAAA,CACA,YAAA,CACA,qBAAA,CACA,MAAA,CACA,WAAA,CACA,kEACE,YAAA,CACA,MAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CAEF,gEACE,UAAA,CACA,kBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,iBAAA,CACA,eAAA,CAEF,+DACE,UAAA,CACA,iBAAA,CAEA,kBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-UpdateApp-module-wrapper-e3PPg`,
	"content": `-UpdateApp-module-content-Sr7yv`,
	"title": `-UpdateApp-module-title-UQn8G`,
	"desc": `-UpdateApp-module-desc-_0mrs`
};
export default ___CSS_LOADER_EXPORT___;
