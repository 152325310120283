import auth, { AuthAction, AuthState, LOGOUT_FULFILL } from './auth';
import blocked, { BlockedAction, BlockedState } from './blocked';
import boards, { BoardState } from './board';
import chat, { ChatState } from './chat';
import comments, { CommentAction, CommentState } from './comment';
import files, { FileAction, FileState } from './files';
import flag, { FlagAction, FlagState } from './flag';
import liked, { LikedState } from './liked';
import notifications, {
  NotificationAction,
  NotificationState,
} from './notifications';
import posts, { PostAction, PostState } from './post';
import profile, { ProfileAction, ProfileState } from './profile';
import saved, { SavedState } from './saved';
import signUp, { SignUpAction, SignUpState } from './signUp';
import taxonomyCountry, { TaxonomyCountryAction } from './taxonomyCountry';
import taxonomyFlagReasons, {
  TaxonomyFlagReasonsAction,
} from './taxonomyFlagReasons';
import taxonomyGameInvolvement, {
  TaxonomyGameInvolvementAction,
} from './taxonomyGameInvolvement';
import taxonomyGender, { TaxonomyGenderAction } from './taxonomyGender';
import taxonomyMedicalStatus, {
  TaxonomyMedicalStatusAction,
} from './taxonomyMedicalStatus';
import taxonomyMilitaryService, {
  TaxonomyMilitaryServiceAction,
} from './taxonomyMilitaryService';
import taxonomyMilitaryStatus, {
  TaxonomyMilitaryStatusAction,
} from './taxonomyMilitaryStatus';
import taxonomyOpportunities, {
  TaxonomyOpportunitiesAction,
} from './taxonomyOpportunities';
import taxonomyPastGames, {
  TaxonomyPastGamesAction,
} from './taxonomyPastGames';
import taxonomyPastTrials, {
  TaxonomyPastTrialsAction,
} from './taxonomyPastTrials';
import postCategories, {
  PostCategoryAction,
  PostCategoryState,
} from './postCategories';

import { TaxonomyState } from './TaxonomyState';
import { combineReducers } from 'redux';

// import taxonomyEthnicity from './taxonomyEthnicity';

export const appReducer = combineReducers({
  auth,
  signUp,
  profile,
  notifications,
  chat,
  posts,
  postCategories,
  comments,
  blocked,
  saved,
  liked,
  flag,
  files,
  // taxonomyEthnicity,
  taxonomyGender,
  taxonomyCountry,
  taxonomyMilitaryStatus,
  taxonomyMilitaryService,
  taxonomyPastGames,
  taxonomyPastTrials,
  taxonomyMedicalStatus,
  taxonomyOpportunities,
  taxonomyGameInvolvement,
  taxonomyFlagReasons,
  boards,
});

export const rootReducer = (state: RootState, action: RootAction) => {
  if (action.type === LOGOUT_FULFILL) {
    // state = undefined;
    window.location.reload();
  }

  return appReducer(state, action);
};

export interface RootState {
  auth: AuthState;
  signUp: SignUpState;
  profile: ProfileState;
  chat: ChatState;
  taxonomyCountry: TaxonomyState;
  taxonomyFlagReasons: TaxonomyState;
  taxonomyGameInvolvement: TaxonomyState;
  taxonomyGender: TaxonomyState;
  taxonomyMedicalStatus: TaxonomyState;
  taxonomyMilitaryService: TaxonomyState;
  taxonomyMilitaryStatus: TaxonomyState;
  taxonomyOpportunities: TaxonomyState;
  taxonomyPastGames: TaxonomyState;
  taxonomyPastTrials: TaxonomyState;
  notifications: NotificationState;
  posts: PostState;
  postCategories: PostCategoryState;
  comments: CommentState;
  saved: SavedState;
  liked: LikedState;
  boards: BoardState;
  blocked: BlockedState;
  flag: FlagState;
  files: FileState;
}

export type RootAction =
  | AuthAction
  | ProfileAction
  | TaxonomyCountryAction
  | TaxonomyFlagReasonsAction
  | TaxonomyGameInvolvementAction
  | TaxonomyGenderAction
  | TaxonomyMedicalStatusAction
  | TaxonomyMilitaryServiceAction
  | TaxonomyMilitaryStatusAction
  | TaxonomyOpportunitiesAction
  | TaxonomyPastTrialsAction
  | TaxonomyPastGamesAction
  | SignUpAction
  | PostAction
  | CommentAction
  | FlagAction
  | BlockedAction
  | FileAction
  | NotificationAction
  | PostCategoryAction;
