import { FC } from 'react';
import styles from './FieldStatusBlock.module.scss';
import clsx from 'clsx';

export interface FieldStatusBlockProps {
  error: string | React.ReactNode;
  touched: boolean;
  focused?: boolean;
  displayBlock?: boolean;
  light?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const FieldStatusBlock: FC<FieldStatusBlockProps> = props => {
  const {
    displayBlock = true,
    error,
    touched = false,
    focused = false,
    light = false,
    children,
    className,
  } = props;

  const statusBlockClasses = [
    displayBlock && styles.statusBlock,
    light && styles.light,
    focused && styles.focused,
    !focused && touched && error && styles.error,
  ].join(' ');

  return (
    <div className={styles.wrapper}>
      <div className={clsx(styles.blockWrapper, className && className)}>
        <div className={statusBlockClasses}>{children}</div>
      </div>
      {touched && error && <div className={styles.errorMessage}>{error}</div>}
    </div>
  );
};

export default FieldStatusBlock;
