import React, { FC, useEffect, useState } from 'react';

import Avatar from '../Avatar/Avatar';
import CommentMention from '../CommentMention/CommentMention';
import { CommentType } from '../../types/Comment';
import { Preferences } from '@capacitor/preferences';
import { decodeEntity } from 'html-entities';
import defaultAvatar from '../../assets/images/default-avatar.svg';
import styles from './Comment.module.scss';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../util/APIDjango';
import dayjs from 'dayjs';

const decodeCommentText = (comment: string) => {
  const matches = [];
  let match: RegExpMatchArray = [''];

  let restOfText = comment;
  while (match !== null) {
    restOfText = restOfText.substring(match.index + match[0].length);
    match = restOfText.match('&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-fA-F]{1,6});');
    if (match !== null) {
      matches.push({
        entity: match[0],
        index: comment.length - restOfText.length + match.index,
      });
    }
  }

  let returnString = comment;
  matches.forEach(elem => {
    returnString = returnString.replace(elem.entity, decodeEntity(elem.entity));
  });

  return returnString;
};

export interface CommentProps {
  comment: CommentType;
  isBlocked?: boolean;
  translatedMessage?: string;
  translationError?: string;
  translatedContent?: string;
  onClick?: () => void;
}

const Comment: FC<CommentProps> = ({
  comment,
  isBlocked = false,
  translatedMessage = null,
  translationError = null,
  onClick,
}) => {
  const [autoTranslateStatus, setAutoTranslateStatus] = useState(true);

  useEffect(() => {
    void Preferences.get({ key: 'autoTranslateStatus' }).then(result => {
      if (!result.value) {
        setAutoTranslateStatus(false);
      }
    });
  }, []);

  const { t } = useTranslation();
  // const { uid } = useContext(AuthContext);
  const [authState, setAuthState] = useAuth();
  const { uid } = authState;

  const username = isBlocked ? 'Blocked' : comment?.author?.username;
  const avatarSrc = isBlocked ? defaultAvatar : comment?.author?.avatar?.src;

  const content =
    uid !== comment?.author?.id && comment?.translatedContent && autoTranslateStatus
      ? comment?.translatedContent
      : isBlocked
        ? t('blockedContent.label')
        : comment?.content;

  const mentions: CommentType['mentions'] =
    isBlocked || (comment.mentions && comment.mentions?.length === 0)
      ? undefined
      : comment.mentions;
  const wrapperClasses = [styles.wrapper, isBlocked && styles.blocked].join(' ');

  const buildComment = (commentText: string) => {
    if (!mentions) return [<>{commentText}</>];
    const { mentionEndingIdentifier, mentionStartingIdentifier } = comment;

    const tmp = commentText.split(mentionStartingIdentifier);
    const commentParts = tmp.map(part => {
      if (part.includes(mentionEndingIdentifier)) {
        const splitLine = part.split(mentionEndingIdentifier);
        const mentionedUser = mentions.find(user => user.id == splitLine[0]);
        return (
          <>
            <CommentMention className={styles.mention} user={mentionedUser} />
            {splitLine[1]}
          </>
        );
      }
      return <>{part}</>;
    });
    return commentParts;
  };

  return (
    <div id={comment?.id} key={comment?.id} className={wrapperClasses}>
      <Avatar className={styles.avatar} src={avatarSrc} alt={username} />
      <div className={styles.body}>
        <div
          className={styles.comment}
          onClick={onClick}
          onKeyUp={e => (e.keyCode === 13 ? onClick() : null)}
          role="button"
          tabIndex={0}>
          <div className={styles.content}>
            <div className={styles.name}>{username}</div>
            <p className={[styles.content, mentions && styles.extendLineHeight].join(' ')}>
              {buildComment(decodeCommentText(content))}
            </p>
          </div>
        </div>
        <div className={styles.metaWrapper}>
          <div className={styles.created}>{dayjs(comment?.created).from(dayjs())}</div>
          {translationError && <div className={styles.translationError}>{translationError}</div>}
          {!translationError && translatedMessage && (
            <div className={styles.translatedMessage}>{translatedMessage}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Comment;
