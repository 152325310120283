// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-UIContainer-module-wrapper-oEc7O{width:100%;background-color:#222;height:fit-content;border-radius:12px;display:flex;flex-direction:column;gap:12px;padding:16px}.-UIContainer-module-wrapper-oEc7O.-UIContainer-module-bottomSpace-LKHkQ{margin-bottom:24px}.-UIContainer-module-wrapper-oEc7O.-UIContainer-module-withBorder-gw0lc{border:1px solid #484848}`, "",{"version":3,"sources":["webpack://./src/components/UIContainer/UIContainer.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAGA,mCACE,UAAA,CACA,qBCqCe,CDpCf,kBAAA,CACA,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,YAAA,CACA,yEACE,kBAAA,CAEF,wEACE,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-UIContainer-module-wrapper-oEc7O`,
	"bottomSpace": `-UIContainer-module-bottomSpace-LKHkQ`,
	"withBorder": `-UIContainer-module-withBorder-gw0lc`
};
export default ___CSS_LOADER_EXPORT___;
