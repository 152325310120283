// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-CommentField-module-wrapper-Of0yK{position:relative;display:inline-block;width:100%;margin:8px 0px}.-CommentField-module-wrapper-Of0yK textarea{position:relative;display:block;width:100%;border-radius:8px;border:1px solid #eceff1;padding:8px 16px;font-size:1rem;background-color:rgba(0,0,0,0);color:#37474f;resize:none;outline:none;box-sizing:border-box;-webkit-appearance:none}.-CommentField-module-wrapper-Of0yK textarea.-CommentField-module-disabled-OYYTU{opacity:.5;color:#37474f}::-webkit-input-placeholder{color:#cfd8dc}::-moz-placeholder{color:#cfd8dc}:-ms-input-placeholder{color:#cfd8dc}:-moz-placeholder{color:#cfd8dc}.-CommentField-module-uppercase-j_PmQ{text-transform:uppercase}`, "",{"version":3,"sources":["webpack://./src/components/CommentField/CommentField.module.scss","webpack://./src/theme/_dimensions.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAGA,oCACE,iBAAA,CACA,oBAAA,CACA,UAAA,CACA,cAAA,CAGF,6CACE,iBAAA,CACA,aAAA,CACA,UAAA,CACA,iBCTY,CDUZ,wBAAA,CACA,gBAAA,CACA,cAAA,CACA,8BAAA,CACA,aEGS,CFFT,WAAA,CACA,YAAA,CACA,qBAAA,CACA,uBAAA,CAGF,iFACE,UAAA,CACA,aENS,CFSX,4BACE,aEjBS,CFmBX,mBACE,aEpBS,CFsBX,uBACE,aEvBS,CFyBX,kBACE,aE1BS,CF6BX,sCACE,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-CommentField-module-wrapper-Of0yK`,
	"disabled": `-CommentField-module-disabled-OYYTU`,
	"uppercase": `-CommentField-module-uppercase-j_PmQ`
};
export default ___CSS_LOADER_EXPORT___;
