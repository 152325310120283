import { ChatMessage, ChatMessagesListResp } from '../../types/ChatMessage';

import { AnyAction } from 'redux';
import { ChatMessageFetchOptions } from '../../types/ChatMessageFetchOptions';
import { ChatRoom } from '../../types/ChatRoom';

export const GET_CHAT_ROOMS_DISPATCH = 'WIS/chat/GET_CHAT_ROOMS_DISPATCH';
export const GET_CHAT_ROOMS_FULFILL = 'WIS/chat/GET_CHAT_ROOMS_FULFILLED';
export const GET_CHAT_ROOMS_REJECT = 'WIS/chat/GET_CHAT_ROOMS_REJECTED';

export const GET_MESSAGES_FOR_CHAT_DISPATCH = 'WIS/chat/GET_MESSAGES_FOR_CHAT_DISPATCHED';
export const GET_MESSAGES_FOR_CHAT_FULFILL = 'WIS/chat/GET_MESSAGES_FOR_CHAT_FULFILLED';
export const GET_MESSAGES_FOR_CHAT_REJECT = 'WIS/chat/GET_MESSAGES_FOR_CHAT_REJECTED';
export const GET_NEXT_MESSAGES_FOR_CHAT_DISPATCH = 'WIS/chat/GET_NEXT_MESSAGES_FOR_CHAT_DISPATCHED';
export const GET_NEXT_MESSAGES_FOR_CHAT_FULFILL = 'WIS/chat/GET_NEXT_MESSAGES_FOR_CHAT_FULFILLED';
export const GET_NEXT_MESSAGES_FOR_CHAT_REJECT = 'WIS/chat/GET_NEXT_MESSAGES_FOR_CHAT_REJECTED';
export const GET_PREVIOUS_MESSAGES_FOR_CHAT_DISPATCH =
  'WIS/chat/GET_PREVIOUS_MESSAGES_FOR_CHAT_DISPATCHED';
export const GET_PREVIOUS_MESSAGES_FOR_CHAT_FULFILL =
  'WIS/chat/GET_PREVIOUS_MESSAGES_FOR_CHAT_FULFILLED';
export const GET_PREVIOUS_MESSAGES_FOR_CHAT_REJECT =
  'WIS/chat/GET_PREVIOUS_MESSAGES_FOR_CHAT_REJECTED';

export const ADD_WS_MESSAGE_TO_LIST = 'WIS/chat/ADD_WS_MESSAGE_TO_LIST';
export const ADD_WS_MESSAGE_LIKES_TO_LIST = 'WIS/chat/ADD_WS_MESSAGE_LIKES_TO_LIST';
// export const ADD_WS_MESSAGE_TO_LIST_FULFILL =
//   'WIS/chat/NEW_WS_MESSAGE_TO_LIST_FULFILLED';
// export const ADD_WS_MESSAGE_TO_LIST_REJECT =
//   'WIS/chat/NEW_WS_MESSAGE_TO_LIST_REJECTED';

export const REMOVE_WS_MESSAGE_FROM_LIST = 'WIS/chat/REMOVE_WS_MESSAGE_FROM_LIST';

export const getChatRoomsDispatch = (id?: number) => ({
  type: GET_CHAT_ROOMS_DISPATCH,
  id,
});
// export const getChatRoomsFulfill = (rooms: ChatRoom[]) => ({
//   type: GET_CHAT_ROOMS_FULFILL,
//   rooms,
// });
// export const getChatRoomsReject = (error: string) => ({
//   type: GET_CHAT_ROOMS_REJECT,
//   error,
// });
export const getMessagesForChatDispatch = (id: number, options?: ChatMessageFetchOptions) => ({
  type: GET_MESSAGES_FOR_CHAT_DISPATCH,
  id,
  options,
});
export const getMessagesForChatFulfill = (chatId: number, messages: ChatMessagesListResp) => ({
  type: GET_MESSAGES_FOR_CHAT_FULFILL,
  chatId,
  messages,
});

export const getMessagesForChatReject = (error: string) => ({
  type: GET_MESSAGES_FOR_CHAT_REJECT,
  error,
});
export const getNextMessagesForChatDispatch = (id: number, url: string) => ({
  type: GET_NEXT_MESSAGES_FOR_CHAT_DISPATCH,
  id,
  url,
});
export const getNextMessagesForChatFulfill = (chatId: number, messages: ChatMessagesListResp) => ({
  type: GET_NEXT_MESSAGES_FOR_CHAT_FULFILL,
  chatId,
  messages,
});
export const getNextMessagesForChatReject = (error: string) => ({
  type: GET_NEXT_MESSAGES_FOR_CHAT_REJECT,
  error,
});
export const getPreviousMessagesForChatDispatch = (id: number, url: string) => ({
  type: GET_PREVIOUS_MESSAGES_FOR_CHAT_DISPATCH,
  id,
  url,
});
export const getPreviousMessagesForChatFulfill = (
  chatId: number,
  messages: ChatMessagesListResp,
) => ({ type: GET_PREVIOUS_MESSAGES_FOR_CHAT_FULFILL, chatId, messages });
export const getPreviousMessagesForChatReject = (error: string) => ({
  type: GET_PREVIOUS_MESSAGES_FOR_CHAT_FULFILL,
  error,
});
export const addWsMessageToList = (chatId: number, message: ChatMessage) => ({
  type: ADD_WS_MESSAGE_TO_LIST,
  chatId,
  message,
});

export const addWsMessageLikesToList = (
  chatId: number,
  likes_count: number,
  message_id: number,
  isLike: boolean,
) => {
  return {
    type: ADD_WS_MESSAGE_LIKES_TO_LIST,
    chatId,
    likes_count,
    message_id,
    isLike,
  };
};

export const removeWsMessageFromList = (chatId: number, messageId: number) => ({
  type: REMOVE_WS_MESSAGE_FROM_LIST,
  chatId,
  messageId,
});

export interface ChatState {
  rooms: ChatRoom[];
  messagesByRoom: ChatMessagesListResp[];
  fetchError?: string;
  isFetching: boolean;
  isFetched: boolean;
  messagesFetched: boolean;
  messagesFetching: boolean;
  lastMessageIdFetched?: number;
}

const initialState: ChatState = {
  rooms: [],
  messagesByRoom: [],
  isFetching: false,
  isFetched: false,
  messagesFetched: false,
  messagesFetching: false,
};

export default function reducer(state = initialState, action: AnyAction): ChatState {
  const rooms = state.messagesByRoom.slice();

  switch (action.type) {
    case GET_CHAT_ROOMS_DISPATCH:
      return {
        ...state,
        isFetching: true,
        isFetched: false,
      };
    case GET_CHAT_ROOMS_FULFILL:
      return {
        ...state,
        rooms: action.rooms,
        isFetched: true,
        isFetching: false,
      };
    case GET_CHAT_ROOMS_REJECT:
      return {
        ...state,
        fetchError: action.error,
        isFetched: true,
        isFetching: false,
      };
    case GET_MESSAGES_FOR_CHAT_DISPATCH:
      return {
        ...state,
        messagesFetched: false,
        messagesFetching: true,
      };
    case GET_MESSAGES_FOR_CHAT_FULFILL:
      rooms[action.chatId] = {
        ...action.messages,
        results: addMessagesToList(
          rooms[action.chatId]?.results || [],
          action.messages?.results || [],
        ),
      };
      return {
        ...state,
        messagesByRoom: rooms,
        messagesFetched: true,
        messagesFetching: false,
        lastMessageIdFetched: action.messages?.results[action.messages?.results.length - 1]?.id,
      };
    case GET_MESSAGES_FOR_CHAT_REJECT:
      return {
        ...state,
        fetchError: action.error,
        messagesFetched: true,
        messagesFetching: false,
      };
    case GET_NEXT_MESSAGES_FOR_CHAT_DISPATCH:
      return {
        ...state,
        messagesFetched: false,
        messagesFetching: true,
      };
    case GET_NEXT_MESSAGES_FOR_CHAT_FULFILL:
      rooms[action.chatId] = {
        ...action.messages,
        results: addMessagesToList(
          rooms[action.chatId]?.results || [],
          action.messages?.results || [],
        ),
      };
      return {
        ...state,
        messagesByRoom: rooms,
        messagesFetched: true,
        messagesFetching: false,
        lastMessageIdFetched: action.messages?.results[action.messages?.results.length - 1]?.id,
      };
    case GET_NEXT_MESSAGES_FOR_CHAT_REJECT:
      return {
        ...state,
        fetchError: action.error,
        messagesFetched: true,
        messagesFetching: false,
      };
    case GET_PREVIOUS_MESSAGES_FOR_CHAT_DISPATCH:
      return {
        ...state,
        messagesFetched: false,
        messagesFetching: true,
      };
    case GET_PREVIOUS_MESSAGES_FOR_CHAT_FULFILL:
      rooms[action.chatId] = {
        ...action.messages,
        results: addMessagesToList(
          rooms[action.chatId]?.results || [],
          action.messages?.results || [],
        ),
      };
      return {
        ...state,
        messagesByRoom: rooms,
        messagesFetched: true,
        messagesFetching: false,
        lastMessageIdFetched: action.messages?.results[action.messages?.results.length - 1]?.id,
      };
    case GET_PREVIOUS_MESSAGES_FOR_CHAT_REJECT:
      return {
        ...state,
        fetchError: action.error,
        messagesFetched: true,
        messagesFetching: false,
      };

    case ADD_WS_MESSAGE_TO_LIST:
      rooms[action?.chatId].results = addMessagesToList(rooms[action?.chatId].results, [
        action?.message,
      ]);
      return {
        ...state,
        messagesByRoom: rooms,
        lastMessageIdFetched: action.message.id,
      };

    case REMOVE_WS_MESSAGE_FROM_LIST:
      const msgs = rooms[action.chatId].results;
      const foundMessage = msgs.findIndex(({ id }) => id === action.messageId);
      if (foundMessage < 0) break;
      msgs.splice(foundMessage, 1);
      rooms[action.chatId].results = msgs;
      return {
        ...state,
        messagesByRoom: rooms,
      };

    case ADD_WS_MESSAGE_LIKES_TO_LIST:
      const { chatId, likes_count, message_id, isLike } = action;

      if (rooms[chatId]) {
        const messageIndex = rooms[chatId].results.findIndex(msg => msg.id === message_id);
        if (messageIndex !== -1) {
          if (rooms[chatId].results[messageIndex]) {
            if (!isLike) {
              const likedIndex = rooms[chatId]?.likedByMe?.indexOf(message_id);
              if (likedIndex !== -1) {
                rooms[chatId].likedByMe.splice(likedIndex, 1);
              }
              rooms[chatId].results[messageIndex].likesCount = Math.max(
                0,
                rooms[chatId].results[messageIndex].likesCount - 1,
              );
              return {
                ...state,
                messagesByRoom: rooms,
              };
            } else {
              if (!rooms[chatId]?.likedByMe.includes(message_id)) {
                rooms[chatId]?.likedByMe.push(message_id);
              }
              rooms[chatId].results[messageIndex].likesCount++;
              return {
                ...state,
                messagesByRoom: rooms,
              };
            }
          }
        }
      }
    default:
      return {
        ...state,
      };
  }
}

export const addMessagesToList = (msgList: ChatMessage[], msgsToAdd: ChatMessage[]) => {
  const msgMap = new Map(msgList.map(msg => [msg.id, msg]));

  msgsToAdd.forEach(message => {
    if (!msgMap.has(message.id) || msgMap.get(message.id).content !== message.content) {
      msgMap.set(message.id, message);
    }
  });

  msgList.forEach(msg => {
    if (msg.repliedToMessage && msgsToAdd.some(newMsg => newMsg.id === msg.repliedToMessage.id)) {
      msg.repliedToMessage = msgsToAdd.find(newMsg => newMsg.id === msg.repliedToMessage.id);
      msgMap.set(msg.id, msg);
    }
  });

  return Array.from(msgMap.values()).sort((a, b) => a.created.getTime() - b.created.getTime());
};
