// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-CheckboxButtonGroup-module-wrapper-SuS0s .-CheckboxButtonGroup-module-items-I2ehy{margin:8px -4px 0px}`, "",{"version":3,"sources":["webpack://./src/components/CheckboxButtonGroup/CheckboxButtonGroup.module.scss"],"names":[],"mappings":"AAIE,oFACE,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-CheckboxButtonGroup-module-wrapper-SuS0s`,
	"items": `-CheckboxButtonGroup-module-items-I2ehy`
};
export default ___CSS_LOADER_EXPORT___;
