import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import editorStyles from './MentionsEditorChat.module.scss';
import { useTranslation } from 'react-i18next';
import { ChatMessage } from '../../types/ChatMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { decodeEntity } from 'html-entities';
import CommentMention from '../CommentMention/CommentMention';
import { $restoreEditorState } from '@lexical/utils';
import {
  $getSelection,
  $isRangeSelection,
  EditorState,
  LexicalEditor,
  RootNode,
  SerializedParagraphNode,
  SerializedTextNode,
} from 'lexical';
import { SerializedMentionNode } from './MentionNode';
import Editor from './Editor';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $trimTextContentFromAnchor } from '@lexical/selection';
import { MaxLengthPlugin } from './MaxLengthPlugin';
import FieldStatusBlock from '../FieldStatusBlock/FieldStatusBlock';
import clsx from 'clsx';

interface MentionsEditorProps {
  id?: string;
  disabled: boolean;
  placeholder: string;
  replyMessage: ChatMessage;
  setReplyMessage?: Dispatch<SetStateAction<ChatMessage | null>>;
  maxLength?: number;
  setCurrentLength: (currentLength: number) => void;
  currentLength: number;
}

export const editorStateToString = (editor: LexicalEditor): string => {
  const raw = editor.getEditorState().toJSON()?.root?.children[0];
  if (!raw || raw.type !== 'paragraph') {
    return '';
  }
  const paragraph = raw as SerializedParagraphNode;
  if (!paragraph.children.length) {
    return '';
  }

  return paragraph.children
    .map(content => {
      if (content.type === 'linebreak') {
        return '\n';
      }
      if (content.type === 'text') {
        return (content as SerializedTextNode).text;
      }
      if (content.type === 'mention') {
        return `{{${(content as SerializedMentionNode).id}}}`;
      }
    })
    .join('');
};

const MentionsEditorChat: FC<MentionsEditorProps> = ({
  disabled,
  placeholder,
  replyMessage,
  setReplyMessage,
  maxLength = 1000,
  currentLength,
  setCurrentLength,
}) => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerNodeTransform(RootNode, (rootNode: RootNode) => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
        return;
      }

      const textContentSize = rootNode.getTextContentSize();
      setCurrentLength(textContentSize);
    });
  }, [editor, maxLength]);

  const { t } = useTranslation();

  const decodeCommentText = (chat: string) => {
    const matches = [];
    let match: RegExpMatchArray = [''];

    let restOfText = chat;
    while (match !== null) {
      restOfText = restOfText.substring(match.index + match[0].length);
      match = restOfText.match('&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-fA-F]{1,6});');
      if (match !== null) {
        matches.push({
          entity: match[0],
          index: chat.length - restOfText.length + match.index,
        });
      }
    }

    let returnString = chat;
    matches.forEach(elem => {
      returnString = returnString.replace(elem.entity, decodeEntity(elem.entity));
    });

    return returnString;
  };

  const replyMentions: ChatMessage['mentions'] =
    replyMessage?.mentions && replyMessage.mentions.length === 0
      ? undefined
      : replyMessage?.mentions;

  const contentReplyMessage = replyMessage?.content;

  const buildReplyComment = (chatText: string) => {
    if (!replyMentions) return [<>{chatText}</>];
    // const { mentionEndingIdentifier, mentionStartingIdentifier } = message;
    const mentionEndingIdentifier = '}}';
    const mentionStartingIdentifier = '{{';

    const tmp = chatText.split(mentionStartingIdentifier);
    const messageParts = tmp.map(part => {
      if (part.includes(mentionEndingIdentifier)) {
        const splitLine = part.split(mentionEndingIdentifier);
        const mentionedUser = replyMentions.find(user => user.id == splitLine[0]);

        return (
          <>
            <CommentMention className={editorStyles.replyMention} user={mentionedUser} />
            {splitLine[1]}
          </>
        );
      }
      return <>{part}</>;
    });
    return messageParts;
  };

  return (
    <div className={editorStyles.editorWrapper}>
      <div
        className={clsx(editorStyles.editor, currentLength > maxLength && editorStyles.error)}
        onClick={() => {
          editor.focus();
        }}>
        {replyMessage && (
          <div className={editorStyles.replyContainer}>
            <div className={editorStyles.author}>
              <div>
                {t('replyingTo.label')} {replyMessage.author.username}
              </div>
              <div className={editorStyles.closeIcon} onClick={() => setReplyMessage(null)}>
                <FontAwesomeIcon icon={['far', 'xmark-circle']} />
              </div>
            </div>

            <p className={editorStyles.content}>
              {buildReplyComment(decodeCommentText(contentReplyMessage))}
            </p>
          </div>
        )}

        <Editor placeholder={disabled ? t('noCommentsAllowed.label') : String(placeholder)} />
      </div>
      <FieldStatusBlock
        touched={!!currentLength}
        error={currentLength >= maxLength ? t('tooLongText.label', { length: maxLength }) : null}
      />
      <div className={editorStyles.helperText}>
        {currentLength} / 1000 {t('characters.label')}
      </div>
    </div>
  );
};

export default MentionsEditorChat;
