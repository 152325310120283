import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styles from './FullModal.module.scss';
import useModal from '../../hooks/useModal';
import clsx from 'clsx';

const FullModal = ({ show, className, children, backDropClick, isChatPage = false }) => {
  const setShow = useModal();
  const [extraClass, setExtraClass] = useState(false);
  useEffect(() => {
    setShow(show);
    setExtraClass(show);
  }, [show]);

  useEffect(() => {
    document.getElementById('app')?.classList.add('fullscreen');
    if (isChatPage) {
      document.getElementById('app')?.classList.add('chatPage');
    }
    return () => {
      document.getElementById('app')?.classList.remove('fullscreen');
      if (isChatPage) {
        document.getElementById('app')?.classList.remove('chatPage');
      }
    };
  }, []);
  const modalRoot = document.getElementById('modal-root');

  return ReactDOM.createPortal(
    <div onClick={backDropClick} className={clsx(styles.backdrop, extraClass && styles.open)}>
      <div
        className={[styles.modal, isChatPage && styles.chat, className].join(' ')}
        style={{
          position: 'relative',
        }}
        onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>,
    modalRoot,
  );
};

FullModal.propTypes = {
  show: PropTypes.bool,
  backDropClick: PropTypes.func,
};

FullModal.defaultProps = {
  show: false,
  backDropClick: null,
};

export default FullModal;
