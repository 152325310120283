import { FC, FocusEvent, ReactElement } from 'react';
import FieldLabel from '../FieldLabel/FieldLabel';
import styles from './CheckboxButtonGroup.module.scss';
import { CheckboxButtonProps } from '../CheckboxButton/CheckboxButton';

type CheckboxButtonGroupValue = number | string;
export interface CheckboxButtonGroupProps {
  label: string;
  value: CheckboxButtonGroupValue | CheckboxButtonGroupValue[];
  errorText?: string;
  onChange: (value: CheckboxButtonGroupValue | CheckboxButtonGroupValue[]) => void;
  onBlur?: (e: FocusEvent) => void;
  multiSelect: boolean;
  // items: { key: string; description: string; }[] | Map<string, string>;
  renderItems: (
    value: CheckboxButtonGroupValue | CheckboxButtonGroupValue[],
    handleSelect: (optionValue: CheckboxButtonGroupValue) => void,
    getSelectedState: (optionValue: CheckboxButtonGroupValue) => boolean,
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void,
  ) => ReactElement<CheckboxButtonProps>[];
  required: boolean;
  disabled: boolean;
  touched: boolean;
  children?: React.ReactNode;
}

const CheckboxButtonGroup: FC<CheckboxButtonGroupProps> = ({
  label,
  value,
  onChange,
  multiSelect = false,
  renderItems,
  required = false,
  disabled = false,
  touched = false,
  errorText,
}) => {
  const getSelectedState = (optionValue: CheckboxButtonGroupValue) =>
    multiSelect && Array.isArray(value)
      ? value && value.includes(optionValue)
      : value === optionValue;

  const handleSelect = (optionValue: CheckboxButtonGroupValue) => {
    if (onChange && multiSelect && Array.isArray(value)) {
      if (!value) {
        onChange([optionValue]);
      } else if (value.includes(optionValue)) {
        const newValue = value.filter(v => v !== optionValue);
        onChange(newValue);
      } else {
        onChange(value.concat(optionValue));
      }
    } else if (onChange && !multiSelect) {
      onChange(optionValue);
    }
  };

  return (
    <div className={styles.wrapper}>
      <FieldLabel label={label} required={required} disabled={disabled} />
      <div className={styles.items}>{renderItems(value, handleSelect, getSelectedState)}</div>
    </div>
  );
};

export default CheckboxButtonGroup;
