import React, { useEffect, useState } from 'react';

import { FEATURE_FLAGS } from '../../util/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink as Link } from 'react-router-dom';
import styles from './TabNav.module.scss';
import { useTranslation } from 'react-i18next';

import { Posts } from '../Icons/Posts';
import { Settings } from '../Icons/Settings';
import { Chat } from '../Icons/Chat';
import { Notifications } from '../Icons/Notifications';
import api from '../../util/api';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotificationUnRead } from '../../redux/modules/notifications';

type TabNavigationItem = {
  to: string;
  label: string;
  icon: JSX.Element;
  badgeCount?: number;
};

const TabNav = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const defaultBadge = useSelector(({ notifications }: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return notifications?.history?.unreadCount ?? 0;
  });
  const [badgeCount, setBadgeCount] = useState(defaultBadge);

  const navigationItems: TabNavigationItem[] = [
    {
      to: '/all-posts',
      label: t('posts.label'),
      icon: <Posts />,
    },
    {
      to: '/settings',
      label: t('settings.label'),
      icon: <Settings />,
    },
  ];

  if (FEATURE_FLAGS.savedPosts) {
    navigationItems.splice(navigationItems.length - 1, 0, {
      to: '/saved',
      label: t('saved.label'),
      icon: <FontAwesomeIcon icon={['far', 'bookmark']} />,
    });
  }

  if (FEATURE_FLAGS.notifications) {
    navigationItems.splice(navigationItems.length - 1, 0, {
      to: '/notifications',
      label: t('notifications.label'),
      icon: <Notifications />,
      badgeCount,
    });
  }

  if (FEATURE_FLAGS.chat) {
    navigationItems.splice(navigationItems.length - 1, 0, {
      to: '/chat',
      label: t('chat.label'),
      icon: <Chat />,
    });
  }

  useEffect(() => {
    api
      .notificationsGetHistory(1000, 1, true)
      .then(({ results }) => {
        const unreadCount =
          results?.filter((notification: { read: boolean }) => !notification.read).length ?? 0;
        dispatch(fetchNotificationUnRead(unreadCount));
        setBadgeCount(unreadCount);
      })
      .catch(() => {
        dispatch(fetchNotificationUnRead(0));
        setBadgeCount(0);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.wrapper}>
      <ul className={styles.menu}>
        {navigationItems.map(({ to, label, icon, badgeCount }) => (
          <li key={label}>
            <Link to={to} activeClassName={styles.active}>
              {badgeCount && badgeCount > 0 ? (
                <div className={styles.badge}>{badgeCount}</div>
              ) : null}
              {icon}
              <div className={styles.label}>{label}</div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TabNav;
