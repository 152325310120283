// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-CheckboxButton-module-wrapper-zSmBC{display:inline-block}.-CheckboxButton-module-wrapper-zSmBC .-CheckboxButton-module-checkbox-Wo0Fc{display:none}.-CheckboxButton-module-wrapper-zSmBC .-CheckboxButton-module-button-yCQz4{position:relative;display:inline-block;padding:8px;border-radius:12px;margin:4px;background-color:#191919;color:#b3b3b3;font-size:1rem;transition:all .25s ease;box-sizing:border-box;font-weight:bold}.-CheckboxButton-module-wrapper-zSmBC .-CheckboxButton-module-button-yCQz4.-CheckboxButton-module-active-GuvRD{background-color:gold;color:#000}.-CheckboxButton-module-wrapper-zSmBC .-CheckboxButton-module-button-yCQz4.-CheckboxButton-module-disabled-usa6W{background-color:rgba(0,0,0,0)}.-CheckboxButton-module-wrapper-zSmBC .-CheckboxButton-module-button-yCQz4.-CheckboxButton-module-disabled-usa6W.-CheckboxButton-module-active-GuvRD{background-color:#2a2a2a;color:#eee}`, "",{"version":3,"sources":["webpack://./src/components/CheckboxButton/CheckboxButton.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAIA,sCACE,oBAAA,CACA,6EACE,YAAA,CAEF,2EACE,iBAAA,CACA,oBAAA,CACA,WAAA,CACA,kBAAA,CACA,UAAA,CACA,wBC4Be,CD3Bf,aCoCa,CDnCb,cAAA,CACA,wBAAA,CACA,qBAAA,CACA,gBAAA,CAEA,+GACE,qBCiBa,CDhBb,UCfE,CDkBJ,iHACE,8BAAA,CACA,qJACE,wBCcU,CDbV,UCoBO","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-CheckboxButton-module-wrapper-zSmBC`,
	"checkbox": `-CheckboxButton-module-checkbox-Wo0Fc`,
	"button": `-CheckboxButton-module-button-yCQz4`,
	"active": `-CheckboxButton-module-active-GuvRD`,
	"disabled": `-CheckboxButton-module-disabled-usa6W`
};
export default ___CSS_LOADER_EXPORT___;
