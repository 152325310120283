export const INITIAL_LIKES = 'WIS/like/INITIAL_LIKES';
export const LIKE_DISPATCH = 'WIS/like/LIKE_DISPATCHED';
export const LIKE_FULFILL = 'WIS/like/LIKE_FULFILLED';
export const LIKE_REJECT = 'WIS/like/LIKE_REJECTED';

export const UNLIKE_DISPATCH = 'WIS/like/UNLIKE_DISPATCHED';
export const UNLIKE_FULFILL = 'WIS/like/UNLIKE_FULFILLED';
export const UNLIKE_REJECT = 'WIS/like/UNLIKE_REJECTED';

export type LikedActionTypes = 
  'WIS/like/INITIAL_LIKES'
  | 'WIS/like/LIKE_DISPATCHED'
  | 'WIS/like/LIKE_FULFILLED'
  | 'WIS/like/LIKE_REJECTED'
  | 'WIS/like/UNLIKE_DISPATCHED'
  | 'WIS/like/UNLIKE_FULFILLED'
  | 'WIS/like/UNLIKE_REJECTED';

export const initialLikes = (posts: string[]) => ({ type: INITIAL_LIKES, posts });
export const like = (postId: string) => ({ type: LIKE_DISPATCH, postId });
export const likeFulfill = (post: string) => ({ type: LIKE_FULFILL, post });
export const likeReject = (error: string) => ({ type: LIKE_REJECT, error });

export const unLike = (postId: string) => ({ type: UNLIKE_DISPATCH, postId });
export const unLikeFulfill = (post: string) => ({ type: UNLIKE_FULFILL, post });
export const unLikeReject = (error: string) => ({ type: UNLIKE_REJECT, error });


export interface LikedState {
  posts: string[];
  updating: boolean;
  error?: string;
}

const initialState: LikedState = {
  posts: ['P000002', 'P000004', 'P000006'],
  updating: false,
  error: null,
};

export type LikedAction = {
  readonly type: LikedActionTypes;
  post?: string;
} & Partial<Pick<LikedState, 'posts' | 'error'>>;

export default function reducer(state = initialState, action: LikedAction) {
  switch (action.type) {
    case INITIAL_LIKES:
      return {
        ...state,
        posts: action.posts,
        updating: false,
        error: null,
      };

    case LIKE_DISPATCH:
      return {
        ...state,
        updating: true,
        error: null,
      };

    case LIKE_FULFILL:
      return {
        ...state,
        updating: false,
        posts: [...state.posts, action.post],
        error: null,
      };

    case LIKE_REJECT:
      return {
        ...state,
        updating: false,
        error: action.error,
      };
    case UNLIKE_DISPATCH:
      return {
        ...state,
        updating: true,
        error: null,
      };

    case UNLIKE_FULFILL:
      return {
        ...state,
        updating: false,
        posts: state.posts.filter(elem => elem !== action.post),
        error: null,
      };

    case UNLIKE_REJECT:
      return {
        ...state,
        updating: false,
        error: action.error,
      };

    default:
      return state;
  }
}
