import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en_GB from '../assets/translations/en_GB.json';
import en_US from '../assets/translations/en_US.json';
import ar_IQ from '../assets/translations/ar_IQ.json';
import ar_JO from '../assets/translations/ar_JO.json';
import da_DK from '../assets/translations/da_DK.json';
import de_DE from '../assets/translations/de_DE.json';
import es_ES from '../assets/translations/es_ES.json';
import et_EE from '../assets/translations/et_EE.json';
import fa_IR from '../assets/translations/fa_IR.json';
import fr_FR from '../assets/translations/fr_FR.json';
import fr_BE from '../assets/translations/fr_BE.json';
import fr_CA from '../assets/translations/fr_CA.json';
import it_IT from '../assets/translations/it_IT.json';
import iw_IL from '../assets/translations/iw_IL.json';
import ka_GE from '../assets/translations/ka_GE.json';
import ko_KR from '../assets/translations/ko_KR.json';
import nl_NL from '../assets/translations/nl_NL.json';
import nl_BE from '../assets/translations/nl_BE.json';
import pl_PL from '../assets/translations/pl_PL.json';
import ps_AF from '../assets/translations/ps_AF.json';
import ro_RO from '../assets/translations/ro_RO.json';
import uk_UA from '../assets/translations/uk_UA.json';
import pt_BR from '../assets/translations/pt_BR.json';
import lt_LT from '../assets/translations/lt_LT.json';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import dayjs from 'dayjs';

import AdvancedFormat from 'dayjs/plugin/advancedFormat';

import UpdateLocale from 'dayjs/plugin/updateLocale';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import RelativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(LocalizedFormat);
dayjs.extend(AdvancedFormat);
dayjs.extend(RelativeTime);
dayjs.extend(UpdateLocale);

dayjs.updateLocale('en', {
  formats: {
    L: 'MM/DD/YYYY',
  },
});

export const LANGUAGE_KEY = 'applicationLanguage';

export const languageConfig = {
  'en-GB': {
    label: 'English (UK)',
    translations: en_GB,
  },
  'en-US': { label: 'English (US)', translations: en_US },
  'ar-IQ': { label: 'Arabic (Iraq)', translations: ar_IQ },
  'ar-JO': { label: 'Arabic (Jordan)', translations: ar_JO },
  'da-DK': { label: 'Danish (Denmark)', translations: da_DK },
  'de-DE': { label: 'German (Germany)', translations: de_DE },
  'es-ES': { label: 'Spanish (Spain)', translations: es_ES },
  'et-EE': { label: 'Estonian (Estonia)', translations: et_EE },
  'fa-IR': { label: 'Persian/Farsi (Iran)', translations: fa_IR },
  'fr-FR': { label: 'French (France)', translations: fr_FR },
  'fr-CA': { label: 'French (Canada)', translations: fr_CA },
  'fr-BE': { label: 'French (Belgium)', translations: fr_BE },
  'it-IT': { label: 'Italian (Italy)', translations: it_IT },
  'he-IL': { label: 'Hebrew (Israel)', translations: iw_IL },
  'ka-GE': { label: 'Georgian (Georgia)', translations: ka_GE },
  'ko-KR': { label: 'Korean (South Korea)', translations: ko_KR },
  'nl-NL': { label: 'Dutch (Netherlands)', translations: nl_NL },
  'nl-BE': { label: 'Dutch (Belgium)', translations: nl_BE },
  'pl-PL': { label: 'Polish (Poland)', translations: pl_PL },
  'ps-AF': { label: 'Pashto (Afghanistan)', translations: ps_AF },
  'ro-RO': { label: 'Romanian (Romania)', translations: ro_RO },
  'uk-UA': { label: 'Ukrainian (Ukraine)', translations: uk_UA },
  'pt-BR': { label: 'Portuguese (Brazil)', translations: pt_BR },
  'lt-LT': { label: 'Lithuanian (Lithuania)', translations: lt_LT },
};

// Fallback to UK English
const fallbackLng = 'en-GB';

export const loadLanguage = async language => {
  try {
    await import(`dayjs/locale/${language.toLowerCase()}.js`);
    dayjs.locale(language.toLowerCase());
  } catch (error) {
    if (language.indexOf('-') > 0) {
      void loadLanguage(language.split('-')[0].toLowerCase());
    } else {
      dayjs.locale(fallbackLng.toLowerCase());
    }
  }
};

const languageDetector = {
  type: 'languageDetector',
  async: true, // Enables async language detection

  detect: async callback => {
    try {
      // Check for language preference in localStorage
      let savedLanguage = localStorage.getItem(LANGUAGE_KEY);
      if (savedLanguage) {
        void loadLanguage(savedLanguage);
        callback(savedLanguage);
        return;
      }

      const isNative = Capacitor.isNativePlatform();
      if (isNative) {
        const deviceLanguage = await Device.getLanguageTag();
        void loadLanguage(deviceLanguage.value);
        callback(deviceLanguage.value);
        return;
      }

      // Detect language from browser settings
      const browserLanguage = navigator.language || navigator.languages[0];
      if (browserLanguage) {
        void loadLanguage(browserLanguage);
        callback(browserLanguage);
        return;
      }
      void loadLanguage(fallbackLng);
      callback(fallbackLng);
    } catch (error) {
      void loadLanguage(fallbackLng);
      callback(fallbackLng);
    }
  },
  init: () => {
    // No initialization needed
  },
  cacheUserLanguage: lang => {
    localStorage.setItem(LANGUAGE_KEY, lang);
  },
  name: 'languageDetector',
};

export default i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: Object.entries(languageConfig).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: {
          translations: value.translations,
        },
      };
    }, {}),
    order: ['languageDetector'],
    fallbackLng,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
    },
  });

i18n.on('languageChanged', language => {
  void loadLanguage(language);
  document.body.dir = i18n.dir();
});
