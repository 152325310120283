import React from 'react';
import styles from './UpdateApp.module.scss';
import FullModal from '../FullModal/FullModal';
import Rocket from '../../assets/images/rocket.svg';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import Button from '../Button/Button';
import { AppUpdate } from '@capawesome/capacitor-app-update';

interface UpdateAppProps {
  cancelClick: () => void;
}

export const UpdateApp = ({ cancelClick }: UpdateAppProps) => (
  <FullModal className="" show>
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <img src={Rocket} />
        <div className={styles.title}>Time to update!</div>
        <div className={styles.desc}>
          The current version of the application is no longer supported. For a faster more enhanced
          experience please update the We Are Invictus App.
        </div>
      </div>
      <ButtonGroup>
        <Button
          label="Update now"
          onClick={() => {
            void AppUpdate.openAppStore();
          }}
        />
        <Button label="No, thanks!" secondary onClick={cancelClick} />
      </ButtonGroup>
    </div>
  </FullModal>
);
