import { FC, Fragment, useContext, useEffect, useRef, useState } from 'react';

import ActionSheet from '../ActionSheet/ActionSheet';
import { AuthContext } from '../../context/AuthProvider';
import Avatar from '../Avatar/Avatar';
import Button from '../Button/Button';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import { Capacitor } from '@capacitor/core';
import CommentForm from '../../forms/CommentForm/CommentForm';
import CommentList from '../CommentList/CommentList';
import DOMPurify from 'dompurify';
import { FEATURE_FLAGS } from '../../util/config';
import FlagForm from '../../forms/FlagForm/FlagForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HideShow from '../HideShow/HideShow';
import Img from '../Img/Img';
import { PostType } from '../../types/Post';
import { Preferences } from '@capacitor/preferences';
import RemovePostListItem from '../RemovePostListItem/RemovePostListItem';
import SavePostListItem from '../SavePostListItem/SavePostListItem';
import UIListItem from '../UIListItem/UIListItem';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import moment from 'moment';
import styles from './PostDetail.module.scss';
import useBoard from '../../hooks/useBoard';
import useGoogleTranslate from '../../hooks/useGoogleTranslate';
import usePostLike from '../../hooks/usePostLike';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../util/APIDjango';
import { Close } from '../Icons/Close';
import { Info } from '../Icons/Info';
import { DotMenu } from '../Icons/DotMenu';
import clsx from 'clsx';
import { Translate } from '../Icons/Translate';
import dayjs from 'dayjs';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { MentionNode } from '../MentionsEditor/MentionNode';

const initialConfig = {
  namespace: 'editor',
  nodes: [MentionNode],
  onError: (error: Error) => {
    throw error;
  },
};

export const LikedIcon = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="far"
    data-icon="heart"
    className="svg-inline--fa fa-heart"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512">
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="30px"
      d="M462.1 62.86C438.8 41.92 408.9 31.1 378.7 32c-37.49 0-75.33 15.4-103 43.98l-19.7 20.27l-19.7-20.27C208.6 47.4 170.8 32 133.3 32C103.1 32 73.23 41.93 49.04 62.86c-62.14 53.79-65.25 149.7-9.23 207.6l193.2 199.7C239.4 476.7 247.6 480 255.9 480c8.332 0 16.69-3.267 23.01-9.804l193.1-199.7C528.2 212.5 525.1 116.6 462.1 62.86z"
    />
  </svg>
);

export interface PostDetailProps {
  post: PostType;
  onClose: () => void;
  className: string;
  commentId?: string;
}

const PostDetail: FC<PostDetailProps> = ({ post = null, className, onClose, commentId }) => {
  // const { uid } = useContext(AuthContext);
  const [authState, setAuthState] = useAuth();
  const { uid }: { uid: number } = authState;

  const { translate, translated, translation, translationError } = useGoogleTranslate(
    post?.content,
  );
  const { board } = useBoard(post.boardId);
  const [translatablePost, setTranslatablePost] = useState<PostType>(post);
  const { toggleLiked, isLiked } = usePostLike(post.id);
  const [activeActionsheet, setActiveActionSheet] = useState<string>(null);
  const [activeFlagForm, setActiveFlagForm] = useState<string>(null);
  const { t } = useTranslation();
  const sanitizer = DOMPurify.sanitize;
  const usingTranslation = translatablePost.content === translation;
  const translatedMessage = usingTranslation ? t('translationByGoogle.label') : null;
  const [likeButtonLabel, setLabel] = useState<string>();
  const initiallyLiked = useRef<boolean>(isLiked);
  const scrollBoxRef = useRef<HTMLDivElement>();

  const [autoTranslateStatus, setAutoTranslateStatus] = useState(true);

  useEffect(() => {
    void Preferences.get({ key: 'autoTranslateStatus' }).then(result => {
      if (!result.value) {
        setAutoTranslateStatus(false);
      }
    });
  }, []);

  useEffect(() => {
    if (!translation && autoTranslateStatus) {
      handleTranslate();
    } else {
      setTranslatablePost({ ...post, content: translation });
    }
  }, [translation]);

  useEffect(() => {
    if (initiallyLiked.current && isLiked) {
      setLabel(String(post.likesCount));
    } else if (initiallyLiked.current && !isLiked) {
      setLabel(String(post.likesCount - 1));
    } else if (!initiallyLiked.current && isLiked) {
      setLabel(String(post.likesCount + 1));
    } else if (!initiallyLiked.current && !isLiked) {
      setLabel(String(post.likesCount ? post.likesCount : 0));
    }
  }, [isLiked, post.likesCount]);

  if (!post) {
    return null;
  }

  const {
    id,
    title,
    translatedTitle,
    author,
    created,
    image,
    video,
    isCommentAllowed,
    opportunityDetails,
  } = post;

  const wrapperClasses = clsx(
    styles.wrapper,
    className,
    !isCommentAllowed && styles.disabledCommnets,
  );

  const toggleActionSheet = (postId: string) => {
    if (postId === null) {
      setActiveFlagForm(null);
    }
    setActiveActionSheet(postId);
  };

  const handleTranslate = () => {
    if (!translated) {
      translate();
    } else if (usingTranslation) {
      setTranslatablePost(post);
    } else {
      setTranslatablePost({ ...post, content: translation });
    }
    toggleActionSheet(null);
  };

  const renderActionSheetContent = () => {
    const savePostListItem = <SavePostListItem postId={post?.id} />;
    if (author?.id === uid) {
      return (
        <Fragment>
          {FEATURE_FLAGS.savedPosts && savePostListItem}
          <RemovePostListItem postId={post?.id} />
        </Fragment>
      );
    }
    if (post?.postType === 'content' || post?.postType === 'opportunity') {
      return (
        <Fragment>
          {FEATURE_FLAGS.boards && board?.boardId && (
            <UIListItem
              to={`/boards/${board.boardId}`}
              icon={<FontAwesomeIcon icon={['fas', 'th-large']} />}>
              {t('viewBoard.label')}
            </UIListItem>
          )}
          {FEATURE_FLAGS.savedPosts && savePostListItem}
          {FEATURE_FLAGS.googleTranslate && (
            <UIListItem onClick={handleTranslate} icon={<Translate />}>
              {usingTranslation ? t('removeTranslation.label') : t('translatePost.label')}
            </UIListItem>
          )}

          {post?.postType === 'content' && (
            <Fragment>
              <UIListItem
                onClick={() => setActiveFlagForm(post?.id)}
                icon={<Info />}
                activeButton={activeFlagForm === post?.id}
                className={clsx(activeFlagForm === post?.id && styles.flagOpen)}>
                {t('flagBlock.label')}
              </UIListItem>
              <HideShow show={activeFlagForm === post?.id}>
                <FlagForm
                  author={post?.author}
                  id={post?.id}
                  type="post"
                  handleCancel={() => toggleActionSheet(null)}
                />
              </HideShow>
            </Fragment>
          )}
        </Fragment>
      );
    }

    return FEATURE_FLAGS.savedPosts ? savePostListItem : <UIListItem>No Options</UIListItem>;
  };

  return (
    <div className={wrapperClasses}>
      <div className={styles.meta}>
        <Avatar className={styles.avatar} src={author?.avatar?.src} alt={author?.username} />

        <div className={styles.titles}>
          <div className={styles.title}>
            {uid !== author?.id && autoTranslateStatus && translatedTitle ? translatedTitle : title}
          </div>
          <div className={styles.subtitle}>
            {author?.username} - {dayjs(created).from(dayjs())}
          </div>
        </div>
        <ButtonIcon title="Close" className={styles.close} onClick={onClose} icon={<Close />} />
      </div>
      <div className={styles.detail} ref={scrollBoxRef}>
        {video && (
          <VideoPlayer
            sources={[
              video.dash ? { src: video.dash.url, type: video.dash.mimeType } : undefined,
              video.hls ? { src: video.hls.url, type: video.hls.mimeType } : undefined,
              video.mp4 ? { src: video.mp4.url, type: video.mp4.mimeType } : undefined,
            ]}
            poster={video.thumbnail.url}
            controls
            autoplay={false}
          />
        )}
        {!video && image?.src && (
          <Img className={styles.image} src={image.src} alt={title} ratio={image.ratio} />
        )}
        <div className={styles.body}>
          <div className={styles.contentWrapper}>
            <div className={styles.contentMeta}>
              {FEATURE_FLAGS.boards && board && (
                <Avatar className={styles.avatar} src={board?.image?.src} alt={board?.name} />
              )}

              <div className={styles.boardMeta}>{FEATURE_FLAGS.boards && board?.name}</div>
              {FEATURE_FLAGS.likedPosts && (
                // Initial POC, hooked up to actual api
                <ButtonIcon
                  title="like"
                  onClick={() => toggleLiked()}
                  label={likeButtonLabel}
                  className={clsx(styles.icon, isLiked && styles.liked)}
                  icon={isLiked ? <LikedIcon /> : <FontAwesomeIcon icon={['far', 'heart']} />}
                />
              )}
              <div className={styles.options}>
                <ButtonIcon
                  title="Options"
                  onClick={() => toggleActionSheet(id)} // handleSave
                  className={styles.icon}
                  icon={<DotMenu />}
                />
              </div>
            </div>
            {post?.opportunityLocation && (
              <div className={styles.opportunityDetails}>
                <div className={styles.details}>
                  <div className={styles.label}>{t('location.label')}:</div>
                  {post?.opportunityLocation}
                </div>
                {post?.opportunityDate && (
                  <div className={styles.details}>
                    <div className={styles.label}>{t('date.label')}:</div>
                    {dayjs(post?.opportunityDate).format('dddd, Do MMMM YYYY')}
                  </div>
                )}
                {post?.opportunityDeadline && (
                  <div className={styles.details}>
                    <div className={styles.label}>{t('deadline.label')}:</div>
                    {dayjs(post?.opportunityDeadline).format('dddd, Do MMMM YYYY')}
                  </div>
                )}
              </div>
            )}
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: post?.translatedContent || sanitizer(translatablePost.content),
              }}
            />
          </div>
          {translationError && <div className={styles.translationError}>{translationError}</div>}
          {!translationError && translatedMessage && (
            <div className={styles.translatedMessage}>{translatedMessage}</div>
          )}
          {opportunityDetails && opportunityDetails.link && (
            <Fragment>
              <Button
                label={opportunityDetails.label || t('apply.label')}
                fullWidth
                link={opportunityDetails.link}
              />
            </Fragment>
          )}
          {opportunityDetails && opportunityDetails.email && (
            <Fragment>
              <Button
                label={opportunityDetails.label || t('apply.label')}
                fullWidth
                link={`mailto:${opportunityDetails.email}`}
              />
            </Fragment>
          )}
          {isCommentAllowed && (
            <LexicalComposer initialConfig={initialConfig}>
              <CommentForm postId={id} boardId={post.boardId} />
            </LexicalComposer>
          )}
          <CommentList postId={id} isEnabled={isCommentAllowed} />
        </div>
      </div>

      <ActionSheet
        show={activeActionsheet === post?.id}
        backDropClick={() => toggleActionSheet(null)}>
        {renderActionSheetContent()}
      </ActionSheet>
    </div>
  );
};

export default PostDetail;
