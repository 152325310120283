// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-ChatForm-module-form-GijuI{width:100%;padding:0px 16px;margin:24px auto 0;max-width:512px}.-ChatForm-module-form-GijuI .-ChatForm-module-container-iLFqH{display:flex;align-items:flex-start}.-ChatForm-module-form-GijuI .-ChatForm-module-container-iLFqH .-ChatForm-module-field-WAnHU{box-sizing:border-box;cursor:text;background-color:#313131;line-height:25px;margin:16px 0;position:relative;display:block;font-weight:400;width:100%;border-radius:8px;border:1px solid #eceff1;padding:8px 16px;font-size:1rem;color:#666;resize:none;outline:none;appearance:none;-webkit-appearance:none;margin-bottom:0px}.-ChatForm-module-form-GijuI .-ChatForm-module-container-iLFqH ::placeholder{font-style:italic}.-ChatForm-module-form-GijuI .-ChatForm-module-container-iLFqH .-ChatForm-module-submit-Mw1LQ{color:#78909c}.-ChatForm-module-form-GijuI .-ChatForm-module-container-iLFqH .-ChatForm-module-submitChat-OTIS4{width:56px}`, "",{"version":3,"sources":["webpack://./src/forms/ChatForm/ChatForm.module.scss","webpack://./src/theme/_colors.scss","webpack://./src/theme/_dimensions.scss"],"names":[],"mappings":"AAGA,6BACE,UAAA,CACA,gBAAA,CACA,kBAAA,CACA,eAAA,CAEA,+DACE,YAAA,CACA,sBAAA,CAEA,6FACE,qBAAA,CACA,WAAA,CACA,wBC6BU,CD5BV,gBAAA,CACA,aAAA,CACA,iBAAA,CACA,aAAA,CACA,eAAA,CACA,UAAA,CACA,iBElBQ,CFmBR,wBAAA,CACA,gBAAA,CACA,cAAA,CACA,UCDmB,CDEnB,WAAA,CACA,YAAA,CACA,eAAA,CACA,uBAAA,CACA,iBAAA,CAGF,6EACE,iBAAA,CAGF,8FACE,aCtBK,CDyBP,kGACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `-ChatForm-module-form-GijuI`,
	"container": `-ChatForm-module-container-iLFqH`,
	"field": `-ChatForm-module-field-WAnHU`,
	"submit": `-ChatForm-module-submit-Mw1LQ`,
	"submitChat": `-ChatForm-module-submitChat-OTIS4`
};
export default ___CSS_LOADER_EXPORT___;
