import { ENVIRONMENT } from './config';

export enum LogType {
  ERROR = 'error',
  WARNING = 'warn',
  LOG = 'log',
}

export const log = (message: string | string[], status: LogType = LogType.LOG): void => {
  if (ENVIRONMENT !== 'production') {
    let msgArr = typeof message === 'string' ? [message] : message
    console[status](...msgArr);
  }

  return null;
};
