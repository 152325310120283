/* eslint-disable @typescript-eslint/no-unsafe-return */

import { catchError, map, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import {
  GET_POST_CATEGORIES_DISPATCH,
  getPostCategoriesFulfill,
  getPostCategoriesReject,
} from '../modules/postCategories';
import api from '../../util/api';

export const postCategoriesGetAllEpic = action$ =>
  action$.pipe(
    ofType(GET_POST_CATEGORIES_DISPATCH),
    mergeMap(() => {
      const apiCall = api.getPostCategories();

      return from(apiCall).pipe(
        map(savedPostCategories => getPostCategoriesFulfill(savedPostCategories)),
        catchError(error => of(getPostCategoriesReject(error))),
      );
    }),
  );

export default combineEpics(postCategoriesGetAllEpic);
