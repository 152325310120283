/**
 * listens for events caused by a native notification being tapped.
 */

import { Dispatch } from "redux";
import { updateNotificationRead } from "../modules/notifications";


export const notificationReadListener = (dispatch: Dispatch) => {
  document.addEventListener('pushNotificationRead', (e: CustomEvent) => {
    const notificationId = e.detail?.notificationId;
    if (notificationId) {
      dispatch(updateNotificationRead([notificationId]))
    }
  });
};