import { FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SelectField.module.scss';
import SelectList from 'react-select';
import { FieldProps } from 'formik';
import clsx from 'clsx';
import { OptionItem } from './OptionItem';
import { SingleValue } from './SingleValue';
import FieldStatusBlock from '../FieldStatusBlock/FieldStatusBlock';

export interface SelectFieldItem {
  key: string | number;
  description: string;
}

export interface SelectFieldProps {
  className?: string;
  name: string;
  value: string;
  label: string;
  items?: SelectFieldItem[] | Map<string | number, string>;
  errorText: string;
  disabled?: boolean;
  touched: boolean;
  required?: boolean;
  numVal?: boolean;
  onChange: (value: string | number) => void;
  onBlur?: (e: FocusEvent) => void;
  onFocus?: (e: FocusEvent) => void;
}

export interface Option {
  label: string;
  value: string;
  icon?: string;
}

interface FormikSelectProps {
  options: Option[];
  placeholder?: string;
  className?: string;
  onChange?: (value: Option) => void;
  error?: string;
}

export const FormikSelect: React.FC<FieldProps & FormikSelectProps> = ({
  options,
  field,
  form,
  placeholder,
  className,
  error,
  onChange,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <div className={clsx(styles.select, className, !!error && styles.error)}>
      <SelectList
        options={options}
        placeholder={placeholder || t('select.label')}
        name={field.name}
        onChange={(option: Option) => {
          void form.setFieldValue(field.name, option, true).then(() => {
            void form.validateField(field.name);
          });

          onChange && onChange(option);
        }}
        onBlur={field.onBlur}
        classNamePrefix="invictus"
        isSearchable={false}
        components={{
          Option: OptionItem,
          SingleValue: SingleValue,
        }}
        value={field.value}
        {...rest}
      />
      <FieldStatusBlock touched={!!error} error={error} />
    </div>
  );
};
