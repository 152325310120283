import { Profile } from './../../types/Profile';
export const DISPATCH = 'WIS/user/profile/DISPATCHED';
export const FULFILL = 'WIS/user/profile/FULFILLED';
export const REJECT = 'WIS/user/profile/REJECTED';

export const UPDATE_INTERESTS_DISPATCHED =
  'WIS/user/profile/UPDATE_INTERESTS_DISPATCHED';
export const UPDATE_INTERESTS_FULFILLED =
  'WIS/user/profile/UPDATE_INTERESTS_FULFILLED';
export const UPDATE_INTERESTS_REJECTED =
  'WIS/user/profile/UPDATE_INTERESTS_REJECTED';

export type ProfileActionTypes =
  | 'WIS/user/profile/DISPATCHED'
  | 'WIS/user/profile/FULFILLED'
  | 'WIS/user/profile/REJECTED'
  | 'WIS/user/profile/UPDATE_INTERESTS_DISPATCHED'
  | 'WIS/user/profile/UPDATE_INTERESTS_FULFILLED'
  | 'WIS/user/profile/UPDATE_INTERESTS_REJECTED';

export const dispatch = () => ({ type: DISPATCH });
export const fulfill = (profile: ProfileState['profile']) => ({
  type: FULFILL,
  profile,
});
export const reject = (error: ProfileState['error']) => ({
  type: REJECT,
  error,
});

export const updateInterestsDispatch = (
  interests: Profile['opportunityInterest'],
) => ({ type: UPDATE_INTERESTS_DISPATCHED, interests });
export const updateInterestsFulfill = (
  interests: Profile['opportunityInterest'],
) => ({ type: UPDATE_INTERESTS_FULFILLED, interests });
export const updateInterestsReject = (error: ProfileState['error']) => ({
  type: UPDATE_INTERESTS_REJECTED,
  error,
});

export interface ProfileState {
  profile: Profile;
  isFetching: boolean;
  isFetched: boolean;
  error?: string;
  isUpdating: boolean;
}

const initialState: ProfileState = {
  profile: {},
  isFetching: false,
  isFetched: false,
  isUpdating: false,
  error: null,
};

export type ProfileAction = {
  readonly type: ProfileActionTypes;
  interests?: Profile['opportunityInterest'];
} & Pick<ProfileState, 'error' | 'profile'>;

export default function reducer(state = initialState, action: ProfileAction) {
  switch (action.type) {
    case DISPATCH:
      return {
        ...state,
        isFetching: true,
      };

    case FULFILL:
      return {
        ...state,
        profile: action.profile,
        isFetching: false,
        isFetched: true,
        error: null,
      };

    case REJECT:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };

    case UPDATE_INTERESTS_DISPATCHED:
      return {
        ...state,
        isUpdating: true,
      };

    case UPDATE_INTERESTS_FULFILLED:
      return {
        ...state,
        isUpdating: false,
        profile: {
          ...state.profile,
          opportunityInterest: action.interests,
        },
      };

    case UPDATE_INTERESTS_REJECTED:
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };

    default:
      return state;
  }
}
