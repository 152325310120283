import React from 'react';

export const Back = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.725 12.4879L17.075 19.8379C17.325 20.0879 17.4458 20.3796 17.4375 20.7129C17.4292 21.0462 17.3 21.3379 17.05 21.5879C16.8 21.8379 16.5083 21.9629 16.175 21.9629C15.8417 21.9629 15.55 21.8379 15.3 21.5879L7.6 13.9129C7.4 13.7129 7.25 13.4879 7.15 13.2379C7.05 12.9879 7 12.7379 7 12.4879C7 12.2379 7.05 11.9879 7.15 11.7379C7.25 11.4879 7.4 11.2629 7.6 11.0629L15.3 3.3629C15.55 3.1129 15.8458 2.99207 16.1875 3.0004C16.5292 3.00874 16.825 3.1379 17.075 3.3879C17.325 3.6379 17.45 3.92957 17.45 4.2629C17.45 4.59624 17.325 4.8879 17.075 5.1379L9.725 12.4879Z"
      fill="#EEEEEE"
    />
  </svg>
);
