import { AuthorType } from "../../types/Author";

export const BOARD_GET_ALL_DISPATCH = 'WIS/boards/BOARD_GET_ALL_DISPATCHED';
export const BOARD_GET_ALL_FULFILL = 'WIS/boards/BOARD_GET_ALL_FULFILLED';
export const BOARD_GET_ALL_REJECT = 'WIS/boards/BOARD_GET_ALL_REJECTED';

export type BoardActionTypes = 
  'WIS/boards/BOARD_GET_ALL_DISPATCHED'
  | 'WIS/boards/BOARD_GET_ALL_FULFILLED'
  | 'WIS/boards/BOARD_GET_ALL_REJECTED';

export const boardGetAllDispatch = () => ({ type: BOARD_GET_ALL_DISPATCH });
export const boardGetAllFulfill = (boards: BoardState['boards']) => ({ type: BOARD_GET_ALL_FULFILL, boards });
export const boardGetAllReject = (error: string) => ({ type: BOARD_GET_ALL_REJECT, error });

export interface BoardState {
  boards: Partial<{
    boardId: number;
    country: number;
    created: string;
    description: string;
    image: {
      author: AuthorType;
      created: string;
      id: number;
      lastModified: string;
      src: string;
    };
    isDefaultForUser: boolean;
    isGlobal: boolean;
    lastModified: string;
    lastPost: string;
    name: string;
    recentPostCount: number;
    writableByUser: boolean;
  }>[];
  isFetching: boolean;
  isFetched: boolean;
  lastFetched: string;
  fetchError: string;
}

const initialState: BoardState = {
  boards: [],
  isFetching: false,
  isFetched: false,
  lastFetched: null,
  fetchError: null,
};

export type BoardAction = { readonly type: BoardActionTypes; error?: string } & Partial<Pick<BoardState, 'boards'>>;

export default function reducer(state = initialState, action: BoardAction) {
  switch (action.type) {
    case BOARD_GET_ALL_DISPATCH:
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        fetchError: null,
      };
    case BOARD_GET_ALL_FULFILL:
      return {
        ...state,
        boards: action.boards,
        isFetching: false,
        isFetched: true,
        fetchError: null,
      };
    case BOARD_GET_ALL_REJECT:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        fetchError: action.error,
      };

    default:
      return state;
  }
}
