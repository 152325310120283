import React, { Fragment, useCallback, useState, useEffect, FC, ChangeEvent } from 'react';
import { Capacitor } from '@capacitor/core';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldLabel from '../FieldLabel/FieldLabel';
import FieldStatusBlock from '../FieldStatusBlock/FieldStatusBlock';
import styles from './DropZoneField.module.scss';

const { getPlatform } = Capacitor;
const platform = getPlatform();

export interface DropZoneVideoFieldProps {
  acceptedMimeTypes?: string[];
  maxUploadSize?: number;
  icon?: React.ReactNode;
  name: string;
  label: string;
  value: File;
  required?: boolean;
  errorText?: string;
  focused?: boolean;
  touched?: boolean;
  onChange: ({ file }: { file: File }) => void;
}

const DropZoneVideoField: FC<DropZoneVideoFieldProps> = ({
  acceptedMimeTypes,
  // maxUploadSize,
  maxUploadSize = 10485760, // 10mb
  icon = <FontAwesomeIcon icon={['fas', 'file-upload']} />,
  name,
  label,
  required = false,
  onChange,
  errorText,
  focused = false,
  touched = false,
  value,
}) => {
  const { t } = useTranslation();
  const [videoData, setVideoData] = useState(null);
  const [videoName, setVideoName] = useState(null);

  useEffect(() => {
    if (!value) {
      setVideoData(null);
      setVideoName(null);
    }
  }, [value]);

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: File[]) => {
    if (rejectedFiles.length) {
      // TODO Display error when unsupported files added.
      rejectedFiles.forEach(function (file: File) {
        this.errorMessage = `${file.name} ${t('fileTooLarge.label')}`;
        // show error message
      });
      return;
    }
    const reader = new FileReader();
    reader.onload = e => setVideoData(e.target.result);
    reader.readAsDataURL(acceptedFiles[0]);
    setVideoName(acceptedFiles[0].name);
    onChange({ file: acceptedFiles[0] });
  }, []);

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const reader = new FileReader();
    reader.onload = e => setVideoData(e.target.result);
    reader.readAsDataURL(files[0]);
    setVideoName(files[0].name);
    onChange({ file: files[0] });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: acceptedMimeTypes.toString(),
    maxSize: maxUploadSize,
  });

  const removeVideo = () => {
    setVideoData(null);
    setVideoName(null);
  };

  return (
    <Fragment>
      <FieldLabel label={videoName ? `${label} (${videoName})` : label} required={required} />
      <FieldStatusBlock error={errorText} focused={focused} touched={touched}>
        {platform === 'ios' ? (
          <div className={styles.trigger}>
            <label
              htmlFor="videoUpload"
              className={styles.fileThumb}
              style={videoData ? { backgroundImage: `url(${videoData})` } : null}>
              <input
                id="videoUpload"
                name={name}
                type="file"
                accept={acceptedMimeTypes.toString()}
                onChange={onFileChange}
                className={styles.input}
              />
              {!videoData && (
                <FontAwesomeIcon icon={['fas', 'video']} style={{ pointerEvents: 'none' }} />
              )}
            </label>
          </div>
        ) : (
          <div
            {...getRootProps({
              className: styles.trigger,
            })}>
            <input
              {...getInputProps({
                name,
              })}
            />
            <div className={styles.fileThumb}>
              {!videoData ? icon : <img src={videoData} alt="Video Thumbnail" />}
            </div>
          </div>
        )}
        {videoData && (
          <div className={styles.clearText}>
            <a onClick={removeVideo}>{t('clear.label')}</a>
          </div>
        )}
      </FieldStatusBlock>
    </Fragment>
  );
};

export default DropZoneVideoField;
