import { FC, ChangeEvent, FocusEvent, KeyboardEvent } from 'react';
import styles from './CheckboxButton.module.scss';

export interface CheckboxButtonProps {
  id?: string;
  checked: boolean;
  disabled?: boolean;
  label: string;
  value: string | number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
}

const handleKeyUp = (e: KeyboardEvent<HTMLSpanElement>) => {
  if (e.keyCode === 13 || e.keyCode === 32) {
    (e.target as HTMLElement).click();
  }
};

const CheckboxButton: FC<CheckboxButtonProps> = ({
  checked,
  label,
  value,
  onChange,
  onBlur,
  id,
  disabled = false,
}) => {
  const labelStyles = [styles.button, checked && styles.active, disabled && styles.disabled].join(
    ' ',
  );

  return (
    <div className={styles.wrapper}>
      <input
        className={styles.checkbox}
        id={id}
        type="checkbox"
        value={value}
        checked={!!value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
      <label htmlFor={id} tabIndex={0} onKeyUp={handleKeyUp} className={labelStyles}>
        {label}
      </label>
    </div>
  );
};

export default CheckboxButton;
