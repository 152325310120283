import React, { useEffect, useState } from 'react';
import {
  updateLanguagesDispatch,
  updateLanguagesFulfill,
  updateLanguagesReject,
} from '../../redux/modules/autoTranslate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Preferences } from '@capacitor/preferences';
import api from '../../util/api';
// import { getChatRoomsDispatch } from '../../redux/modules/chat';
import { getPostsDispatch } from '../../redux/modules/post';
import { dispatch as getProfile } from '../../redux/modules/profile';
import styles from './LanguageSelector.module.scss';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useChatRooms } from '../../util/APIDjango';
import { languageConfig } from '../../util/i18n';
import { useAnalitycs } from '../../hooks/useAnalitycs';
import { isNative } from '../../util/isNative';

const LanguageSelector = ({ context }) => {
  const { i18n } = useTranslation('', { useSuspense: false });
  const [language, setLanguage] = useState(i18n.language);
  const dispatch = useDispatch();
  const analitycs = useAnalitycs();
  const [autoTranslateStatus, setAutoTranslateStatus] = useState(true);
  const [chatRoomsData, setChatRoomsData] = useChatRooms();

  useEffect(() => {
    void Preferences.get({ key: 'autoTranslateStatus' }).then(result => {
      if (!result.value) {
        setAutoTranslateStatus(false);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(getProfile());
  }, [language]);

  useEffect(() => {
    const handleLanguageChanged = lang => {
      analitycs.logEvent('language_change', {
        previousLanguage: language,
        newLanguage: lang,
        context: isNative ? context : window.location.hash,
      });
      setLanguage(lang);
    };
    i18n.on('languageChanged', handleLanguageChanged);
    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, [analitycs, i18n, language, context]);

  const changeLanguage = e => {
    i18n.changeLanguage(e.target.value).catch(() => {
      console.error('Failed to change language');
    });

    handleLanguagePreference(
      {
        language: e.target.value,
        autoTranslatePreference: autoTranslateStatus,
      },
      { setSubmitting: () => {}, setStatus: () => {} },
    );
  };

  const getChatRooms = () =>
    setChatRoomsData({ ...chatRoomsData, isFetched: false, isFetching: true });

  const handleLanguagePreference = (values, { setSubmitting }) => {
    setSubmitting(true);
    dispatch(updateLanguagesDispatch(values));
    api
      .autoTranslate(values)
      .then(response => {
        dispatch(updateLanguagesFulfill(response));
        dispatch(getPostsDispatch(null, null, null, 0, 10, true));
        getChatRooms();
      })
      .catch(error => {
        dispatch(updateLanguagesReject(error.message));
      });
  };
  return (
    <div className={styles.wrapper}>
      <FontAwesomeIcon icon={['fas', 'globe-europe']} />
      <select className={styles.lngSelect} onChange={changeLanguage} value={language}>
        {Object.entries(languageConfig).map(([key, value]) => (
          <option key={key} value={key}>
            {value.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSelector;
