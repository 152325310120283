import { FirebaseAnalytics, SetUserPropertyOptions } from '@capacitor-firebase/analytics';
import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { isNative } from 'lodash';

export const logAnalitycs = (event: string, eventProps?: Record<string, unknown>) => {
  if (isNative) {
    void FirebaseAnalytics.logEvent({
      name: event,
      params: eventProps,
    });
  } else {
    const analytics = getAnalytics();
    logEvent(analytics, event, eventProps);
  }
};

export const useAnalitycs = () => {
  if (isNative) {
    return {
      logEvent: logAnalitycs,
      setUserId: (userId: string) => FirebaseAnalytics.setUserId({ userId }),
      setUserProperties: (props: SetUserPropertyOptions) => {
        void FirebaseAnalytics.setUserProperty(props);
      },
    };
  }
  return {
    logEvent: logAnalitycs,
    setUserId,
    setUserProperties,
  };
};
