// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/app-bg.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/images/tablet-app-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{background-color:#111}#app.fullscreen{overflow:hidden}#app.fullscreen.chatPage{height:100vh}.-Layout-module-wrapper-I8L3P{display:flex;flex-direction:column;min-height:100vh;background-color:#111;padding-top:env(safe-area-inset-top, 16px);padding-bottom:calc(76px + env(safe-area-inset-bottom, 16px))}.-Layout-module-wrapper-I8L3P .-Layout-module-main-zwrmB{padding:16px;max-width:512px;margin:0 auto;flex-grow:1;width:100%;position:relative;width:100%;background-color:#111;background-repeat:no-repeat;background-position:right bottom;color:#eee}.-Layout-module-wrapper-I8L3P.-Layout-module-chat-u5R7F{background-color:#111}.-Layout-module-wrapperChat-ktH6i{background-color:#111}.-Layout-module-wrapperChat-ktH6i .-Layout-module-main-zwrmB{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___})}@media only screen and (min-width: 768px){.-Layout-module-wrapperChat-ktH6i .-Layout-module-main-zwrmB{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_1___});background-position:center center}}`, "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAGA,KACE,qBCmCc,CDhChB,gBACE,eAAA,CAEF,yBACE,YAAA,CAGF,8BACE,YAAA,CACA,qBAAA,CACA,gBAAA,CACA,qBCqBc,CDpBd,0CAAA,CACA,6DAAA,CAEA,yDACE,YAAA,CACA,eAAA,CACA,aAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CACA,UAAA,CACA,qBCSY,CDRZ,2BAAA,CACA,gCAAA,CACA,UCkBW,CDfb,wDACE,qBCEY,CDEhB,kCACE,qBCHc,CDId,6DACI,wDAAA,CAEA,0CAHJ,6DAIM,wDAAA,CACA,iCAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-Layout-module-wrapper-I8L3P`,
	"main": `-Layout-module-main-zwrmB`,
	"chat": `-Layout-module-chat-u5R7F`,
	"wrapperChat": `-Layout-module-wrapperChat-ktH6i`
};
export default ___CSS_LOADER_EXPORT___;
