// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-ActionButtonGroup-modules-actionButtonGroup-yuxNF{display:flex;flex-direction:row;border-top:1px solid #cfd8dc;margin-top:16px}.-ActionButtonGroup-modules-actionButtonGroup-yuxNF button{background-color:rgba(0,0,0,0);box-shadow:none;flex:1;padding:16px;border:none;border-right:1px solid #cfd8dc;font-size:14px}.-ActionButtonGroup-modules-actionButtonGroup-yuxNF button:last-child{border-right:none}.-ActionButtonGroup-modules-actionButtonGroup-yuxNF button:hover{background-color:rgba(0,0,0,.1)}`, "",{"version":3,"sources":["webpack://./src/components/ActionButtonGroup/ActionButtonGroup.modules.scss","webpack://./src/theme/_dimensions.scss"],"names":[],"mappings":"AAGA,oDACE,YAAA,CACA,kBAAA,CACA,4BAAA,CACA,eCDO,CDGP,2DACE,8BAAA,CACA,eAAA,CACA,MAAA,CACA,YAAA,CACA,WAAA,CACA,8BAAA,CACA,cAAA,CAEA,sEACE,iBAAA,CAGF,iEAAA,+BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionButtonGroup": `-ActionButtonGroup-modules-actionButtonGroup-yuxNF`
};
export default ___CSS_LOADER_EXPORT___;
