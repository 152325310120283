import { SetStateAction, useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';

import Loader from '../Loader/Loader';
import { Preferences } from '@capacitor/preferences';
// import { RootState } from '../../redux/modules/root';
// import { getChatRoomsDispatch } from '../../redux/modules/chat';
import s from './ChatRoomList.module.scss';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import api from '../../util/api';
import { useChatRooms, useMessages } from '../../util/APIDjango';
import ChatModal from '../ChatModal/ChatModal';
import { PageTitle } from '../PageTitle/PageTitle';

// const mapStateToProps = ({ chat }: RootState) => ({
//   rooms: chat.rooms,
//   loading: chat.isFetching,
//   error: chat.fetchError,
// });

/**
 * Lists all available Chat Rooms
 * @returns {JSX.Element}
 */
const ChatRoomList = (): JSX.Element => {
  // const { rooms, loading, error } = useSelector(mapStateToProps);

  const [chatRoomsData, setChatRoomsData] = useChatRooms();
  const { fetchError, isFetching, rooms } = chatRoomsData;
  const { chatId } = useParams<{ chatId?: string }>();

  const history = useHistory();
  const { t } = useTranslation();
  // const dispatch = useDispatch();

  const [autoTranslateStatus, setAutoTranslateStatus] = useState(true);
  const { i18n } = useTranslation('', { useSuspense: false });
  const [language, setLanguage] = useState(i18n.language);

  const [messagesData, setMessagesData] = useMessages();
  const { messagesByRoom } = messagesData;
  const { likedByMe, results } = messagesByRoom;

  const getChatRooms = () =>
    setChatRoomsData({ ...chatRoomsData, isFetched: false, isFetching: true });
  const getChatRoomsFulfil = (data: any) =>
    setChatRoomsData({
      ...chatRoomsData,
      rooms: data,
      isFetching: false,
      isFetched: true,
      fetchError: null,
    });

  const getChatRoomsReject = (error: string) =>
    setChatRoomsData({ ...chatRoomsData, fetchError: error, isFetching: false });

  useEffect(() => {
    void Preferences.get({ key: 'autoTranslateStatus' }).then(result => {
      if (!result.value) {
        setAutoTranslateStatus(false);
      }
    });

    // dispatch(getChatRoomsDispatch());
    getChatRooms();
    api
      .chatRooms()
      .then(chatRoomsData => {
        getChatRoomsFulfil(chatRoomsData);
      })
      .catch(err => {
        getChatRoomsReject(err.message);
      });
  }, []);

  useEffect(() => {
    const handleLanguageChanged = (lang: SetStateAction<string>) => {
      setLanguage(lang);
    };
    i18n.on('languageChanged', handleLanguageChanged);
    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, [i18n]);

  if (isFetching) {
    return <Loader />;
  }

  const resetChatMessages = () => {
    setMessagesData({
      fetchError: null,
      isFetched: false,
      isFetching: false,
      lastMessageIdFetched: null,
      messagesByRoom: {
        likedByMe: [],
        next: null,
        previous: null,
        results: [],
      },
      messagesFetched: false,
      messagesFetching: false,
    });
  };

  return (
    <div>
      <>
        {fetchError && <p className={s.error}>{fetchError}</p>}
        <PageTitle title={t('chat.label')} />
        <h2 className={s.subtitle}>{t('weAreInvictusChatRooms.label')}</h2>
        <ul className={s.chatList}>
          {rooms &&
            rooms.map(
              room =>
                room?.id && (
                  <li
                    className={s.chatListItem}
                    key={room.id}
                    onClick={() => {
                      history.push(`/chat/${room?.id}`);
                      resetChatMessages();
                    }}>
                    <div className={s.titles}>
                      <h2 className={s.name}>
                        {language !== 'en' && autoTranslateStatus && room?.translatedName
                          ? room?.translatedName
                          : room.name}
                      </h2>
                      <h3 className={s.description}>
                        {language !== 'en' && autoTranslateStatus && room?.translatedDescription
                          ? room?.translatedDescription
                          : room.description}
                      </h3>
                    </div>
                  </li>
                ),
            )}
        </ul>
      </>
      {chatId && <ChatModal chatId={Number(chatId)} />}
    </div>
  );
};

export default ChatRoomList;
