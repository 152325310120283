import React, { FC, useEffect } from 'react';

import editorStyles from './MentionsEditorChat.module.scss';
import { useTranslation } from 'react-i18next';
import Editor from './Editor';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { RootNode, $getSelection, $isRangeSelection } from 'lexical';
import FieldStatusBlock from '../FieldStatusBlock/FieldStatusBlock';

interface MentionsEditorProps {
  id?: string;
  boardId: string;
  disabled: boolean;
  placeholder: string;
  setCurrentLength: (newLength: number) => void;
  currentLength: number;
  maxLength: number;
}

const MentionsEditor: FC<MentionsEditorProps> = ({
  boardId,
  disabled,
  placeholder,
  currentLength,
  setCurrentLength,
  maxLength,
}) => {
  const { t } = useTranslation();

  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerNodeTransform(RootNode, (rootNode: RootNode) => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
        return;
      }

      const textContentSize = rootNode.getTextContentSize();
      setCurrentLength(textContentSize);
    });
  }, [editor, maxLength]);

  return (
    <div className={editorStyles.editorWrapper}>
      <div
        className={editorStyles.editor}
        style={{ maxWidth: '100%' }}
        onClick={() => {
          editor.focus();
        }}>
        <Editor placeholder={disabled ? t('noCommentsAllowed.label') : String(placeholder)} />
      </div>
      <FieldStatusBlock
        touched={!!currentLength}
        error={currentLength >= maxLength ? t('tooLongText.label', { length: maxLength }) : null}
      />
      <div className={editorStyles.helperText}>
        {currentLength} / 1000 {t('characters.label')}
      </div>
    </div>
  );
};

export default MentionsEditor;
