// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-DropZoneField-module-trigger-Rh8zD{width:100px;outline:none;background-color:rgba(0,0,0,0);border:none;padding:0;margin:0}.-DropZoneField-module-fileThumb-f8__n{position:relative;width:100px;height:100px;margin:12px 0px;color:#78909c;font-size:2rem;text-align:center;display:flex;align-items:center;justify-content:center;background-color:#cfd8dc;background-repeat:no-repeat;background-position:center;background-size:contain;transition:background-color .25s ease;border:none;object-fit:contain}.-DropZoneField-module-fileThumb-f8__n img{height:100%;max-width:unset}.-DropZoneField-module-fileThumb-f8__n:hover{background-color:#b0bec5}.-DropZoneField-module-fileThumb-f8__n .-DropZoneField-module-input-bntjX{visibility:hidden;width:100px;height:100px;position:absolute}.-DropZoneField-module-clearText-n5Wht{display:flex;justify-content:left;margin-bottom:4px;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/DropZoneVideoField/DropZoneField.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAGA,qCACE,WAAA,CACA,YAAA,CACA,8BAAA,CACA,WAAA,CACA,SAAA,CACA,QAAA,CAGF,uCACE,iBAAA,CACA,WAAA,CACA,YAAA,CACA,eAAA,CACA,aCCS,CAAA,cAAA,CDCT,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,wBCRS,CDST,2BAAA,CACA,0BAAA,CACA,uBAAA,CACA,qCAAA,CACA,WAAA,CACA,kBAAA,CAEA,2CACE,WAAA,CACA,eAAA,CAGF,6CACE,wBCrBO,CDwBT,0EACE,iBAAA,CACA,WAAA,CACA,YAAA,CACA,iBAAA,CAIJ,uCACE,YAAA,CACA,oBAAA,CACA,iBAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trigger": `-DropZoneField-module-trigger-Rh8zD`,
	"fileThumb": `-DropZoneField-module-fileThumb-f8__n`,
	"input": `-DropZoneField-module-input-bntjX`,
	"clearText": `-DropZoneField-module-clearText-n5Wht`
};
export default ___CSS_LOADER_EXPORT___;
