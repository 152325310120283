// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-AutoTranslateDisclaimer-module-wrapper-MtwFs{align-items:center}.-AutoTranslateDisclaimer-module-wrapper-MtwFs .-AutoTranslateDisclaimer-module-cardcontent-xO_00{display:flex;flex-direction:column;padding-top:12px;gap:12px}.-AutoTranslateDisclaimer-module-wrapper-MtwFs .-AutoTranslateDisclaimer-module-cardcontent-xO_00 .-AutoTranslateDisclaimer-module-checkbox-_e1KL{display:flex;gap:4px;flex-direction:row}.-AutoTranslateDisclaimer-module-wrapper-MtwFs .-AutoTranslateDisclaimer-module-textbody-NxKYu{color:#b3b3b3;font-size:17px;font-style:normal;font-weight:400;line-height:22px;text-align:left}`, "",{"version":3,"sources":["webpack://./src/components/AutoTranslateDisclaimer/AutoTranslateDisclaimer.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAEA,+CACE,kBAAA,CAEA,kGACE,YAAA,CACA,qBAAA,CACA,gBAAA,CACA,QAAA,CAEA,kJACE,YAAA,CACA,OAAA,CACA,kBAAA,CAIJ,+FACE,aCiCa,CDhCb,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-AutoTranslateDisclaimer-module-wrapper-MtwFs`,
	"cardcontent": `-AutoTranslateDisclaimer-module-cardcontent-xO_00`,
	"checkbox": `-AutoTranslateDisclaimer-module-checkbox-_e1KL`,
	"textbody": `-AutoTranslateDisclaimer-module-textbody-NxKYu`
};
export default ___CSS_LOADER_EXPORT___;
