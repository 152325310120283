import React from 'react';

export const Play = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_1329_2136"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="64"
      height="64">
      <rect width="64" height="64" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1329_2136)">
      <path
        d="M28.3997 41.9999L41.3997 33.6666C42.0219 33.2666 42.333 32.711 42.333 31.9999C42.333 31.2888 42.0219 30.7332 41.3997 30.3333L28.3997 21.9999C27.733 21.5555 27.0552 21.5221 26.3663 21.8999C25.6775 22.2777 25.333 22.8666 25.333 23.6666V40.3332C25.333 41.1333 25.6775 41.7221 26.3663 42.0999C27.0552 42.4777 27.733 42.4444 28.3997 41.9999ZM31.9997 58.6666C28.3108 58.6666 24.8441 57.9666 21.5997 56.5666C18.3552 55.1666 15.533 53.2666 13.133 50.8666C10.733 48.4666 8.83301 45.6444 7.43301 42.3999C6.03301 39.1555 5.33301 35.6888 5.33301 31.9999C5.33301 28.311 6.03301 24.8444 7.43301 21.5999C8.83301 18.3555 10.733 15.5333 13.133 13.1333C15.533 10.7333 18.3552 8.83325 21.5997 7.43325C24.8441 6.03325 28.3108 5.33325 31.9997 5.33325C35.6886 5.33325 39.1552 6.03325 42.3997 7.43325C45.6441 8.83325 48.4663 10.7333 50.8663 13.1333C53.2663 15.5333 55.1663 18.3555 56.5663 21.5999C57.9663 24.8444 58.6663 28.311 58.6663 31.9999C58.6663 35.6888 57.9663 39.1555 56.5663 42.3999C55.1663 45.6444 53.2663 48.4666 50.8663 50.8666C48.4663 53.2666 45.6441 55.1666 42.3997 56.5666C39.1552 57.9666 35.6886 58.6666 31.9997 58.6666Z"
        fill="white"
        fill-opacity="0.9"
      />
    </g>
  </svg>
);
