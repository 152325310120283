import * as yup from 'yup';

import { Field, Formik } from 'formik';
import { renderTextField } from '../../util/formik-custom-fields';

import ActionButtonGroup from '../../components/ActionButtonGroup/ActionButtonGroup';
import { AuthorType } from '../../types/Author';
import FieldLabel from '../../components/FieldLabel/FieldLabel';
import Loader from '../../components/Loader/Loader';
import { RootState } from '../../redux/modules/root';
import UIBlockListItem from '../../components/UIBlockListItem/UIBlockListItem';
import api from '../../util/api';
import s from '../FlagForm/FlagForm.module.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTaxonomyFlagReasons } from '../../util/APIDjango';
import { FormikSelect, Option } from '../../components/SelectField/SelectField';
import Button from '../../components/Button/Button';
import useUserBlock from '../../hooks/useUserBlock';
import FieldStatusBlock from '../../components/FieldStatusBlock/FieldStatusBlock';

/**
 * Props Type Interface for {@link FlagMessageForm}
 */
export interface FlagMessageFormProps {
  id: number;
  chatId: number;
  author: Partial<AuthorType>;
  handleCancel: () => void;
}

// const mapStateToProps = ({ taxonomyFlagReasons }: RootState) => ({
//   flagReasonTerms: taxonomyFlagReasons.terms,
// });

/**
 * Form to flag messages
 * @param {FlagMessageFormProps} props
 * @returns {JSX.Element}
 */
const FlagMessageForm = ({
  id,
  chatId,
  author,
  handleCancel,
}: FlagMessageFormProps): JSX.Element => {
  const { t } = useTranslation();
  const [flagReasons] = useTaxonomyFlagReasons();

  return (
    <Formik
      initialValues={{
        id,
        chatId,
        author: author?.id,
        reason: undefined,
        reasonDescription: '',
      }}
      validationSchema={yup.object().shape({
        reason: yup
          .object()
          .shape({
            label: yup.string().required(t('required.label')),
            value: yup.string().required(t('required.label')),
          })
          .required(t('required.label')),
        reasonDescription: yup.string(),
      })}
      initialStatus={{
        formError: null,
        flagSubmitted: false,
      }}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        setSubmitting(true);
        setStatus({ formError: null, flagSubmitted: false });

        api
          .flagChatMessage(
            values.chatId,
            values.id,
            Number.parseInt(values.reason?.value),
            values.reasonDescription,
          )
          .then(() => {
            setStatus({ formError: null, flagSubmitted: true });
          })
          .catch(err => {
            let errMsg = err.message;
            if (err.status === 409) errMsg = t('cannotFlagMoreThanOnce.label');
            setStatus({ formError: errMsg, flagSubmitted: false });
          })
          .finally(() => setSubmitting(false));
      }}>
      {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, status, errors }) => (
        <form className={s.form} onSubmit={handleSubmit}>
          {isSubmitting ? (
            <Loader label={t('submittingFlagRequest.label')} />
          ) : (
            <div className={s.container}>
              {status && status.formError ? (
                <>
                  {status.formError}
                  <br />
                </>
              ) : null}
              {status && status.flagSubmitted ? (
                <div className={s.flagFulfill}>{t('thankYouYourFlagWasSubmitted.label')}</div>
              ) : (
                <>
                  <FieldLabel required label={t('pleaseChooseAReasonForFlagging.label')} />
                  <Field
                    component={FormikSelect}
                    name="reason"
                    error={errors.reason?.label}
                    disabled={isSubmitting}
                    options={flagReasons.terms.map(item => ({
                      value: item.id,
                      label: item.i18nKey
                        ? t(`${item.i18nKey}.label`, { defaultValue: item.name })
                        : item.name,
                    }))}
                    className={s.selectReason}
                  />
                  <Field
                    component={renderTextField}
                    type="text"
                    multiLine
                    name="reasonDescription"
                    label={t('additionalDetail.label')}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.reasonDescription}
                    placeholder={t('startTyping.label')}
                  />
                </>
              )}
              <FieldLabel label={t('blockAllContentFromAuthor.label')} className={s.blockLabel} />
            </div>
          )}
          {!isSubmitting && author?.id && (
            <UIBlockListItem
              className={s.blockListItem}
              id={author?.username}
              author={author}></UIBlockListItem>
          )}
          <div className={s.buttons}>
            {status && status.flagSubmitted ? (
              <Button type="button" onClick={handleCancel} label="Done" />
            ) : (
              <>
                <Button type="submit" label={t('flagContent.label')} />
                <Button type="button" secondary onClick={handleCancel} label={t('cancel.label')} />
              </>
            )}
          </div>
        </form>
      )}
    </Formik>
  );
};

export default FlagMessageForm;
