import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import AutoTranslateDisclaimer from '../components/AutoTranslateDisclaimer/AutoTranslateDisclaimer';
import ChatDisclaimer from '../components/ChatDisclaimer/ChatDisclaimer';
import ChatRoomList from '../components/ChatRoomList/ChatRoomList';
import Layout from '../components/Layout/Layout';
import { useEffect } from 'react';

/**
 * Page which displays all of the chats a user can enter
 * @returns {JSX.Element}
 */
const AllChats = (): JSX.Element => {
  //set default language to english if not set

  useEffect(() => {
    void FirebaseAnalytics.setCurrentScreen({
      screenName: 'Chat',
      screenClassOverride: 'Chat',
    });
  }, []);

  // const { chatId } = useParams<{ chatId?: string }>();
  return (
    <Layout>
      <ChatRoomList />
      <ChatDisclaimer />
      <AutoTranslateDisclaimer />
      {/* {chatId && <ChatModal chatId={Number(chatId)} />} */}
    </Layout>
  );
};

export default AllChats;
