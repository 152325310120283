/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-misused-promises */

import { Dispatch, FormEvent, SetStateAction, useState } from 'react';
import toast from 'react-hot-toast';
import ButtonIcon from '../../components/ButtonIcon/ButtonIcon';
import s from './ChatForm.module.scss';
import { useTranslation } from 'react-i18next';
import MentionsEditorChat, {
  editorStateToString,
} from '../../components/MentionsEditor/MentionsEditorChat';
import { ChatMessage } from '../../types/ChatMessage';
import SendIcon from '../../assets/images/send.svg';
import Editor from '../../components/MentionsEditor/Editor';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot } from 'lexical';

/**
 * Props Type Interface for {@link ChatForm}
 */
export interface ChatFormProps {
  replyMessage: ChatMessage;
  setReplyMessage?: Dispatch<SetStateAction<ChatMessage | null>>;
  callback: (content: string) => Promise<void>;
}

const maxLength = 1000;

/**
 * Handles Input for the Chat
 * @param {ChatFormProps} props
 * @returns {JSX.Element}
 */
const ChatForm = ({ replyMessage, setReplyMessage, callback }: ChatFormProps): JSX.Element => {
  const [currentLength, setCurrentLength] = useState(0);
  const [editor] = useLexicalComposerContext();
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (maxLength < currentLength) {
      return;
    }
    setSendMessage(true);
    if (editor.getRootElement().textContent.trim().length === 0) {
      setSendMessage(false);
      return;
    } else if (editor.getRootElement().textContent.length > 1000) {
      toast.error('Exceeded the maximum number of characters allowed!');
      setSendMessage(false);
      return;
    } else {
      if (editorStateToString(editor) === '') {
        setSendMessage(false);
        return;
      }
      void callback(editorStateToString(editor)).then(() => {
        editor.update(() => {
          $getRoot().clear();
        });
        setReplyMessage(null);
        setSendMessage(false);
      });
    }
  };
  const { t } = useTranslation();
  const [sendMessage, setSendMessage] = useState(false);

  return (
    <form className={s.form} onSubmit={handleSubmit}>
      <div className={s.container}>
        <MentionsEditorChat
          currentLength={currentLength}
          setCurrentLength={(newLength: number) => setCurrentLength(newLength)}
          id="comment-input"
          placeholder={t('typeYourMessage.label')}
          disabled={sendMessage}
          replyMessage={replyMessage}
          setReplyMessage={setReplyMessage}
        />
        <ButtonIcon
          className={s.submitChat}
          title="Send Message"
          type="submit"
          disabled={sendMessage}
          icon={<img src={SendIcon} />}
        />
      </div>
    </form>
  );
};

export default ChatForm;
