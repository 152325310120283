import { FC } from 'react';
import styles from './UIContainer.module.scss';
import clsx from 'clsx';

interface UIContainerProps {
  className?: string;
  bottomSpace: boolean;
  border?: boolean;
  children?: React.ReactNode;
}

const UIContainer: FC<UIContainerProps> = ({
  children,
  className,
  bottomSpace = false,
  border,
}) => (
  <div
    className={clsx(
      styles.wrapper,
      bottomSpace && styles.bottomSpace,
      className,
      border && styles.withBorder,
    )}>
    {children}
  </div>
);

export default UIContainer;
