import { FC } from 'react';
import _ from 'lodash';
import { FieldProps } from 'formik';
import CheckboxButtonGroup, {
  CheckboxButtonGroupProps,
} from '../components/CheckboxButtonGroup/CheckboxButtonGroup';
import CommentField from '../components/CommentField/CommentField';
import TextField from '../components/TextField/TextField';
import TextFieldSlim from '../components/TextFieldSlim/TextFieldSlim';
import RadioGroup from '../components/RadioGroup/RadioGroup';
import DropZoneField from '../components/DropZoneField/DropZoneField';
import DropZoneVideoField from '../components/DropZoneVideoField/DropZoneVideoField';

export const renderCheckboxButtonGroup: FC<CheckboxButtonGroupProps & FieldProps> = ({
  field,
  form,
  label,
  children,
  ...custom
}) => (
  <CheckboxButtonGroup
    label={label}
    value={field.value}
    touched={!!_.get(form.touched, field.name)}
    errorText={_.get(form.errors, field.name)?.toString()}
    onChange={newVal => form.setFieldValue(field.name, newVal, true)}
    onBlur={() => form.setFieldTouched(field.name, true, true)}
    {...custom}
  />
);

interface CustomFieldProps extends FieldProps {
  label: string;
  children?: React.ReactNode;
}

export const renderDropZoneField: FC<CustomFieldProps> = ({
  field,
  form,
  label,
  children,
  ...custom
}) => (
  <DropZoneField
    name={field.name}
    label={label}
    value={field.value}
    touched={!!_.get(form.touched, field.name)}
    errorText={_.get(form.errors, field.name) as string}
    onChange={(newVal?: File) => form.setFieldValue(field.name, newVal, true)}
    // onBlur={() => form.setFieldTouched(field.name, true, true)}
    {...custom}
  />
);
export const renderDropZoneVideoField: FC<CustomFieldProps> = ({
  field,
  form,
  label,
  children,
  ...custom
}) => (
  <DropZoneVideoField
    name={field.name}
    label={label}
    value={field.value}
    touched={!!_.get(form.touched, field.name)}
    errorText={_.get(form.errors, field.name)?.toString()}
    onChange={(newVal: { file: File }) => form.setFieldValue(field.name, newVal, true)}
    // onBlur={() => form.setFieldTouched(field.name, true, true)}
    {...custom}
  />
);

export const renderCommentField: FC<CustomFieldProps> = ({ field, form, ...custom }) => (
  <CommentField
    name={field.name}
    value={field.value}
    onChange={field.onChange}
    onBlur={field.onBlur}
    {...custom}
  />
);

export const renderTextField: FC<CustomFieldProps> = ({ field, form, label, ...custom }) => (
  <TextField
    name={field.name}
    label={label}
    value={field.value}
    touched={!!_.get(form.touched, field.name)}
    errorText={_.get(form.errors, field.name)?.toString()}
    onChange={field.onChange}
    onBlur={field.onBlur}
    {...custom}
  />
);

export const renderTextFieldSlim: FC<CustomFieldProps> = ({ field, form, label, ...custom }) => (
  <TextFieldSlim
    name={field.name}
    label={label}
    value={field.value}
    touched={!!_.get(form.touched, field.name)}
    errorText={_.get(form.errors, field.name)?.toString()}
    onChange={field.onChange}
    onBlur={field.onBlur}
    {...custom}
  />
);

export const renderRadioGroup: FC<CustomFieldProps> = ({ field, form, label, ...custom }) => (
  <RadioGroup
    name={field.name}
    label={label}
    value={field.value}
    touched={!!_.get(form.touched, field.name)}
    errorText={_.get(form.errors, field.name)?.toString()}
    onChange={field.onChange}
    {...custom}
  />
);
