type GetNotificationRedirectFunc = (props: { boardId?: string, postId?: string, commentId?: string, chatRoomId?: string, messageId?: string }) => string

export const getNotificationRedirect: GetNotificationRedirectFunc = ({ boardId, postId, commentId, messageId, chatRoomId }) => {
  const basePath = !boardId ? '/all-posts' : `/boards/${boardId}`;
  if (postId && commentId) {
    // comment mention
    return `${basePath}/${postId}/${commentId}?redirectFromNotification=1`;
  } else if (postId) {
    // new post
    return `${basePath}/${postId}?redirectFromNotification=1`;
  } else if (messageId && chatRoomId) {
    return `chat/${chatRoomId}/${messageId}`;
    // return `/chat-rooms/${chatRoomId}/messages/?around=${messageId}`;
  }
}