import { Profile } from "../../types/Profile";

export const BLOCK_DISPATCH = 'WIS/blocked/BLOCK_DISPATCHED';
export const BLOCK_FULFILL = 'WIS/blocked/BLOCK_FULFILLED';
export const BLOCK_REJECT = 'WIS/blocked/BLOCK_REJECTED';

export const UNBLOCK_DISPATCH = 'WIS/blocked/UNBLOCK_DISPATCHED';
export const UNBLOCK_FULFILL = 'WIS/blocked/UNBLOCK_FULFILLED';
export const UNBLOCK_REJECT = 'WIS/blocked/UNBLOCK_REJECTED';

export const UPDATE_BLOCKED_USERS = 'WIS/blocked/UPDATE_BLOCKED_USERS';
export const UPDATE_BLOCKED_USERS_ERROR = 'WIS/blocked/UPDATE_BLOCKED_USERS_ERROR';

export type BlockedActionTypes = 
  'WIS/blocked/BLOCK_DISPATCHED'
  | 'WIS/blocked/BLOCK_FULFILLED'
  | 'WIS/blocked/BLOCK_REJECTED'
  | 'WIS/blocked/UNBLOCK_DISPATCHED'
  | 'WIS/blocked/UNBLOCK_FULFILLED'
  | 'WIS/blocked/UNBLOCK_REJECTED'
  | 'WIS/blocked/UPDATE_BLOCKED_USERS'
  | 'WIS/blocked/UPDATE_BLOCKED_USERS_ERROR';

export const block = (uid: number) => ({ type: BLOCK_DISPATCH, uid });
export const blockFulfill = (users: BlockedState['users']) => ({ type: BLOCK_FULFILL, users });
export const blockReject = (error: string) => ({ type: BLOCK_REJECT, error });

export const unBlock = (uid: number) => ({ type: UNBLOCK_DISPATCH, uid });
export const unBlockFulfill = (users: BlockedState['users']) => ({ type: UNBLOCK_FULFILL, users });
export const unBlockReject = (error: string) => ({ type: UNBLOCK_REJECT, error });

export const updateBlockedUsers = (users: BlockedState['users']) => ({ type: UPDATE_BLOCKED_USERS, users });
export const updateBlockedUsersError = (error: string) => ({ type: UPDATE_BLOCKED_USERS_ERROR, error });

export interface BlockedState {
  users: Partial<Pick<Profile, 'uuid' | 'username' | 'avatar'>>[];
  updating: boolean;
  error?: string;
}

const initialState: BlockedState = {
  users: [],
  updating: false,
  error: null,
};

export type BlockedAction = { readonly type: BlockedActionTypes } & Partial<Pick<BlockedState, 'users' | 'error'>>;

export default function reducer(state = initialState, action: BlockedAction) {
  switch (action.type) {
    case BLOCK_DISPATCH:
      return {
        ...state,
        updating: true,
        error: null,
      };

    case BLOCK_FULFILL:
      return {
        ...state,
        updating: false,
        users: action.users,
        error: null,
      };

    case BLOCK_REJECT:
      return {
        ...state,
        updating: false,
        error: action.error,
      };
    case UNBLOCK_DISPATCH:
      return {
        ...state,
        updating: true,
        error: null,
      };

    case UNBLOCK_FULFILL:
      return {
        ...state,
        updating: false,
        users: action.users,
        error: null,
      };

    case UNBLOCK_REJECT:
      return {
        ...state,
        updating: false,
        error: action.error,
      };

    case UPDATE_BLOCKED_USERS:
      return {
        ...state,
        users: action.users,
      };

    case UPDATE_BLOCKED_USERS_ERROR:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}
