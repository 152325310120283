import { useEffect, useState } from 'react';

import Button from '../Button/Button';
import Card from '../Card/Card';
import Modal from '../Modal/Modal';
import { Preferences } from '@capacitor/preferences';
import s from './ChatDisclaimer.module.scss';
import { useTranslation } from 'react-i18next';

export interface ChatDisclaimerProps {
  id?: number;
  onClose?: () => void;
}

/**
 * The component is a disclaimer popup that users see upon their first access to the site from a new device.
 * It highlights the basic terms of use for users.
 * The chat Disclaimer label is the latest disclaimer set by the client.
 * @returns {JSX.Element}
 */
const ChatDisclaimer = (): JSX.Element => {
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>();
  const { t } = useTranslation();

  useEffect(() => {
    const expiryDate = new Date();
    expiryDate.setHours(expiryDate.getHours() - 4);
    void Preferences.get({
      key: 'chatDisclaimerShown',
    }).then(({ value }) => {
      if (value === null) {
        setShowDisclaimer(true);
      } else if (value !== 'true') {
        setShowDisclaimer(new Date(value).getTime() < expiryDate.getTime());
      }
    });
  }, []);

  return (
    <Modal show={showDisclaimer}>
      <Card title={t('chatDisclaimer.label')} className={s.label}>
        <p className={s.bodyText}>{t('chatRoomsDisclaimer.label')}</p>
        <Button
          label="Continue"
          onClick={() =>
            void Preferences.set({
              key: 'chatDisclaimerShown',
              value: new Date().toISOString(),
            }).then(() => setShowDisclaimer(false))
          }
          fullWidth
        />
      </Card>
    </Modal>
  );
};

export default ChatDisclaimer;
