import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

import ActionSheet from '../ActionSheet/ActionSheet';
// import { AuthContext } from '../../context/AuthProvider';
import ChatMessage from './ChatMessage';
import { FEATURE_FLAGS } from '../../util/config';
import FlagMessageForm from '../../forms/FlagMessageForm/FlagMessageForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HideShow from '../HideShow/HideShow';
import { ChatMessage as IChatMessage } from '../../types/ChatMessage';
import { Profile } from '../../types/Profile';
import RemoveMessageListItem from '../RemoveMessageListItem/RemoveMessageListItem';
import UIBlockListItem from '../UIBlockListItem/UIBlockListItem';
import UIListItem from '../UIListItem/UIListItem';
import moment from 'moment';
import useGoogleTranslate from '../../hooks/useGoogleTranslate';
import { useMessageHide } from '../../hooks/messageHooks';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../util/APIDjango';
import ReplyIcon from '../../assets/images/reply.svg';
import TranslateIcon from '../../assets/images/translate.svg';
import InfoIcon from '../../assets/images/info.svg';
import styles from './ChatMessage.module.scss';
import { Info } from '../Icons/Info';
import { useProfileData } from '../../hooks/useProfileData';

/**
 * Props Type Interface for {@link ActionableChatMessage}
 */
export interface ActionableChatMessageProps {
  chatId: number;
  message: IChatMessage;
  isBlocked: boolean;
  quickViewMessageId: number | null;
  setQuickViewMessageId: Dispatch<SetStateAction<number | null>>;
  likedByMe: number[];
  setReplyMessage?: Dispatch<SetStateAction<IChatMessage | null>>;
  setShouldScrollToBottom?: Dispatch<SetStateAction<boolean | null>>;
  callParentFunction: (data: any) => void;
  deleteMessage: (messageId: number) => void;
}

/**
 * Handles rendering the action sheet for a Chat Message
 * @param {ActionableChatMessageProps} props
 * @returns {JSX.Element}
 */
const ActionableChatMessage = ({
  chatId,
  message,
  isBlocked, // deleteMessage,
  quickViewMessageId,
  setQuickViewMessageId,
  setReplyMessage,
  likedByMe,
  setShouldScrollToBottom,
  callParentFunction,
  deleteMessage,
}: ActionableChatMessageProps): JSX.Element => {
  const { t } = useTranslation();
  // const { uid, user }: { uid: number; user: Profile } = useContext(AuthContext);
  const [authState, setAuthState] = useAuth();
  const { profileData } = useProfileData();
  const { uid }: { uid: number; user: Profile } = authState;
  const { profile: user }: { profile: Profile } = profileData;
  const [originalContent, setOriginalContent] = useState<string | undefined>(message?.content);
  const { translate, translated, translation, translationError } =
    useGoogleTranslate(originalContent);
  const [translatableMessage, setTranslatableMessage] = useState(message);
  const [activeActionSheet, setActiveActionSheet] = useState(null);
  const [activeFlagForm, setActiveFlagForm] = useState(null);
  const { hideMessage, getOriginalContent } = useMessageHide(chatId, message?.id);
  // used to stop actionsheet updating on block.
  const [delayedIsBlocked, setDelayedIsBlocked] = useState(null);
  const [usingTranslation, setUsingTranslation] = useState<boolean>(false);

  const toggleActionSheet = (id: number | null) => {
    if (id === null) {
      setActiveFlagForm(null);
      setDelayedIsBlocked(isBlocked);
    }
    setActiveActionSheet(id);
  };

  useEffect(() => {
    if (
      !message?.translatedContent &&
      message?.content &&
      message?.author?.id !== user?.id &&
      moment(message?.lastModified).isAfter(moment().subtract(1, 'minutes'))
    ) {
      handleTranslate();
    }
  }, [message?.content]);

  const handleTranslate = () => {
    if (!translated) {
      translate();
      setUsingTranslation(true);
    } else if (usingTranslation) {
      setTranslatableMessage(message);
      setUsingTranslation(false);
    } else {
      setUsingTranslation(true);
      setTranslatableMessage({ ...message, content: translation });
    }
    toggleActionSheet(null);
  };

  const handleOriginalContent = async () => {
    const msg = await getOriginalContent();
    setTranslatableMessage(prev =>
      !translatableMessage?.content
        ? {
            ...prev,
            content: msg?.content,
          }
        : message,
    );
    setOriginalContent(msg?.content);
    toggleActionSheet(null);
  };

  const handleHideMessage = async () => {
    await hideMessage(!message?.isHidden);
    setOriginalContent(message?.content);
    setTranslatableMessage({ ...message });
    setUsingTranslation(false);
    toggleActionSheet(null);
    setQuickViewMessageId(null);
    // callParentFunction(message);
  };

  const handleQuickView = async (messageId: number) => {
    await handleOriginalContent();
    setQuickViewMessageId(messageId === quickViewMessageId ? null : messageId);
    toggleActionSheet(null);
  };

  const handleReplyMessage = (replyMessage: IChatMessage) => {
    setReplyMessage(replyMessage);
    toggleActionSheet(null);
  };

  useEffect(() => setDelayedIsBlocked(isBlocked), [isBlocked]);

  useEffect(() => setOriginalContent(message?.content || ''), [message]);
  useEffect(() => {
    if (translation) {
      setTranslatableMessage({ ...message, content: translation });
    }
  }, [translation, message]);
  useEffect(() => {
    if (originalContent !== message?.content) {
      setTranslatableMessage({
        ...message,
        content: message?.content || originalContent,
      });
    }
  }, [originalContent, message]);

  const isQuickViewMessage = quickViewMessageId === message?.id;
  const isModerator = user?.permissions?.includes('chat.hide_message');

  return (
    message?.author?.id &&
    message?.likesCount !== undefined && (
      <>
        <ChatMessage
          likedByMe={likedByMe}
          chatId={chatId}
          translatedMessage={usingTranslation ? t('translationByGoogle.label') : null}
          translationError={translationError}
          isHidden={message?.isHidden}
          isBlocked={isBlocked}
          displayQuickView={isQuickViewMessage && user?.permissions?.includes('chat.hide_message')}
          message={translatableMessage}
          onClick={() => toggleActionSheet(message?.id)}
          originalContent={originalContent}
          setShouldScrollToBottom={setShouldScrollToBottom}
        />
        <ActionSheet
          show={activeActionSheet === message?.id}
          backDropClick={() => toggleActionSheet(null)}>
          {!message.isHidden && (
            <UIListItem
              onClick={() => void handleReplyMessage(message)}
              icon={<img src={ReplyIcon} />}>
              {t('reply.label')}
            </UIListItem>
          )}

          {delayedIsBlocked ? (
            <div className={styles.buttons}>
              <UIListItem
                onClick={() => setActiveFlagForm(message?.id)}
                icon={<Info />}
                activeButton={activeFlagForm === message?.id}>
                {t('flagBlock.label')}
              </UIListItem>
              <HideShow show={activeFlagForm === message?.id}>
                <FlagMessageForm
                  author={{
                    ...message?.author,
                    avatar: {
                      ...message?.author?.avatar,
                      alt: 'User Avatar',
                    },
                  }}
                  id={message?.id}
                  chatId={chatId}
                  handleCancel={() => toggleActionSheet(null)}
                />
              </HideShow>
            </div>
          ) : (
            <>
              {message?.author?.id === uid ? (
                <RemoveMessageListItem
                  chatId={chatId}
                  messageId={message?.id}
                  toggleActionSheet={toggleActionSheet}
                  deleteMessage={deleteMessage}
                />
              ) : (
                <>
                  {FEATURE_FLAGS.googleTranslate &&
                    (!message.isHidden || (isQuickViewMessage && isModerator)) && (
                      <UIListItem onClick={handleTranslate} icon={<img src={TranslateIcon} />}>
                        {usingTranslation
                          ? t('removeTranslation.label')
                          : t('translateMessage.label')}
                      </UIListItem>
                    )}
                  {isModerator && (
                    <UIListItem
                      onClick={() => void handleHideMessage()}
                      icon={
                        <FontAwesomeIcon icon={['fas', message?.isHidden ? 'eye' : 'eye-slash']} />
                      }>
                      {message?.isHidden
                        ? t('showMessageAllUsers.label')
                        : t('hideMessageAllUsers.label')}
                    </UIListItem>
                  )}
                  {isModerator && message?.isHidden && (
                    <UIListItem
                      onClick={() => void handleQuickView(message?.id)}
                      icon={
                        <FontAwesomeIcon icon={['fas', isQuickViewMessage ? 'eye-slash' : 'eye']} />
                      }>
                      {!isQuickViewMessage
                        ? t('quickViewMessage.label')
                        : t('quickViewMessage.label')}
                    </UIListItem>
                  )}
                  <div className={styles.buttons}>
                    <UIListItem
                      onClick={() => setActiveFlagForm(message?.id)}
                      icon={<Info />}
                      activeButton={activeFlagForm === message?.id}>
                      {t('flagBlock.label')}
                    </UIListItem>
                    <HideShow show={activeFlagForm === message?.id}>
                      <FlagMessageForm
                        author={{
                          ...message?.author,
                          avatar: {
                            ...message?.author?.avatar,
                            alt: 'User Avatar',
                          },
                        }}
                        id={message?.id}
                        chatId={chatId}
                        handleCancel={() => toggleActionSheet(null)}
                      />
                    </HideShow>
                  </div>
                </>
              )}
            </>
          )}
        </ActionSheet>
      </>
    )
  );
};

export default ActionableChatMessage;
