import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import useModal from '../../hooks/useModal';
import UIContainer from '../UIContainer/UIContainer';
import styles from './ActionSheet.module.scss';

const ActionSheet = ({ show, backDropClick, children }) => {
  const setShow = useModal();
  useEffect(() => {
    setShow(show);
  }, [show]);
  const modalRoot = document.getElementById('action-sheet-root');

  if (!show) {
    return null;
  }
  return ReactDOM.createPortal(
    <div
      className={styles.backdrop}
      onClick={backDropClick}
      onKeyUp={e => (e.key === 13 ? backDropClick() : null)}
      role="button"
      tabIndex={0}>
      <div
        className={styles.modal}
        onClick={e => {
          e.stopPropagation();
        }}
        onKeyUp={e => e.stopPropagation()}
        role="button"
        tabIndex={0}>
        <UIContainer className={styles.sheet}>{children}</UIContainer>
      </div>
    </div>,
    modalRoot,
  );
};

ActionSheet.propTypes = {
  show: PropTypes.bool,
  backDropClick: PropTypes.func,
};

ActionSheet.defaultProps = {
  show: false,
  backDropClick: null,
};

export default ActionSheet;
