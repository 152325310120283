import { FC, HTMLProps } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import styles from './ButtonIcon.module.scss';
import clsx from 'clsx';

export interface ButtonIconProps extends HTMLProps<HTMLButtonElement> {
  icon?: React.ReactNode;
  to?: string;
  type?: 'submit' | 'reset' | 'button';
  fullWidth?: boolean;
}

const ButtonIcon: FC<RouteComponentProps & ButtonIconProps> = ({
  title,
  to = null,
  history,
  icon = null,
  label = null,
  type = 'button',
  disabled = false,
  onClick = null,
  className,
  fullWidth,
}) => {
  const wrapperClasses = clsx(
    styles.button,
    disabled && styles.disabled,
    className,
    styles.label,
    fullWidth && styles.fullWidth,
  );

  return (
    <div className={clsx(styles.wrap, fullWidth && styles.fullWidth, label && styles.hasLabel)}>
      <button
        title={title}
        type={type}
        className={wrapperClasses}
        disabled={disabled}
        onClick={e => {
          if (type === 'button') {
            e.preventDefault();
            e.stopPropagation();
          }

          if (to) {
            history.push(to);
          }

          if (onClick) {
            onClick(e);
          }
        }}>
        {icon}
        {label && <span className={styles.label}>{label}</span>}
      </button>
    </div>
  );
};

export default withRouter(ButtonIcon);
