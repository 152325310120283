export const SESSION_RESUME_DISPATCH = 'WIS/auth/SESSION_RESUME_DISPATCH';
export const SESSION_RESUME_FULFILL = 'WIS/auth/SESSION_RESUME_FULFILL';
export const SESSION_RESUME_REJECT = 'WIS/auth/SESSION_RESUME_REJECT';

export const LOGIN_DISPATCH = 'WIS/auth/LOGIN_DISPATCHED';
export const LOGIN_FULFILL = 'WIS/auth/LOGIN_FULFILLED';
export const LOGIN_REJECT = 'WIS/auth/LOGIN_REJECTED';

export const LOGOUT_DISPATCH = 'WIS/auth/LOGOUT_DISPATCHED';
export const LOGOUT_FULFILL = 'WIS/auth/LOGOUT_FULFILLED';
export const LOGOUT_REJECT = 'WIS/auth/LOGOUT_REJECTED';

export const UPDATE_FCM_TOKEN_DISPATCH = 'WIS/auth/UPDATE_FCM_TOKEN_DISPATCHED';
export const UPDATE_FCM_TOKEN_FULFILL = 'WIS/auth/UPDATE_FCM_TOKEN_FULFILLED';
export const UPDATE_FCM_TOKEN_REJECT = 'WIS/auth/UPDATE_FCM_TOKEN_REJECTED';

export type AuthActionType =
  'WIS/auth/SESSION_RESUME_DISPATCH'
  | 'WIS/auth/SESSION_RESUME_FULFILL'
  | 'WIS/auth/SESSION_RESUME_REJECT'
  | 'WIS/auth/LOGIN_DISPATCHED'
  | 'WIS/auth/LOGIN_FULFILLED'
  | 'WIS/auth/LOGIN_REJECTED'
  | 'WIS/auth/LOGOUT_DISPATCHED'
  | 'WIS/auth/LOGOUT_FULFILLED'
  | 'WIS/auth/LOGOUT_REJECTED'
  | 'WIS/auth/UPDATE_FCM_TOKEN_DISPATCHED'
  | 'WIS/auth/UPDATE_FCM_TOKEN_FULFILLED'
  | 'WIS/auth/UPDATE_FCM_TOKEN_REJECTED';

// export const sessionResume = () => ({ type: SESSION_RESUME_DISPATCH });
// export const sessionResumeFulfill = (uid: number) => ({ type: SESSION_RESUME_FULFILL, uid });
// export const sessionResumeReject = (error: string) => ({ type: SESSION_RESUME_REJECT, error });

// export const login = () => ({ type: LOGIN_DISPATCH });
// export const loginFulfill = (uid: number) => ({ type: LOGIN_FULFILL, uid });
// export const loginReject = (error: string) => ({ type: LOGIN_REJECT, error });

// export const logout = () => ({ type: LOGOUT_DISPATCH });
// export const logoutFulfill = () => ({ type: LOGOUT_FULFILL });
// export const logoutReject = (error: string) => ({ type: LOGOUT_REJECT, error });

export const updateFCMToken = (token: string) => ({ type: UPDATE_FCM_TOKEN_DISPATCH, token });
export const updateFCMTokenFulfill = () => ({ type: UPDATE_FCM_TOKEN_FULFILL });
export const updateFCMTokenReject = (error: string) => ({ type: UPDATE_FCM_TOKEN_REJECT, error });

export interface AuthState {
  uid?: number;
  user: {
    // TODO - Type User Response
  };
  authenticating: boolean;
  reAuthenticating: boolean;
  authenticated: boolean;
  unauthenticating: boolean;
  error?: string;
  fbcmError?: string;
  token?: string;
}

const initialState: AuthState = {
  uid: null,
  user: {},
  authenticating: false,
  reAuthenticating: false,
  authenticated: false,
  unauthenticating: false,
  error: null,
  fbcmError: null,
};

export type AuthAction = { readonly type: AuthActionType } & Pick<AuthState, 'uid' | 'error'>

export default function reducer(state = initialState, action: AuthAction) {
  switch (action.type) {
    case SESSION_RESUME_DISPATCH: {
      return {
        ...state,
        authenticating: true,
        reAuthenticating: true,
        authenticated: false,
        unauthenticating: false,
        error: null,
      };
    }
    case SESSION_RESUME_FULFILL: {
      return {
        ...state,
        uid: action.uid,
        authenticated: true,
        authenticating: false,
        reAuthenticating: false,
        unauthenticating: false,
        error: null,
      };
    }
    case SESSION_RESUME_REJECT: {
      return {
        ...state,
        authenticated: false,
        authenticating: false,
        reAuthenticating: false,
        // error: action.error.message,
      };
    }
    case LOGIN_DISPATCH:
      return {
        ...state,
        authenticating: true,
        error: null,
      };

    case LOGIN_FULFILL:
      return {
        ...state,
        uid: action.uid,
        authenticated: true,
        authenticating: false,
        error: null,
      };

    case LOGIN_REJECT:
      return {
        ...state,
        authenticating: false,
        error: action.error,
      };
    case LOGOUT_DISPATCH:
      return {
        ...state,
        unauthenticating: true,
        error: null,
      };

    case LOGOUT_FULFILL:
      return {
        ...state,
        uid: null,
        authenticated: false,
        unauthenticating: false,
        error: null,
      };

    case LOGOUT_REJECT:
      return {
        ...state,
        unauthenticating: false,
        error: action.error,
      };

    case UPDATE_FCM_TOKEN_REJECT:
      return {
        ...state,
        fbcmError: action.error,
      };

    default:
      return state;
  }
}
