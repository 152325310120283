import { of, from } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import api from '../../util/api';
import { DISPATCH, fulfill, reject } from '../modules/taxonomyPastGames';
import { ofType } from 'redux-observable';

const taxonomyEpic = action$ =>
  action$.pipe(
    ofType(DISPATCH),
    mergeMap(() =>
      from(api.taxonomyPastGames()).pipe(
        map(terms => fulfill(terms)),
        catchError(error => of(reject(error))),
      ),
    ),
  );

export default taxonomyEpic;
