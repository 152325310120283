// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-FlagForm-module-form-nGh36{width:100%;background:#313131;border-radius:0 0 12px 12px}.-FlagForm-module-form-nGh36 .-FlagForm-module-flagDispatch-kkHC3{padding:16px}.-FlagForm-module-form-nGh36 .-FlagForm-module-container-n4RZQ{padding:8px 12px}.-FlagForm-module-form-nGh36 .-FlagForm-module-container-n4RZQ .-FlagForm-module-flagFulfill-QIbQH{padding:16px 0px;color:#b3b3b3}.-FlagForm-module-form-nGh36 .-FlagForm-module-blockListItem-tlqiL{border-bottom:none;background:#313131;padding:0 6px}.-FlagForm-module-form-nGh36 .-FlagForm-module-buttons-WreCS{display:flex;flex-direction:column;gap:8px;margin-top:20px;padding:0 12px}.-FlagForm-module-form-nGh36 .-FlagForm-module-selectReason-glcru{margin-bottom:20px}.-FlagForm-module-form-nGh36 .-FlagForm-module-blockLabel-xmP_7{margin-top:20px;margin-bottom:0}.-FlagForm-module-form-nGh36 .-FlagForm-module-separator-L7Ms0{width:100%;height:20px}`, "",{"version":3,"sources":["webpack://./src/forms/FlagForm/FlagForm.module.scss","webpack://./src/theme/_colors.scss","webpack://./src/theme/_dimensions.scss"],"names":[],"mappings":"AAIA,6BACE,UAAA,CACA,kBCuCc,CDtCd,2BAAA,CACA,kEACE,YEHK,CFMP,+DACE,gBAAA,CAEA,mGACE,gBAAA,CACA,aCmCW,CD/Bf,mEACE,kBAAA,CACA,kBCsBY,CDrBZ,aAAA,CAGF,6DACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,eAAA,CACA,cAAA,CAEF,kEACE,kBAAA,CAEF,gEACE,eAAA,CACA,eAAA,CAEF,+DACE,UAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `-FlagForm-module-form-nGh36`,
	"flagDispatch": `-FlagForm-module-flagDispatch-kkHC3`,
	"container": `-FlagForm-module-container-n4RZQ`,
	"flagFulfill": `-FlagForm-module-flagFulfill-QIbQH`,
	"blockListItem": `-FlagForm-module-blockListItem-tlqiL`,
	"buttons": `-FlagForm-module-buttons-WreCS`,
	"selectReason": `-FlagForm-module-selectReason-glcru`,
	"blockLabel": `-FlagForm-module-blockLabel-xmP_7`,
	"separator": `-FlagForm-module-separator-L7Ms0`
};
export default ___CSS_LOADER_EXPORT___;
