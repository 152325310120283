/* eslint-disable @typescript-eslint/no-unsafe-return */

import {
  GET_POSTS_DISPATCH,
  GET_POSTS_DISPATCH_FILTERED,
  GET_POSTS_GLOBAL_DISPATCH,
  POST_CREATE_FULFILL,
  POST_UNPUBLISH_DISPATCH,
  getPostsDispatch,
  getPostsFulfill,
  getPostsReject,
  postUnpublishFulfill,
  postUnpublishReject,
} from '../modules/post';
import { catchError, map, mapTo, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

import api from '../../util/api';
import { combineEpics, ofType } from 'redux-observable';
import { filter } from 'lodash';

export const postsGetAll = async ({
  boardId,
  uid,
  keywords,
  offset,
  limit,
  isFullRefresh,
  categoryId,
}) => {
  try {
    const apiCall = uid
      ? api.getPosts(boardId, categoryId, uid, keywords)
      : api.getPosts(boardId, categoryId, uid, keywords, offset, limit);

    let savedPosts = await apiCall;

    if (uid) {
      savedPosts = savedPosts.filter(post => post.author.uuid === uid);
    } else if (keywords) {
      savedPosts = savedPosts.filter(post => {
        return (
          post.author.username.toLowerCase().includes(keywords.toLowerCase()) ||
          post.title.toLowerCase().includes(keywords.toLowerCase()) ||
          post.content.toLowerCase().includes(keywords.toLowerCase())
        );
      });
    }

    return getPostsFulfill(savedPosts, uid, isFullRefresh);
  } catch (error) {
    return getPostsReject(error);
  }
};

export const postsGetAllEpic = action$ =>
  action$.pipe(
    ofType(GET_POSTS_DISPATCH),
    mergeMap(({ boardId, uid, keywords, offset, limit, isFullRefresh, categoryId }) => {
      const apiCall = uid
        ? api.getPosts(boardId, categoryId, uid, keywords)
        : api.getPosts(boardId, categoryId, uid, keywords, offset, limit);

      return from(apiCall).pipe(
        map(savedPosts => {
          if (uid) {
            return filter(savedPosts, post => post.author.uuid === uid);
          } else if (keywords) {
            return filter(savedPosts, post => {
              return (
                post.author.username.toLowerCase().includes(keywords.toLowerCase()) ||
                post.title.toLowerCase().includes(keywords.toLowerCase()) ||
                post.content.toLowerCase().includes(keywords.toLowerCase())
              );
            });
          } else {
            return savedPosts;
          }
        }),
        map(savedPosts => getPostsFulfill(savedPosts, uid, isFullRefresh)),
        catchError(error => of(getPostsReject(error))),
      );
    }),
  );

// NOTE: If they are "Filtered" they are not "All". What does it mean?
export const postsGetAllEpicFiltered = action$ =>
  action$.pipe(
    ofType(GET_POSTS_DISPATCH_FILTERED),
    mergeMap(({ boardId, uid, keywords, offset, limit }) => {
      const apiCall = uid
        ? api.getPosts(boardId, uid, keywords)
        : api.getPosts(boardId, uid, keywords, offset, limit);

      return from(apiCall).pipe(
        map(savedPosts => {
          if (uid) {
            return filter(savedPosts, post => post.author.uuid === uid);
          } else if (keywords) {
            return filter(savedPosts, post => {
              return (
                post.author.username.toLowerCase().includes(keywords.toLowerCase()) ||
                post.title.toLowerCase().includes(keywords.toLowerCase()) ||
                post.content.toLowerCase().includes(keywords.toLowerCase())
              );
            });
          } else {
            return savedPosts;
          }
        }),
        map(savedPosts => getPostsFulfill(savedPosts)),
        catchError(error => of(getPostsReject(error))),
      );
    }),
  );

export const postsGetAllIncludingGlobalEpic = action$ =>
  action$.pipe(
    ofType(GET_POSTS_GLOBAL_DISPATCH),
    mergeMap(({ boardId }) =>
      from(api.getPostsIncludingGlobal(boardId)).pipe(
        map(savedPosts => getPostsFulfill(savedPosts)),
        catchError(error => of(getPostsReject(error))),
      ),
    ),
  );

export const fetchPostsOnCreate = action$ =>
  action$.ofType(POST_CREATE_FULFILL).pipe(mapTo(getPostsDispatch()));

export const unPublishPostEpic = action$ =>
  action$.pipe(
    ofType(POST_UNPUBLISH_DISPATCH),
    mergeMap(({ postId }) =>
      from(api.unpublishPost(postId)).pipe(
        map(unpublishedPost => postUnpublishFulfill(unpublishedPost)),
        catchError(error => of(postUnpublishReject(error))),
      ),
    ),
  );

export default combineEpics(
  // fetchPostsOnCreate,
  postsGetAllEpic,
  postsGetAllIncludingGlobalEpic,
  unPublishPostEpic,
);
